/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "./loginTypes";
import axios from "axios";
import Config from "../../../config/index";
let auth3Url = Config.auth3Url;
let clientId = Config.clientId;

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload: payload,
  };
};

const loginFaliure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

const login = (payload) => {
  return (dispatch) => {
    // let payload = {
    //   email: loginDetails.email,
    //   password: loginDetails.password,
      
    // };
    dispatch(loginRequest());
    let url = `${auth3Url}/vendor/login`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(loginSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(loginFaliure(err.message));
        return { status: false };
      });
  };
};

export { login };
