import {
  GET_OTP_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_REQUEST,
  PUT_OTP_VALIDATE_REQUEST,
  PUT_OTP_VALIDATE_SUCCESS,
  PUT_OTP_VALIDATE_FAILURE,
} from "./OtpTypes";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;

const getOtpRequest = () => {
  return {
    type: GET_OTP_REQUEST,
  };
};

const getOtpSuccess = (payload) => {
  return {
    type: GET_OTP_SUCCESS,
    payload: payload,
  };
};

const getOtpFaliure = (error) => {
  return {
    type: GET_OTP_FAILURE,
    payload: error,
  };
};

const getOtp = (uuid) => {
  return (dispatch) => {
    dispatch(getOtpRequest());
    let url = `${auth3Url}/vendor/generate_otp?vendorId=${uuid}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getOtpSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getOtpFaliure(err.message));
      });
  };
};

const getOtpValidateRequest = () => {
  return {
    type: PUT_OTP_VALIDATE_REQUEST,
  };
};

const getOtpValidateSuccess = (payload) => {
  return {
    type: PUT_OTP_VALIDATE_SUCCESS,
    payload: payload,
  };
};

const getOtpValidateFaliure = (error) => {
  return {
    type: PUT_OTP_VALIDATE_FAILURE,
    payload: error,
  };
};

const validateOtp = (payload) => {
  return (dispatch) => {
    dispatch(getOtpValidateRequest());
    let url = `${auth3Url}/vendor/validate_otp`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(getOtpValidateSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(getOtpValidateFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getOtp, validateOtp };
