/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import MDSnackbar from "components/MDSnackbar";
import MDProgress from "components/MDProgress";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable/index";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Functions from store
import {
  getListOfItems,
  getPurchasedOrderStatus,
  getNotAcceptedPurchasedOrders,
  getAsnViewDetails,
  acceptPo,
} from "../../store";

// Router Components
import { useParams, useLocation, useNavigate } from "react-router-dom";

// React-Select component and styling
import Select from "react-select";

// Custom css
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 345,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const NewPurchaseOrders = ({
  getListOfItems,
  listOfItemsReducer,

  purchasedOrderStatusReducer,
  getNotAcceptedPurchasedOrders,
  notAcceptedPurchasedOrdersReducer,

  getAsnViewDetails,

  asnViewDetailsReducer,

  acceptPo,
}) => {
  let user = cookies.get("vendor-portal-user-data");

  let role = user.data.data.role;

  let vendorId = user.data.data.vendorId;
  const emailId = user.data.data.email;

  const [show, setShow] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // const history = useHistory();

  // const { orderNumber } = params;

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");

  const [state, setState] = useState({
    columns: [
      {
        Header: "PO Number",
        accessor: "orderNumber",
        align: "left",
      },

      {
        Header: "PO created date",
        accessor: "createdDate",

        align: "left",
      },

      {
        Header: "PO total amount",
        accessor: "totalAmount",
        align: "left",
      },

      {
        Header: "Status",
        accessor: "status",

        align: "left",
      },

      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: [],
  });

  const { columns, rows } = state;

  const [orderId, setOrderId] = useState("");

  const [listOfItemsState, setListOfItemsState] = useState({
    columns: [
      {
        Header: "Line item no",
        accessor: "lineItemNumber",
        align: "left",
      },

      {
        Header: "HSN code",
        accessor: "hsnCode",
        align: "left",
      },

      {
        Header: "Item code",
        accessor: "itemCode",
        align: "left",
      },

      {
        Header: "Item Name",
        accessor: "item",
        align: "left",
      },

      {
        Header: "UOM",
        accessor: "uom",
        align: "left",
      },

      {
        Header: "Item Qty",
        accessor: "itemQty",
        align: "left",
      },

      {
        Header: "Remaining Qty",
        accessor: "remainingQty",
        align: "left",
      },

      {
        Header: "Amount",
        accessor: "amount",
        align: "left",
      },

      {
        Header: "Planned receiving date",
        accessor: "receivingDate",
        align: "left",
      },
    ],
    rows: [],
  });
  const { columns: pColumns, rows: pRows } = listOfItemsState;

  useEffect(() => {
    getNotAcceptedPurchasedOrders(emailId);
  }, [getNotAcceptedPurchasedOrders]);

  let loading = notAcceptedPurchasedOrdersReducer.loading;

  useEffect(() => {
    getAsnViewDetails(vendorId);
  }, [getAsnViewDetails]);

  const [openAcceptPoModal, setOpenAcceptPoModal] = useState(false);

  const [singleDataForAcceptPoModal, setSingleDataForAcceptPoModal] = useState(
    {}
  );

  const handleOpenAcceptPoModal = (poData = {}) => {
    setSingleDataForAcceptPoModal(poData);
    setOpenAcceptPoModal(true);
  };

  const handleCloseAcceptPoModal = () => {
    setOpenAcceptPoModal(false);
  };

  let tempPurchaseOrders = [];

  useEffect(() => {
    let purchaseOrdersData = notAcceptedPurchasedOrdersReducer
      .notAcceptedpurchasedOrders.data
      ? notAcceptedPurchasedOrdersReducer.notAcceptedpurchasedOrders.data
      : [];

    purchaseOrdersData.map((data) => {
      const singleData = {
        privateKey: data.orderNumber,
        orderNumber: data.orderNumber,
        createdDate: data.createdDate,
        totalAmount: data.totalAmount,

        status: data.status,

        action: (
          <div>
            <MDButton
              color="white"
              type="button"
              onClick={() => handleOpenAcceptPoModal(data)}
              style={{
                marginTop: "0.5rem",
                color: "white",
                border: "1px solid #4caf50",
                background: "#4caf50",
                marginLeft: 5,
              }}
            >
              Accept
            </MDButton>
          </div>
        ),
        expand: (
          <IconButton aria-label="expand row" size="small">
            {orderId === data.orderNumber && open === true ? (
              <MDTypography>
                <KeyboardArrowUpIcon
                  onClick={() => {
                    setTitle(data.orderNumber);
                    setOrderId(data.orderNumber);
                    setOpen(false);
                  }}
                />
              </MDTypography>
            ) : (
              <MDTypography>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setTitle(data.orderNumber);
                    setOrderId(data.orderNumber);
                    setOpen(true);
                  }}
                />
              </MDTypography>
            )}
          </IconButton>
        ),
        items: (
          <Collapse
            in={orderId === data.orderNumber && open}
            timeout="auto"
            unmountOnExit
          >
            <MDBox sx={{ margin: 1, marginLeft: 10 }}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                mb={-5}
              >
                {/* <MDBox>
                  <MDTypography variant="h5" gutterBottom>
                    {title}
                  </MDTypography>
                </MDBox> */}
              </MDBox>
              <DataTable
                table={{ columns: pColumns, rows: pRows }}
                totalRecords={rows.length}
                isSorted={true}
                canSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
              />
            </MDBox>
          </Collapse>
        ),
      };

      tempPurchaseOrders.push(singleData);
    });
    setState({ ...state, rows: tempPurchaseOrders });
  }, [notAcceptedPurchasedOrdersReducer, open, listOfItemsState]);

  useEffect(() => {
    getListOfItems(vendorId, orderId);
  }, [orderId]);

  useEffect(() => {
    let tempListOfItems = [];
    let data = listOfItemsReducer.listOfItems.data
      ? listOfItemsReducer.listOfItems.data
      : [];

    data.map((data) => {
      const listObject = {
        privateKey: data.itemId,

        lineItemNumber: data.lineItemNumber,
        itemId: data.itemId,
        item: data.item,
        itemQty: data.itemQty,
        asnQty: data.asnQty,
        hsnCode: data.hsnCode,

        itemCode: data.itemCode,
        uom: data.uom,
        remainingQty: data.remainingQty,
        amount: data.amount,
        receivingDate: data.receivingDate,
      };
      tempListOfItems.push(listObject);
    });
    setListOfItemsState({ ...listOfItemsState, rows: tempListOfItems });
  }, [listOfItemsReducer]);

  const [isErrorAcceptPo, setIsErrorAcceptPo] = useState(false);

  useEffect(() => {
    if (isErrorAcceptPo) {
      setTimeout(() => {
        setIsErrorAcceptPo(false);
      }, 3000);
    }
  }, [isErrorAcceptPo]);

  const acceptedPoNotification = () =>
    toast.success("PO Accepted Successfully", {
      position: "top-center",
    });

  const acceptPoHandler = async () => {
    let vendorId = singleDataForAcceptPoModal.vendorId;

    let orderNumber = singleDataForAcceptPoModal.orderNumber;

    let response = await acceptPo(vendorId, orderNumber);

    if (response.status) {
      handleCloseAcceptPoModal();

      setTimeout(() => {
        getNotAcceptedPurchasedOrders(emailId);
      }, 0);

      acceptedPoNotification();
    }
    if (response.status == false) {
      setIsErrorAcceptPo(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  mb={-6}
                >
                  <MDBox>
                    <MDTypography variant="h4" gutterBottom>
                      New Purchase Orders
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox pt={3}>
                  {role === "user" ? (
                    <>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        canSearch={true}
                        entriesPerPage={false}
                        totalRecords={rows.length}
                        showTotalEntries={true}
                        nestedTable={true}
                        nestedTableData={pRows}
                        noEndBorder
                      />
                    </>
                  ) : null}
                </MDBox>

                <Modal
                  open={openAcceptPoModal}
                  onClose={handleCloseAcceptPoModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={style}>
                    <MDTypography mb={2}>
                      Are you sure you want to Accept ?
                    </MDTypography>

                    <MDCard mt={2}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <MDButton
                            style={{ width: "100%", marginRight: "0.5rem" }}
                            color="info"
                            variant="outlined"
                            onClick={handleCloseAcceptPoModal}
                          >
                            Cancel
                          </MDButton>
                        </div>
                        <div>
                          <MDButton
                            style={{ width: "100%", marginLeft: "0.5rem" }}
                            color="success"
                            onClick={acceptPoHandler}
                          >
                            Accept
                          </MDButton>
                        </div>
                      </div>
                    </MDCard>
                    <MDBox mt={2}>
                      <Collapse in={isErrorAcceptPo}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAcceptPo(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not accept the po!
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </Card>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <ToastContainer />

      <MDSnackbar
        color="error"
        icon="notifications"
        title="Error"
        content="No PO Selected"
        open={show}
        close={toggleSnackbar}
      />
      {!loading ? (
      <Footer />
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    listOfItemsReducer: state.listOfItemsReducer,

    purchasedOrderStatusReducer: state.purchasedOrderStatusReducer,
    purchasedOrdersReducer: state.purchasedOrdersReducer,

    asnViewDetailsReducer: state.asnViewDetailsReducer,
    notAcceptedPurchasedOrdersReducer: state.notAcceptedPurchasedOrdersReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getListOfItems: (vendorId, orderNumber) =>
      dispatch(getListOfItems(vendorId, orderNumber)),

    getPurchasedOrderStatus: (orderNumber, vendorId) =>
      dispatch(getPurchasedOrderStatus(orderNumber, vendorId)),
    getNotAcceptedPurchasedOrders: (emailId) =>
      dispatch(getNotAcceptedPurchasedOrders(emailId)),
    getAsnViewDetails: (vendorId) => dispatch(getAsnViewDetails(vendorId)),

    acceptPo: (vendorId, orderNumber) =>
      dispatch(acceptPo(vendorId, orderNumber)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(NewPurchaseOrders);
