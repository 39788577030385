/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable/index";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getAsnViewDetails,
  deleteSingleItemOfAsn,
  deleteAllItemOfAsn,
  createAsn,
  updateAsnQty,
  createPrintQR,
  getAsnData,
} from "../../store";

// Router Components
import { useNavigate } from "react-router-dom";

// React-Select component and styling
import Select from "react-select";

// Custom css
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 275,
  height: 210,
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
};

const styleForQR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 196,
  boxShadow: 24,
  p: 2,
};

const Asn = ({
  getAsnViewDetails,
  asnViewDetailsReducer,
  getAsnData,
  deleteSingleItemOfAsn,
  deleteAllItemOfAsn,
  createAsnReducer,
  createAsn,
  updateAsnQty,
  updateAsnQtyReducer,
  createPrintQR,
  printQRReducer,
}) => {
  let user = cookies.get("vendor-portal-user-data");

  let vendorId = user.data.data.vendorId;

  const navigate = useNavigate();
  const [state, setState] = useState({
    columns: [
      { Header: "PO Number", accessor: "orderNumber", align: "left" },
      { Header: "Line no", accessor: "lineItemNumber", align: "left" },
      { Header: "Item Code", accessor: "itemCode", align: "left" },
      { Header: "Item Name", accessor: "item", align: "left" },
      { Header: "Item Qty", accessor: "itemQty", align: "left" },
      { Header: "Remaining Qty", accessor: "remainingQty", align: "left" },
      { Header: "ASN Qty", accessor: "asnQty", align: "left" },
      {
        Header: "Edit ASN Qty",
        accessor: "editaction",
        width: 150,
        align: "left",
      },
      { Header: "Action", accessor: "action", width: 100, align: "center" },
    ],

    rows: [],
  });
  const { columns, rows } = state;
  useEffect(() => {
    getAsnViewDetails(vendorId);
  }, [getAsnViewDetails]);

  let loading = asnViewDetailsReducer.loading;

  const [file, setFile] = useState({});

  const handleChangeForFile = (event) => {
    setFile(event.target.files[0]);
  };

  const [ebn, setEbn] = useState("");

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [isErrorAcceptPo, setIsErrorAcceptPo] = useState(false);
  useEffect(() => {
    if (isErrorAcceptPo) {
      setTimeout(() => {
        setIsErrorAcceptPo(false);
      }, 3000);
    }
  }, [isErrorAcceptPo]);

  const [openEditAsnModal, setOpenEditAsnModal] = useState(false);

  const [singleAsnForUpdate, setSingleAsnForUpdate] = useState({});

  const handleOpenEditAsnModal = (asnData = {}) => {
    setSingleAsnForUpdate(asnData);
    setOpenEditAsnModal(true);
  };

  const handleCloseEditAsnModal = () => {
    setOpenEditAsnModal(false);
  };

  const [openPrintQRModal, setOpenPrintQRModal] = useState(false);

  const handleOpenPrintQRModal = () => {
    setOpenPrintQRModal(true);
  };

  const handleClosePrintQRModal = () => {
    setOpenPrintQRModal(false);
  };

  const [msg, setMsg] = useState("");

  const [ebnerror, setEbnerror] = useState("");

  const initialValues = {
    vendorId: vendorId,
    // orderNumber: orderNumber,
    id: singleAsnForUpdate.id ? singleAsnForUpdate.id : null,
    itemId: singleAsnForUpdate.itemId ? singleAsnForUpdate.itemId : null,
    asnQty: singleAsnForUpdate.asnQty ? singleAsnForUpdate.asnQty : null,
  };

  const validationSchema = Yup.object({
    asnQty: Yup.string().required("Enter A Valid Asn Qty"),
  });

  const onSubmitForUpdateAsnQty = async (values) => {
    let response = await updateAsnQty(values);

    if (response.status) {
      handleCloseEditAsnModal();

      getAsnViewDetails(vendorId);

      setSingleAsnForUpdate({});
      setMsg("");
    }
    if (!response.status) {
      setMsg("Enter A Valid Qty");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitForUpdateAsnQty,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const [openDeleteAsnModal, setOpenDeleteAsnModal] = useState(false);
  const [singleDataForDeleteModal, setSingleDataForDeleteModal] = useState({});
  const handleOpenDeleteModal = (Data = {}) => {
    setSingleDataForDeleteModal(Data);
    setOpenDeleteAsnModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteAsnModal(false);
  };

  const removeFromAsn = async () => {
    const itemId = singleDataForDeleteModal.itemId;
    const response = await deleteSingleItemOfAsn(vendorId, itemId);

    if (response.status) {
      handleCloseDeleteModal();
      setTimeout(() => {
        getAsnViewDetails(vendorId);
      }, 0);
    }
  };

  const removeAllAsns = async () => {
    const res = await deleteAllItemOfAsn(vendorId);

    if (res.status) {
      getAsnViewDetails(vendorId);
    }
  };
  var AsnData;
  useEffect(() => {
    let tempAsnData = [];
    AsnData = asnViewDetailsReducer.asnViewDetails.data
      ? asnViewDetailsReducer.asnViewDetails.data
      : [];

    AsnData.map((data) => {
      const singleData = {
        orderNumber: data.orderNumber,
        lineItemNumber: data.lineItemNumber,
        itemCode: data.itemCode,
        item: data.item,
        itemQty: data.itemQty,
        remainingQty: `${data.remainingQty - data.asnQty}`,
        asnQty: data.asnQty,
        itemId: data.itemId,
        editaction: (
          <Tooltip title="Edit Asn Qty">
            <MDButton
              type="button"
              variant="gradient"
              color="success"
              iconOnly
              onClick={() => {
                handleOpenEditAsnModal(data);
              }}
            >
              <Icon> edit </Icon>
            </MDButton>
          </Tooltip>
        ),
        action: (
          <div>
            <div>
              <Tooltip title="Remove">
                <MDButton
                  variant="gradient"
                  color="error"
                  iconOnly
                  onClick={() => handleOpenDeleteModal(data)}
                >
                  <Icon> delete </Icon>
                </MDButton>
              </Tooltip>
            </div>
          </div>
        ),
      };
      tempAsnData.push(singleData);
    });

    setState({ ...state, rows: tempAsnData });
  }, [asnViewDetailsReducer]);

  const asnCreatedNotification = () =>
    toast.success("ASN Created Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const QRCodeCreatedNotification = () =>
    toast.success("QRCode Printed Successfully", {
      position: "top-center",
      autoClose: 4000,
    });

  const createAsnHandler = async () => {
    let AsnsData = asnViewDetailsReducer.asnViewDetails.data
      ? asnViewDetailsReducer.asnViewDetails.data
      : [];

    let payload = {
      vendorId: vendorId,
      ebn: ebn,
      invoiceNumber: invoiceNumber,
      file: file,
      itemList: AsnsData,
    };

    let response = await createAsn(payload);

    if (response.status) {
      cookies.set(
        "vendor-portal-created-asn-details",
        JSON.stringify(response.data),
        { path: "/" }
      );

      removeAllAsns();
      asnCreatedNotification();
      handleOpenPrintQRModal();
      setEbnerror("");
      setEbn("");
      setFile({});
    }
    if (!response.status) {
      setEbnerror("Enter A Valid E-WayBill No");
    }
  };

  const printQRHandler = async () => {
    let asn = cookies.get("vendor-portal-created-asn-details");

    let payload = {
      asnCode: asn.data.asnCode,
      totalAmount: asn.data.totalAmount,
      receivingDate: asn.data.receivingDate,
      ebn: asn.data.ebn,
    };

    let response = await createPrintQR(payload);

    if (response.status) {
      cookies.remove("vendor-portal-created-asn-details");

      setTimeout(() => {
        handleClosePrintQRModal();
        QRCodeCreatedNotification();
      }, 1000);

      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
     
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "column", md: "row", lg: "row", xl: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "left", sm: "left", md: "center", lg: "center", xl: "center" }}
                  p={3}
                  mb={-6}
                >
                  <MDBox>
                    <MDTypography variant="h4" gutterBottom>
                      ASN
                    </MDTypography>
                  </MDBox>
                  <MDBox

                    marginLeft={{ xs: "0px", sm: "0px", md: "10px", lg: "50px", xl: "50px" }}
                    style={{
                      marginBottom: "28px",

                      height: "50px",
                      display: "flex",
                    }}
                  >
                    <div>
                      <MDTypography
                        variant="h6"
                        gutterBottom

                        style={{ marginRight: "5px", marginTop: 15, fontSize: 14 }}
                      >
                        E-WayBill No
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                            marginBottom: 15,
                          }}
                        >
                          *
                        </span>
                      </MDTypography>
                    </div>

                    <div>
                      <div>
                        <input
                          required
                          onChange={(e) => setEbn(e.target.value)}
                          style={{
                            width: 150,
                            height: 60,
                            marginLeft: 0,
                            marginTop: 12,
                            marginBottom: 0,
                            height: 30,
                            borderRadius: 5,
                            border: "0.2px solid rgba(0, 0, 0, 0.125)",
                            paddingLeft: 5,
                            outline: "none",
                          }}
                        />
                        <MDTypography
                          style={{
                            color: "red",
                            fontSize: 12,
                            fontStyle: "italic",
                            fontWeight: 800,
                            marginTop: 10,
                          }}
                        >
                          {ebnerror}
                        </MDTypography>
                      </div>
                    </div>
                  </MDBox>
                  <MDBox

                    style={{
                      marginBottom: "28px",

                      height: "50px",
                      display: "flex",
                    }}
                  >
                    <div>
                      <MDTypography
                        variant="h6"
                        gutterBottom
                        style={{ marginRight: "10px", marginTop: 15, fontSize: 14 }}
                      >
                        Invoice No
                      </MDTypography>
                    </div>

                    <div>
                      <div>
                        <input
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          style={{
                            width: 150,
                            height: 60,
                            marginLeft: 0,
                            marginTop: 12,
                            marginBottom: 0,
                            height: 30,
                            borderRadius: 5,
                            border: "0.2px solid rgba(0, 0, 0, 0.125)",
                            paddingLeft: 5,
                            outline: "none",
                          }}
                        />
                      </div>
                    </div>
                  </MDBox>
                  <MDBox
                    style={{
                      marginBottom: "23px",
                      display: "flex",
                    }}
                  >
                    <div>
                      <MDTypography
                        variant="h6"
                        gutterBottom
                        style={{ marginRight: "20px", marginTop: 6, fontSize: 14 }}
                      >
                        Invoice
                      </MDTypography>
                    </div>

                    <div>
                      <input
                        className="choose_file"
                        type="file"
                        accept=".pdf"
                        onChange={(event) => handleChangeForFile(event)}
                      />
                    </div>
                  </MDBox>
                </MDBox>
                <MDBox pt={3}>
                  <>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                  mb={1.5}
                  ml={2}
                >
                  <Tooltip title="Clear ASN">
                    <MDButton
                      color="white"
                      style={{
                        marginRight: "15px",
                        color: "white",
                        border: "1px solid #ef5350",
                        background: "#ef5350",
                      }}
                      size="medium"
                      onClick={removeAllAsns}
                    >
                      <CloseOutlinedIcon fontSize="large" /> &nbsp; Clear ASN
                    </MDButton>
                  </Tooltip>
                  <MDBox>
                    <Tooltip title="Create ASN">
                      <MDButton
                        color="white"
                        type="button"
                        style={{
                          marginRight: "15px",
                          color: "white",
                          border: "1px solid #0275d8",
                          background: "#0275d8",
                        }}
                        onClick={createAsnHandler}
                      >
                        Create ASN
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>{" "}
                <Modal
                  open={openEditAsnModal}
                  onClose={handleCloseEditAsnModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForEdit}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Enter Asn Qty
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseEditAsnModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDInput
                            type="number"
                            name="asnQty"
                            InputProps={{
                              inputProps: {
                                max: singleAsnForUpdate.asnQty,
                                min: 1,
                              },
                            }}
                            fullWidth
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.asnQty}
                            error={
                              formik.touched.asnQty &&
                              formik.errors.asnQty &&
                              true
                            }
                          />
                          {formik.touched.asnQty && formik.errors.asnQty && (
                            <TextError msg={formik.errors.asnQty} />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
                <Modal
                  open={openDeleteAsnModal}
                  onClose={handleCloseDeleteModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={style}>
                    <MDTypography mb={2}>
                      Are you sure you want to Remove item from ASN ?
                    </MDTypography>
                    <MDCard mt={2}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <MDButton
                            style={{ width: "100%", marginRight: "0.5rem" }}
                            color="error"
                            variant="outlined"
                            onClick={handleCloseDeleteModal}
                          >
                            Cancel
                          </MDButton>
                        </div>
                        <div style={{ float: "right" }}>
                          <MDButton
                            style={{ width: "100%", marginLeft: "0.5rem" }}
                            color="error"
                            onClick={removeFromAsn}
                          >
                            Remove
                          </MDButton>
                        </div>
                      </div>
                    </MDCard>
                    <MDCard mt={2}>
                      <Collapse in={isErrorAcceptPo}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAcceptPo(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not remove item from asn !
                        </Alert>
                      </Collapse>
                    </MDCard>
                  </Card>
                </Modal>
                <Modal
                  open={openPrintQRModal}
                  // onClose={handleClosePrintQRModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={styleForQR}>
                    <div
                      onClick={handleClosePrintQRModal}
                      style={{
                        float: "right",
                        textAlign: "right",
                        marginTop: "-15px",
                        marginRight: "-11px",
                        cursor: "pointer",
                      }}
                    >
                      <MDTypography>
                        <Tooltip title="Close">
                        <IconButton
                            aria-label="close"
                            color="inherit"
                           
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                              padding: 2
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </MDTypography>
                    </div>

                    <MDTypography variant="h5" mb={2} ml={2}>
                      Print QR Code
                    </MDTypography>
                    <MDCard
                      mt={2}
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        border: "1px solid #4caf50",
                        paddingTop: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{ textAlign: "center" }}
                        onClick={printQRHandler}
                      >
                        <Tooltip title="Print">
                          <Icon fontSize="large" color="success">
                            print
                          </Icon>
                        </Tooltip>
                      </div>
                    </MDCard>
                    <MDCard mt={2}>
                      <Collapse in={isErrorAcceptPo}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAcceptPo(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not remove item from asn !
                        </Alert>
                      </Collapse>
                    </MDCard>
                  </Card>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
     
      <ToastContainer />
      {!loading ? (
      <Footer />
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    asnViewDetailsReducer: state.asnViewDetailsReducer,
    createAsnReducer: state.createAsnReducer,
    updateAsnQtyReducer: state.updateAsnQtyReducer,
    printQRReducer: state.printQRReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getAsnViewDetails: (vendorId) => dispatch(getAsnViewDetails(vendorId)),
    getAsnData: () => dispatch(getAsnData()),
    deleteSingleItemOfAsn: (vendorId, itemId) =>
      dispatch(deleteSingleItemOfAsn(vendorId, itemId)),
    deleteAllItemOfAsn: (vendorId) => dispatch(deleteAllItemOfAsn(vendorId)),
    createAsn: (payload) => dispatch(createAsn(payload)),
    updateAsnQty: (payload) => dispatch(updateAsnQty(payload)),
    createPrintQR: (payload) => dispatch(createPrintQR(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Asn);
