import {
  GET_REJECTEDVENDORS_REQUEST,
  GET_REJECTEDVENDORS_SUCCESS,
  GET_REJECTEDVENDORS_FAILURE,
} from "./RejectedVendorsTypes";

import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;

const getRejectedVendorsRequest = () => {
  return {
    type: GET_REJECTEDVENDORS_REQUEST,
  };
};

const getRejectedVendorsSuccess = (payload) => {
  return {
    type: GET_REJECTEDVENDORS_SUCCESS,
    payload: payload,
  };
};

const getRejectedVendorsFailure = (error) => {
  return {
    type: GET_REJECTEDVENDORS_FAILURE,
    payload: error,
  };
};

const getRejectedVendors = () => {
  return (dispatch) => {
    dispatch(getRejectedVendorsRequest());
    let url = `${auth3Url}/vendor/vendors_rejected`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRejectedVendorsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRejectedVendorsFailure(err.message));
      });
  };
};

export { getRejectedVendors };
