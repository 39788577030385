import {
    GET_PURCHASEDORDERS_REQUEST,
    GET_PURCHASEDORDERS_SUCCESS ,
    GET_PURCHASEDORDERS_FAILURE ,
    GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST,
    GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS,
    GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE,
    PUT_ACCEPTPO_REQUEST,
    PUT_ACCEPTPO_SUCCESS,
    PUT_ACCEPTPO_FAILURE , 
    GET_CARTDATA_SUCCESS , 
    GET_PURCHASEDORDERSTATUS_REQUEST,
    GET_PURCHASEDORDERSTATUS_SUCCESS,
    GET_PURCHASEDORDERSTATUS_FAILURE,
    GET_LISTOFITEMS_REQUEST,
    GET_LISTOFITEMS_SUCCESS,
    GET_LISTOFITEMS_FAILURE,
    UPDATE_ASNQTY_REQUEST,
    UPDATE_ASNQTY_SUCCESS,
    UPDATE_ASNQTY_FAILURE,
    DELETE_UPDATEDASNDATA_REQUEST,
    DELETE_UPDATEDASNDATA_SUCCESS,
    DELETE_UPDATEDASNDATA_FAILURE
    } from "./PurchasedOrdersTypes";
  import Config from "../../config/index";
  
import axios from 'axios';

const URL = Config.baseUrl;

let auth3Url = Config.auth3Url;
  

  
  const getPurchasedOrdersRequest = () => {
    return {
      type: GET_PURCHASEDORDERS_REQUEST,
    };
  }; 
  
  
  const getPurchasedOrdersSuccess = (payload) => {
    return {
      type: GET_PURCHASEDORDERS_SUCCESS,
      payload: payload,
    };
  };
  
  const getPurchasedOrdersFailure = (error) => {
    return {
      type: GET_PURCHASEDORDERS_FAILURE,
      payload: error,
    };
  };
  
  const getPurchasedOrders = (emailId, statusSelected) => {
    return (dispatch) => {
      dispatch(getPurchasedOrdersRequest());
      let url = `${auth3Url}/vendor/get_purchase_order?email=${emailId}&status=${statusSelected}`;
      axios
        .get(url)
        .then((response) => {
          dispatch( getPurchasedOrdersSuccess(response.data));
        })
        .catch((err) => {
          dispatch(getPurchasedOrdersFailure(err.message));
        });
    };
  };

  const getNotAcceptedPurchasedOrdersRequest = () => {
    return {
      type: GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST,
    };
  }; 
  
  
  const getNotAcceptedPurchasedOrdersSuccess = (payload) => {
    return {
      type: GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS,
      payload: payload,
    };
  };
  
  const getNotAcceptedPurchasedOrdersFailure = (error) => {
    return {
      type: GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE,
      payload: error,
    };
  };
  
  const getNotAcceptedPurchasedOrders = (emailId) => {
    return (dispatch) => {
      dispatch(getNotAcceptedPurchasedOrdersRequest());
      let url = `${auth3Url}/vendor/get_not_accepted_purchase_order_without_itemList?email=${emailId}`;
      axios
        .get(url)
        .then((response) => {
          dispatch( getNotAcceptedPurchasedOrdersSuccess(response.data));
        })
        .catch((err) => {
          dispatch(getNotAcceptedPurchasedOrdersFailure(err.message));
        });
    };
  };

  const getPurchasedOrderStatusRequest = () => {
    return {
      type: GET_PURCHASEDORDERSTATUS_REQUEST,
    };
  }; 
  
  
  const getPurchasedOrderStatusSuccess = (payload) => {
    return {
      type: GET_PURCHASEDORDERSTATUS_SUCCESS,
      payload: payload,
    };
  };
  
  const getPurchasedOrderStatusFailure = (error) => {
    return {
      type: GET_PURCHASEDORDERSTATUS_FAILURE,
      payload: error,
    };
  };
  
  const getPurchasedOrderStatus = (orderNumber,vendorId) => {
    return (dispatch) => {
      dispatch(getPurchasedOrderStatusRequest());
      let url = `${auth3Url}/vendor/get_is_accepted?orderNumber=${orderNumber}&vendorId=${vendorId}`;
      axios
        .get(url)
        .then((response) => {
          dispatch( getPurchasedOrderStatusSuccess(response.data));
        })
        .catch((err) => {
          dispatch(getPurchasedOrderStatusFailure(err.message));
        });
    };
  };
  
const acceptPoRequest = () => {
  return {
    type: PUT_ACCEPTPO_REQUEST,
  };
};

const acceptPoSuccess = (payload) => {
  return {
    type: PUT_ACCEPTPO_SUCCESS,
    payload: payload,
  };
};

const acceptPoFaliure = (error) => {
  return {
    type: PUT_ACCEPTPO_FAILURE,
    payload: error,
  };
};

const acceptPo = (vendorId,orderNumber) => {
  return (dispatch) => {
    
    dispatch(acceptPoRequest());
    let url = `${auth3Url}/vendor/update_is_accepted?vendorId=${vendorId}&orderNumber=${orderNumber}`;
    return axios
      .put(url,{})
      .then((response) => {
        dispatch(acceptPoSuccess(response.data));
        return { status: true ,data: response.data};
      })
      .catch((err) => {
        dispatch(acceptPoFaliure(err.message));
        return { status: false };
      });
  };
};



const getListOfItemsRequest = () => {
  return {
    type: GET_LISTOFITEMS_REQUEST,
  };
};

const getListOfItemsSuccess = (payload) => {
  return {
    type: GET_LISTOFITEMS_SUCCESS,
    payload: payload,
  };
};

const getListOfItemsFailure = (error) => {
  return {
    type: GET_LISTOFITEMS_FAILURE,
    payload: error,
  };
};

const getListOfItems = (vendorId, orderNumber) => {
  return (dispatch) => {
    dispatch(getListOfItemsRequest());
    let url = `${auth3Url}/vendor/get_list_of_items?vendorId=${vendorId}&orderNumber=${orderNumber}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getListOfItemsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getListOfItemsFailure(err.message));
      });
  };
};

const updateAsnQtyRequest = () => {
  return {
    type: UPDATE_ASNQTY_REQUEST,
  };
};

const updateAsnQtySuccess = (payload) => {
  return {
    type: UPDATE_ASNQTY_SUCCESS,
    payload: payload,
  };
};

const updateAsnQtyFaliure = (error) => {
  return {
    type: UPDATE_ASNQTY_FAILURE,
    payload: error,
  };
};

const updateAsnQty = (payload) => {
  return (dispatch) => {
    dispatch(updateAsnQtyRequest());
    let url = `${auth3Url}/vendor/update_asn_qty`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(updateAsnQtySuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateAsnQtyFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteUpdatedAsnDataRequest = () => {
  return {
    type: DELETE_UPDATEDASNDATA_REQUEST,
  };
};

const deleteUpdatedAsnDataSuccess = (payload) => {
  return {
    type: DELETE_UPDATEDASNDATA_SUCCESS,
    payload: payload,
  };
};

const deleteUpdatedAsnDataFaliure = (error) => {
  return {
    type: DELETE_UPDATEDASNDATA_FAILURE,
    payload: error,
  };
};
const deleteUpdatedAsnData = (vendorId) => {
  return (dispatch) => {
    dispatch(deleteUpdatedAsnDataRequest());
    let url = `${auth3Url}/vendor/remove_update_asn?vendorId=${vendorId}`;
    return axios
      .delete(url)
      .then((response) => {
        dispatch(deleteUpdatedAsnDataSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteUpdatedAsnDataFaliure(err.message));
        return { status: false };
      });
  };
};


const getAsnDataSuccess = (payload) => {
  return {
    type: GET_CARTDATA_SUCCESS,
    payload: payload,
  }; 
}; 


const getAsnData = (payload) => {
  return (dispatch) => { 
    
    dispatch(getAsnDataSuccess(payload));
  };
};
  
 
  export { getPurchasedOrders, acceptPo  , getAsnData, getPurchasedOrderStatus, getNotAcceptedPurchasedOrders, getListOfItems, updateAsnQty, deleteUpdatedAsnData};
  