import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik & Yup
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "yup-phone";

// Country & State components
import { Country, State } from "country-state-city";

// React-Select component and styling
import Select from "react-select";

// React-DatePicker component and styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Selects from "@mui/material/Select";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";

// Utils component
import TextError from "utils/TextError";
import "../../utils/input.css"

// Router components

import { useNavigate } from "react-router-dom";

// Store
import { getPreview } from "../../store";


// Cookies
import Cookies from "universal-cookie";
import { isValid } from "date-fns";

const red = {
  100: "#ffcdd2",
  200: "#ff0000",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  // 50: "#f6f8fa",
  // 100: "#eaeef2",
  200: "#ff0000",
  // 300: "#afb8c1",
  // 400: "#8c959f",
  // 500: "#6e7781",
  // 600: "#57606a",
  // 700: "#424a53",
  // 800: "#32383f",
  // 900: "#24292f",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${red[100]};
    color: #d2042d;
  }

  &:focus {
    color: #fff;
    outline: 3px solid #d2042d;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #b03a2e;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 400px;
  background-color: #d2042d;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  // box-shadow: 0px 4px 8px ${theme.palette.mode === "dark" ? "#d2042d" : "#d2042d"
    };
  `
);

const VendorForm = ({ getPreview, getPreviewReducer }) => {
  const [industryType, setIndustryType] = React.useState([]);

  const handleChangeForIndustrial = (event) => {
    const {
      target: { value },
    } = event;
    setIndustryType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const industryTypeNames = [
    "Dealer",
    "Manufacturer",
    "Service",
    "Sub-Contractor",
    "Trader",
  ];

  const navigate = useNavigate();

  const cookies = new Cookies();

  var jwt = cookies.get("vendor-portal-jwtToken");

  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    setPreviewData(getPreviewReducer?.preview);
  }, [getPreviewReducer]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const valuesAccountType = [
    { label: "Current Account", value: "Current Account" },
    { label: "Savings Account", value: "Savings Account" },
  ];
  const [selectedaccountType, setSelectedaccountType] = useState("");

  const handleaccountType = (selectedaccountType) => {
    setSelectedaccountType(selectedaccountType);
  };

  // const valuesIndustrialType = [

  //   { key: "Dealer", value: "Dealer" },
  //   { key: "Manufacturer", value: "Manufacturer" },
  //   { key: "Service", value: "Service" },
  //   { key: "Sub-Contractor", value: "Sub-Contractor" },
  //   { key: "Trader", value: "Trader" },
  // ];

  const [selectedindustrialType, setSelectedindustrialType] = useState("");

  const handleindustrialType = (selectedindustrialType) => {
    setSelectedindustrialType(selectedindustrialType);
  };

  const valuesLineOfBusiness = [
    { label: "Direct Material", value: "Direct Material" },
    { label: "Indirect Material", value: "Indirect Material" },
  ];

  const [selectedlineOfBusiness, setSelectedlineOfBusiness] = useState({});

  const handlelineOfBusiness = (selectedlineOfBusiness) => {
    setSelectedlineOfBusiness(selectedlineOfBusiness);
  };

  //   Whether SEZ/Non-SEZ
  const valuesSezNonsez = [
    { label: "SEZ", value: "SEZ" },
    { label: "Non-SEZ", value: "Non-SEZ" },
  ];
  const [selectedvaluesSezNonsez, setSelectedvaluesSezNonsez] = useState("");

  const handlevaluesSezNonsez = (selectedvaluesSezNonsez) => {
    setSelectedvaluesSezNonsez(selectedvaluesSezNonsez);
  };

  // const currencyTypes = [
  //   { label: "2 Days", value: "2_days" },
  //   { label: "1 Week", value: "1_week" },
  //   { label: "1 Month", value: "1_month" },
  //   { label: "Never expire", value: "never_expire" },
  // ];
  const listOfCountries = Country.getAllCountries();
  const countryDetails = [];

  listOfCountries.map((country) =>
    countryDetails.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.name,
      value: country.name,
    })
  );

  const countryDetails2 = [];

  listOfCountries.map((country) =>
    countryDetails2.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.currency,
      value: country.currency,
    })
  );

  const listOfCountriesName = Country.getAllCountries();
  const countryDetailsName = [];

  listOfCountriesName.map((country) =>
    countryDetailsName.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.name,
      value: country.name,
    })
  );

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const [selectedValidity, setSelectedValidity] = useState("");

  const handleValidityChange = (selectedValidity) => {
    setSelectedValidity(selectedValidity);
  };

  const [selectedState, setSelectedState] = useState("");

  const handleStateChange = (selectedState) => {
    setSelectedState(selectedState);
  };

  const [selectedCountryName, setSelectedCountryName] = useState("");

  const handleCountryName = (selectedCountryName) => {
    setSelectedCountryName(selectedCountryName);
  };


  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.name,
      ...state,
    }));

  const [startDate, setStartDate] = useState("");

  // let isoStartDate

  // useEffect(() => {
  //   isoStartDate = new Date(
  //     (startDate ? startDate : new Date()).getTime() -
  //     (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
  //   ).toISOString();

  // }, [startDate]);

  const initialValues = {
    lineOfBusiness: previewData.lineOfBusiness
      ? previewData.lineOfBusiness
      : "",
    currency: previewData.currency ? previewData.currency : "",
    supplierName: previewData.supplierName ? previewData.supplierName : "",
    buildingNoName: previewData.buildingNoName
      ? previewData.buildingNoName
      : "",
    floor: previewData.floor ? previewData.floor : "",
    street: previewData.street ? previewData.street : "",
    country: previewData.country ? previewData.country : "",
    state: previewData.state ? previewData.state : "",
    city: previewData.city ? previewData.city : "",
    pinCode: previewData.pinCode ? previewData.pinCode : "",
    contactPerson: previewData.contactPerson ? previewData.contactPerson : "",
    countryCode: previewData.countryCode ? previewData.countryCode : "",
    contactNo: previewData.contactNo ? previewData.contactNo : "",
    industrialType: previewData.industrialType
      ? previewData.industrialType
      : [""],
    email: previewData.email ? previewData.email : "",
    pan: previewData.pan ? previewData.pan : "",
    gstNo: previewData.gstNo ? previewData.gstNo : "",
    sezNonsez: previewData.sezNonsez ? previewData.sezNonsez : "",
    rcmApplicable: previewData.rcmApplicable ? previewData.rcmApplicable : "",
    lutNo: previewData.lutNo ? previewData.lutNo : "",
    lutDate: previewData.lutDate ? previewData.lutDate : "",
    paymentTerms: previewData.paymentTerms ? previewData.paymentTerms : "",
    cinNumber: previewData.cinNumber ? previewData.cinNumber : "",
    msmedAct: previewData.msmedAct ? previewData.msmedAct : "",
    tdsLowerDeduction: previewData.tdsLowerDeduction
      ? previewData.tdsLowerDeduction
      : "",
    categoryOfSupply: previewData.categoryOfSupply
      ? previewData.categoryOfSupply
      : [],
    pfAccountNumber: previewData.pfAccountNumber
      ? previewData.pfAccountNumber
      : "",
    esiAccountNumber: previewData.esiAccountNumber
      ? previewData.esiAccountNumber
      : "",
    beneficiaryBankAndAddress: previewData.beneficiaryBankAndAddress
      ? previewData.beneficiaryBankAndAddress
      : "",
    accountNumber: previewData.accountNumber ? previewData.accountNumber : "",
    accountType: previewData.accountType ? previewData.accountType : "",
    accountName: previewData.accountName ? previewData.accountName : "",
    ifscCode: previewData.ifscCode ? previewData.ifscCode : "",
    swiftCodeIban: previewData.swiftCodeIban ? previewData.swiftCodeIban : "",
    intermediaryRoutingBank: previewData.intermediaryRoutingBank
      ? previewData.intermediaryRoutingBank
      : "",
    swiftCodeOfIntermediaryRoutingBank:
      previewData.swiftCodeOfIntermediaryRoutingBank
        ? previewData.swiftCodeOfIntermediaryRoutingBank
        : "",
    incorporationCertificate: previewData.incorporationCertificate
      ? previewData.incorporationCertificate
      : "",
    panCard: previewData.panCard ? previewData.panCard : "",
    cancelledCheque: previewData.cancelledCheque
      ? previewData.cancelledCheque
      : "",
    validLut: previewData.validLut ? previewData.validLut : "",
    gstRegistrationCertificate: previewData.gstRegistrationCertificate
      ? previewData.gstRegistrationCertificate
      : "",
    sezApproval: previewData.sezApproval ? previewData.sezApproval : "",
    pfCertificate: previewData.pfCertificate ? previewData.pfCertificate : "",
    esiCertificate: previewData.esiCertificate
      ? previewData.esiCertificate
      : "",
    tdsLowerDeductionCertificate: previewData.tdsLowerDeductionCertificate
      ? previewData.tdsLowerDeductionCertificate
      : "",
    msmeCertificate: previewData.msmeCertificate
      ? previewData.msmeCertificate
      : "",
    // token: jwt,
  };

  const pancardRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  const gstnoRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const cinnumberRegex =
    /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  const pfaccountnumberRegex =
    /[A-Z]{2}\/[A-Z]{3}\/[0-9]{7}\/[0-9A-Z]{2}[0-9]{1}\/[0-9]{7}/;
  const ifsccodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    lineOfBusiness: Yup.string().required("Choose a Line Of Business"),
    currency: Yup.string().required("Choose a Currency"),
    supplierName: Yup.string().required("Enter a Supplier Name"),
    buildingNoName: Yup.string().required("Enter a Building No/Name"),
    floor: Yup.string().required("Enter a Floor"),
    street: Yup.string().required("Enter a Street"),
    country: Yup.string().required("Choose a Country"),
    state: Yup.string().required("Choose a State"),
    city: Yup.string().required("Enter a City"),
    pinCode: Yup.string()
      .required("Enter a PinCode")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),

    contactPerson: Yup.string().required("Enter a Contact Person"),

    contactNo: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Enter a Contact No"),
    // industrialType: Yup.array(
    //   Yup.string()
    //     .oneOf([
    //       "Dealer",
    //       "Manufacturer",
    //       "Service",
    //       "Sub-Contractor",
    //       "Trader",
    //     ])
    //     .min(1)
    //     .required("Choose a Industrial Type")
    // ),
    email: Yup.string().email("E-mail is Invalid").required("Enter a E-mail"),
    pan: Yup.string()
      .required("Enter Pan No")
      .matches(pancardRegex, "Invalid Format"),
    gstNo: Yup.string()
      .matches(gstnoRegex, "Invalid Format")
      .required("Enter GST No"),
    // sezNonsez: Yup.string().required("Choose Whether SEZ/Non-SEZ"),
    // rcmApplicable: Yup.string().required("Enter RCM Applicable"),
    // lutNo: Yup.number().required("Enter a LutNo"),
    // lutDate: Yup.date().required("Enter a Lut Date"),
    // paymentTerms: Yup.string().required("Enter Payment Terms"),
    cinNumber: Yup.string()
      .matches(cinnumberRegex, "Invalid Format"),
    msmedAct: Yup.string().oneOf(["Yes", "No"]).required("Required"),
    tdsLowerDeduction: Yup.string().oneOf(["Yes", "No"]).required("Required"),
    categoryOfSupply: Yup.array(
      Yup.string().oneOf(["Material", "Services", "Man Power"])
    )
      .min(1, "Choose atleast one")
      .required("Choose Category Of Supply"),
    pfAccountNumber: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: Yup.string()
        .matches(pfaccountnumberRegex, "Invalid Format")
        .required("Enter a valid pf Account Number"),
      otherwise: Yup.string()
        .matches(pfaccountnumberRegex, "Invalid Format")
        .optional(),
    }),
    esiAccountNumber: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: Yup.string().required(),
      otherwise: Yup.string().optional(),
    }),
    beneficiaryBankAndAddress: Yup.string().required(
      "Enter Beneficiary Bank And Address"
    ),
    accountNumber: Yup.number().required("Enter Account Number"),
    accountType: Yup.string().required("Choose Account Type"),
    accountName: Yup.string().required("Enter Account Name"),

    ifscCode: Yup.string()
      .matches(ifsccodeRegex, "Invalid Format")
      .required("Enter IFSC Code"),
    swiftCodeIban: Yup.string().required("Enter swiftCodeIban"),
    intermediaryRoutingBank: Yup.string().required(
      "Enter intermediaryRoutingBank"
    ),
    swiftCodeOfIntermediaryRoutingBank: Yup.string().required(
      "Enter swiftCodeOfIntermediaryRoutingBank"
    ),
    incorporationCertificate: Yup.mixed()
      .required("Upload Incorporation Certificate")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    panCard: Yup.mixed()
      .required("Upload PAN Card")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    cancelledCheque: Yup.mixed()
      .required("Upload Cancelled Cheque")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    // validLut: Yup.mixed()
    //   .required("Upload Valid LUT")
    //   .test("fileSize", "The file size is too large", (value) => {
    //     return value && value.size < 700000;
    //   })
    //   .test("type", "Upload only PDF", (value) => {
    //     return value && value.type === "application/pdf";
    //   }),
    gstRegistrationCertificate: Yup.mixed()
      .required("Upload GST Registration Certificate")
      .test("fileSize", "The file size is too large", (value) => {
        return value && value.size < 700000;
      })
      .test("type", "Upload only PDF", (value) => {
        return value && value.type === "application/pdf";
      }),
    sezApproval: Yup.mixed().when("sezNonsez", {
      is: "SEZ",
      then: Yup.mixed()
        .required("Upload sez Approval")
        .test("fileSize", "The file size is too large", (value) => {
          return value && value.size < 700000;
        })
        .test("type", "Upload only PDF", (value) => {
          return value && value.type === "application/pdf";
        }),
      otherwise: Yup.mixed().optional(),
    }),
    pfCertificate: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: Yup.mixed()
        .required("Upload pf Certificate")
        .test("fileSize", "The file size is too large", (value) => {
          return value && value.size < 700000;
        })
        .test("type", "Upload only PDF", (value) => {
          return value && value.type === "application/pdf";
        }),
      otherwise: Yup.mixed().optional(),
    }),
    esiCertificate: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: Yup.mixed()
        .required("Upload esi Certificate")
        .test("fileSize", "The file size is too large", (value) => {
          return value && value.size < 700000;
        })
        .test("type", "Upload only PDF", (value) => {
          return value && value.type === "application/pdf";
        }),
      otherwise: Yup.mixed().optional(),
    }),
    tdsLowerDeductionCertificate: Yup.mixed().when("tdsLowerDeduction", {
      is: "Yes",
      then: Yup.mixed()
        .required("Upload tds LowerDeduction Certificate")
        .test("fileSize", "The file size is too large", (value) => {
          return value && value.size < 700000;
        })
        .test("type", "Upload only PDF", (value) => {
          return value && value.type === "application/pdf";
        }),
      otherwise: Yup.mixed().optional(),
    }),
    msmeCertificate: Yup.mixed().when("msmedAct", {
      is: "Yes",
      then: Yup.mixed()
        .required("Upload msme Certificate")
        .test("fileSize", "The file size is too large", (value) => {
          return value && value.size < 700000;
        })
        .test("type", "Upload only PDF", (value) => {
          return value && value.type === "application/pdf";
        }),
      otherwise: Yup.mixed().optional(),
    }),
  });

  const [countryIsoCode, setCountryIsoCode] = useState("");

  const countryCodeHandler = (code) => {
    setCountryIsoCode(code);
  };

  const onSubmit = (values) => {
    values.countryCode = countryIsoCode;
    values.lineOfBusiness = selectedlineOfBusiness.value;
    values.state = selectedState.name;
    values.industrialType = industryType;
    values.lutDate = startDate;

    getPreview(values);
    navigate("/preview");
  };

  const [expanded, setExpanded] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
  });

  const valuesCurrency = countryDetails2.filter(
    (country) => country.currency !== ""
  );

  const [selectedCurrency, setSelectedCurrency] = useState("");



  const handleCurrency = (selectedlineOfBusiness) => {
    setSelectedCurrency(selectedlineOfBusiness);
  };

  useEffect(() => {
    if (previewData) {
      setSelectedlineOfBusiness({
        label: previewData.lineOfBusiness,
        value: previewData.lineOfBusiness,
      });

      setSelectedCurrency({
        label: previewData.currency,
        value: previewData.currency,
      });

      setSelectedCountry({
        label: previewData.country,
        value: previewData.country,
      });

      setSelectedState({
        label: previewData.state,
        name: previewData.state,
      });

      setSelectedvaluesSezNonsez({
        label: previewData.sezNonsez,
        value: previewData.sezNonsez,
      });

      setSelectedaccountType({
        label: previewData.accountType,
        value: previewData.accountType,
      });


    }
  }, [previewData]);

  useEffect(() => {

    if (previewData) {
      setStartDate(previewData.lutDate);
    }

    if (previewData.industrialType) {
      setIndustryType(previewData.industrialType);
    }
  }, [previewData]);

  const [previewButtonColor, setPreviewButtonColor] = useState("#FD6A6A");
  const [cursorCss, setCursorCss] = useState("auto");

  setTimeout(() => {
    setPreviewButtonColor("red")
    setCursorCss("pointer")
  }, [20000])

  return (
    <div>
      <BasicLayout>
        <div style={{ position: "absolute" }}>
          <Card
            style={{
              marginTop: "150px",

              marginLeft: "5px",
              marginRight: "-15px",
            }}
          >
            <CardContent align="center">
              <MDTypography
                mt={5}
                mb={5}
                variant="h2"
                fontWeight="medium"
                style={{ display: "grid", justifyContent: "center" }}
              >
                Vendor Form
              </MDTypography>
              <MDBox>
                <TabsUnstyled defaultValue={0}>
                  <TabsList style={{ width: "90%" }}>
                    <Tab style={{ padding: "15px" }}>
                      {" "}
                      Business Partner Details{" "}
                    </Tab>
                    <Tab style={{ padding: "15px" }}>
                      {" "}
                      Statutory Information{" "}
                    </Tab>
                    <Tab style={{ padding: "15px" }}> Bank Details </Tab>
                    <Tab style={{ padding: "15px" }}>
                      {" "}
                      Documents To Be Attached{" "}
                    </Tab>
                  </TabsList>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      formik,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      isValid
                    }) => (
                      <Form>
                        <TabPanel value={0}>
                          <Grid container mt={3} spacing={3}>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Line of Business
                                </MDTypography>

                                <Select
                                  className="select-css-for-form"
                                  // placeholder={p}
                                  name="lineOfBusiness"
                                  value={selectedlineOfBusiness}
                                  options={valuesLineOfBusiness}
                                  onChange={(selectedOption) => {
                                    handlelineOfBusiness(selectedOption);
                                    handleChange("lineOfBusiness")(
                                      selectedOption.value
                                    );
                                  }}
                                />

                                {errors.lineOfBusiness && (
                                  <TextError msg={errors.lineOfBusiness} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Currency
                                </MDTypography>

                                <Select
                                  className="select-css-for-form"
                                  // placeholder="Select..."
                                  name="currency"
                                  value={selectedCurrency}
                                  options={valuesCurrency}
                                  onChange={(selectedOption) => {
                                    handleCurrency(selectedOption);
                                    handleChange("currency")(
                                      selectedOption.value
                                    );
                                  }}
                                />

                                {errors.currency && (
                                  <TextError msg={errors.currency} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Supplier Name
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  name="supplierName"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.supplierName}
                                  style={{ width: "565px" }}
                                />

                                {errors.supplierName && (
                                  <TextError msg={errors.supplierName} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Building No/Name
                                </MDTypography>
                                <MDInput
                                  // label="Building No/Name"
                                  type="text"
                                  name="buildingNoName"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.buildingNoName}
                                  style={{ width: "565px" }}
                                />

                                {errors.buildingNoName && (
                                  <TextError msg={errors.buildingNoName} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Floor
                                </MDTypography>
                                <MDInput
                                  // label="Floor"
                                  type="text"
                                  name="floor"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.floor}
                                  style={{ width: "565px" }}
                                />

                                {errors.floor && (
                                  <TextError msg={errors.floor} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Street
                                </MDTypography>
                                <MDInput
                                  // label="Street"
                                  type="text"
                                  name="street"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.street}
                                  style={{ width: "565px" }}
                                />

                                {errors.street && (
                                  <TextError msg={errors.street} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6">
                                  Country
                                </MDTypography>

                                <Select
                                  className="select-css-for-form"
                                  // placeholder="Select..."
                                  name="country"
                                  value={selectedCountry}
                                  options={countryDetails}
                                  onChange={(selectedOption) => {
                                    handleCountry(selectedOption);
                                    handleChange("country")(
                                      selectedOption.name
                                    );
                                  }}
                                />

                                {errors.country && (
                                  <TextError msg={errors.country} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6">State</MDTypography>

                                <Select
                                  className="select-css-for-form"
                                  // placeholder="Select..."
                                  name="state"
                                  value={selectedState ? selectedState : ""}
                                  options={updatedStates(
                                    selectedCountry
                                      ? selectedCountry.countryCode
                                      : null
                                  )}
                                  onChange={(selectedOption) => {
                                    handleStateChange(selectedOption);
                                    handleChange("state")(selectedOption.value);
                                  }}
                                />
                                {errors.state && (
                                  <TextError msg={errors.state} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  City
                                </MDTypography>
                                <MDInput
                                  // label="City"
                                  type="text"
                                  name="city"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.city}
                                  style={{ width: "565px" }}
                                />

                                {errors.city && <TextError msg={errors.city} />}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  PinCode
                                </MDTypography>
                                <MDInput
                                  // label="PinCode"
                                  type="text"
                                  name="pinCode"
                                  inputProps={{ maxLength: 6 }}
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.pinCode}
                                  style={{ width: "565px" }}
                                />

                                {errors.pinCode && (
                                  <TextError msg={errors.pinCode} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Contact Person
                                </MDTypography>
                                <MDInput
                                  // label="Contact Person"
                                  type="text"
                                  name="contactPerson"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.contactPerson}
                                  style={{ width: "565px" }}
                                />
                                {errors.contactPerson && (
                                  <TextError msg={errors.contactPerson} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Contact No
                                </MDTypography>{" "}
                                <Field
                                  readOnly
                                  style={{
                                    width: "50px",
                                    height: "40px",
                                    borderRadius: "5px",
                                    border: "0.2px solid rgba(0, 0, 0, 0.125)",
                                  }}
                                  className="phonecode"
                                  onChange={countryCodeHandler(
                                    values.country
                                      ? "+" +
                                      countryDetails.filter(
                                        (country) =>
                                          country.name === values.country
                                      )[0].phoneCode
                                      : ""
                                  )}
                                  value={
                                    values.country
                                      ? "+" +
                                      countryDetails.filter(
                                        (country) =>
                                          country.name === values.country
                                      )[0].phoneCode
                                      : ""
                                  }
                                />
                                <span style={{ paddingLeft: "1rem" }} />

                                <MDInput

                                  type="number"
                                  name="contactNo"
                                  disabled={values.country ? false : true}
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.contactNo}
                                  style={{ width: "565px" }}
                                  inputProps={{ maxLength: 14 }}
                                />
                                {errors.contactNo && (
                                  <TextError msg={errors.contactNo} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Email
                                </MDTypography>
                                <MDInput
                                  // label="Email"
                                  type="text"
                                  name="email"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email}
                                  style={{ width: "565px" }}
                                />
                                {errors.email && (
                                  <TextError msg={errors.email} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <div>
                                <MDTypography variant="h6" color="inherit">
                                  Enter Industrial Type
                                </MDTypography>
                                <FormControl
                                  sx={{ m: 0, width: 570, height: 45 }}
                                >
                                  <InputLabel id="demo-multiple-checkbox-label">
                                    Select Industrial Type
                                  </InputLabel>
                                  <Selects
                                    style={{ height: "100px" }}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={industryType}
                                    onChange={handleChangeForIndustrial}
                                    input={
                                      <OutlinedInput label="Select Industrial Type" />
                                    }
                                    renderValue={(selected) =>
                                      selected.join(", ")
                                    }
                                    MenuProps={MenuProps}
                                  >
                                    {industryTypeNames.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        <Checkbox
                                          checked={
                                            industryType.indexOf(name) > -1
                                          }
                                        />
                                        <ListItemText primary={name} />
                                      </MenuItem>
                                    ))}
                                  </Selects>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value={1}>
                          <Grid
                            container
                            spacing={3}
                            mt={3}
                            alignItems="center"
                          >
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Pan
                                </MDTypography>
                                <MDInput
                                  // label="Pan"
                                  type="text"
                                  name="pan"
                                  inputProps={{ maxLength: 10 }}
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.pan}
                                  style={{ width: "565px" }}
                                />
                                {errors.pan && <TextError msg={errors.pan} />}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Gst No
                                </MDTypography>
                                <MDInput
                                  // label="Gst No"
                                  type="text"
                                  name="gstNo"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.gstNo}
                                  style={{ width: "565px" }}
                                />
                                {errors.gstNo && (
                                  <TextError msg={errors.gstNo} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Whether SEZ/Non-SEZ
                                </MDTypography>

                                <Select
                                  className="select-css-for-form"
                                  placeholder="Choose..."
                                  name="sezNonsez"
                                  value={selectedvaluesSezNonsez}
                                  options={valuesSezNonsez}
                                  onChange={(selectedOption) => {
                                    handlevaluesSezNonsez(selectedOption);
                                    handleChange("sezNonsez")(
                                      selectedOption.value
                                    );
                                  }}
                                />

                                {errors.sezNonsez && (
                                  <TextError msg={errors.sezNonsez} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  RCM Applicable
                                </MDTypography>
                                <MDInput
                                  // label="RCM Applicable"
                                  type="text"
                                  name="rcmApplicable"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.rcmApplicable}
                                  style={{ width: "565px" }}
                                />
                                {errors.rcmApplicable && (
                                  <TextError msg={errors.rcmApplicable} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Lut No
                                </MDTypography>
                                <MDInput
                                  // label="Lut No"
                                  type="text"
                                  name="lutNo"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lutNo}
                                  style={{ width: "565px" }}
                                />
                                {errors.lutNo && (
                                  <TextError msg={errors.lutNo} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  LUT Date
                                </MDTypography>
                                {/* <MDInput
                                  // label="Lut Date"
                                  type="text"
                                  name="lutDate"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lutDate}
                                  style={{ width: "565px" }}
                                /> */}
                                <MDBox width="35rem">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Start Date"
                                    // maxDate={new Date()}
                                    onChange={(date) => setStartDate(date)}
                                    isClearable={true}
                                    withPortal
                                    selectsStart
                                    selected={startDate}


                                  // minDate={startDate}
                                  />
                                </MDBox>

                                {errors.lutDate && (
                                  <TextError msg={errors.lutDate} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Payment Terms
                                </MDTypography>
                                <MDInput
                                  // label="Payment Terms"
                                  type="text"
                                  name="paymentTerms"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.paymentTerms}
                                  style={{ width: "565px" }}
                                />
                                {errors.paymentTerms && (
                                  <TextError msg={errors.paymentTerms} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  CIN Number
                                </MDTypography>
                                <MDInput
                                  // label="CIN Number"
                                  type="text"
                                  name="cinNumber"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.cinNumber}
                                  style={{ width: "565px" }}
                                />
                                {errors.cinNumber && (
                                  <TextError msg={errors.cinNumber} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={6}
                              xs={12}
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                alignSelf: "center",
                                // marginTop: "10px",
                              }}
                            >
                              <FormControl>
                                <MDTypography variant="h6" color="inherit">
                                  Are you registered under MSMED Act, if yes
                                  please share valid self attested certificate
                                </MDTypography>
                                <div>
                                  <label>
                                    <Field
                                      style={{
                                        fontWeight: "bold",
                                        margin: "10px",
                                        marginRight: "10px",
                                      }}
                                      type="radio"
                                      value="Yes"
                                      name="msmedAct"
                                    />
                                    Yes
                                  </label>
                                  <span />
                                  <label>
                                    <Field
                                      style={{
                                        margin: "10px",
                                        marginRight: "10px",
                                      }}
                                      type="radio"
                                      value="No"
                                      name="msmedAct"
                                    />
                                    No
                                  </label>
                                </div>
                              </FormControl>
                              <ErrorMessage
                                component="div"
                                name="msmedAct"
                                render={(msg) => (
                                  <div
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {msg}
                                  </div>
                                )}
                                className="error"
                              />
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={6}
                              xs={12}
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                alignSelf: "center",
                                // marginTop: "10px",
                              }}
                            >
                              <FormControl>
                                <MDTypography variant="h6" color="inherit">
                                  Do you have any TDS lower deduction
                                  certificate, if yes provide self attested copy
                                </MDTypography>
                                <div>
                                  <label>
                                    <Field
                                      type="radio"
                                      value="Yes"
                                      name="tdsLowerDeduction"
                                      style={{
                                        margin: "10px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    Yes
                                  </label>
                                  <span />
                                  <label>
                                    <Field
                                      type="radio"
                                      value="No"
                                      name="tdsLowerDeduction"
                                      style={{
                                        margin: "10px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    No
                                  </label>
                                  <ErrorMessage
                                    component="div"
                                    name="tdsLowerDeduction"
                                    render={(msg) => (
                                      <div
                                        className="error"
                                        style={{ color: "red" }}
                                      >
                                        {msg}
                                      </div>
                                    )}
                                    className="error"
                                  />
                                </div>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={6}
                              xs={12}
                              style={{
                                display: "grid",
                                justifyContent: "center",
                                alignSelf: "center",
                                // marginTop: "10px",
                              }}
                            >
                              <FormControl>
                                <MDTypography variant="h6" color="inherit">
                                  Category of Supply
                                </MDTypography>
                                <div>
                                  <label>
                                    <Field
                                      style={{
                                        margin: "10px",
                                        marginRight: "10px",
                                        fontWeight: "bold",
                                      }}
                                      type="checkbox"
                                      value="Material"
                                      name="categoryOfSupply"
                                    />
                                    Material
                                  </label>
                                  <span />
                                  <label>
                                    <Field
                                      style={{
                                        margin: "10px",
                                        marginRight: "10px",
                                      }}
                                      type="checkbox"
                                      value="Services"
                                      name="categoryOfSupply"
                                    />
                                    Services
                                  </label>
                                  <span />
                                  <label>
                                    <Field
                                      style={{
                                        margin: "10px",
                                        marginRight: "10px",
                                      }}
                                      type="checkbox"
                                      value="Man Power"
                                      name="categoryOfSupply"
                                    />
                                    Man Power
                                  </label>
                                </div>
                              </FormControl>
                              <ErrorMessage
                                component="div"
                                name="categoryOfSupply"
                                render={(msg) => (
                                  <div
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {msg}
                                  </div>
                                )}
                                className="error"
                              />
                            </Grid>

                            {values.categoryOfSupply.includes("Man Power") ? (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    PF AccountNumber
                                  </MDTypography>
                                  <MDInput
                                    // label="PF AccountNumber"
                                    type="text"
                                    name="pfAccountNumber"
                                    fullWidth
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.pfAccountNumber}
                                    style={{ width: "565px" }}
                                  />
                                  {errors.pfAccountNumber && (
                                    <TextError msg={errors.pfAccountNumber} />
                                  )}
                                </MDBox>
                              </Grid>
                            ) : (
                              ""
                            )}
                            {values.categoryOfSupply.includes("Man Power") ? (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    ESI AccountNumber
                                  </MDTypography>
                                  <MDInput
                                    // label="ESI AccountNumber"
                                    type="text"
                                    name="esiAccountNumber"
                                    fullWidth
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.esiAccountNumber}
                                    style={{ width: "565px" }}
                                  />
                                  {errors.esiAccountNumber && (
                                    <TextError msg={errors.esiAccountNumber} />
                                  )}
                                </MDBox>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </TabPanel>
                        <TabPanel value={2}>
                          <Grid
                            container
                            mt={3}
                            spacing={3}
                            alignItems="center"
                          >
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Beneficiary Bank And Address
                                </MDTypography>
                                <MDInput
                                  // label="Beneficiary Bank And Address"
                                  type="text"
                                  name="beneficiaryBankAndAddress"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.beneficiaryBankAndAddress}
                                  style={{ width: "565px" }}
                                />
                                {errors.beneficiaryBankAndAddress && (
                                  <TextError
                                    msg={errors.beneficiaryBankAndAddress}
                                  />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Account Number
                                </MDTypography>
                                <MDInput
                                  // label="Account Number"
                                  type="text"
                                  name="accountNumber"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.accountNumber}
                                  style={{ width: "565px" }}
                                />
                                {errors.accountNumber && (
                                  <TextError msg={errors.accountNumber} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Account Type
                                </MDTypography>

                                <Select

                                  className="select-css-for-form"
                                  placeholder="Choose..."
                                  name="accountType"
                                  value={selectedaccountType}
                                  options={valuesAccountType}
                                  onChange={(selectedOption) => {
                                    handleaccountType(selectedOption);
                                    handleChange("accountType")(
                                      selectedOption.value
                                    );
                                  }}
                                />

                                {errors.accountType && (
                                  <TextError msg={errors.accountType} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Account Name
                                </MDTypography>
                                <MDInput
                                  // label="accountName"
                                  type="text"
                                  name="accountName"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.accountName}
                                  style={{ width: "565px" }}
                                />
                                {errors.accountName && (
                                  <TextError msg={errors.accountName} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  IFSC Code
                                </MDTypography>
                                <MDInput
                                  // label=" IFSC Code"
                                  type="text"
                                  name="ifscCode"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.ifscCode}
                                  style={{ width: "565px" }}
                                />
                                {errors.ifscCode && (
                                  <TextError msg={errors.ifscCode} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Swift Code/IBAN
                                </MDTypography>
                                <MDInput
                                  // label=" Swift Code/IBAN"
                                  type="text"
                                  name="swiftCodeIban"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.swiftCodeIban}
                                  style={{ width: "565px" }}
                                />
                                {errors.swiftCodeIban && (
                                  <TextError msg={errors.swiftCodeIban} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Swift Code Of Intermediary/RoutingBank
                                </MDTypography>
                                <MDInput
                                  // label="Swift Code Of Intermediary/RoutingBank"
                                  type="text"
                                  name="swiftCodeOfIntermediaryRoutingBank"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.swiftCodeOfIntermediaryRoutingBank
                                  }
                                  style={{ width: "565px" }}
                                />
                                {errors.swiftCodeOfIntermediaryRoutingBank && (
                                  <TextError
                                    msg={
                                      errors.swiftCodeOfIntermediaryRoutingBank
                                    }
                                  />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Intermediary/Routing Bank
                                </MDTypography>
                                <MDInput
                                  // label="Intermediary/Routing Bank"
                                  type="text"
                                  name="intermediaryRoutingBank"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.intermediaryRoutingBank}
                                  style={{ width: "565px" }}
                                />
                                {errors.intermediaryRoutingBank && (
                                  <TextError
                                    msg={errors.intermediaryRoutingBank}
                                  />
                                )}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value={3}>
                          <Grid
                            container
                            mt={3}
                            spacing={3}
                            alignItems="center"
                          >
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Incorporation Certificate
                                </MDTypography>
                                {previewData?.incorporationCertificate && (
                                  <Typography
                                    color="primary"
                                    variant="subtitle2"
                                  >
                                    Selected file:{" "}
                                    {previewData?.incorporationCertificate.name}
                                  </Typography>
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="incorporationCertificate"
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "incorporationCertificate",
                                      event.target.files[0]
                                    );
                                  }}
                                  accept=".pdf"
                                />
                                {errors.incorporationCertificate && (
                                  <TextError
                                    msg={errors.incorporationCertificate}
                                  />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Cancelled Cheque
                                </MDTypography>
                                {previewData?.cancelledCheque && (
                                  <Typography
                                    color="primary"
                                    variant="subtitle2"
                                  >
                                    Selected file:{" "}
                                    {previewData?.cancelledCheque.name}
                                  </Typography>
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="cancelledCheque"
                                  onBlur={handleBlur}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "cancelledCheque",
                                      e.target.files[0]
                                    )
                                  }
                                  accept=".pdf"
                                  autoComplete="off"
                                />

                                {errors.cancelledCheque && (
                                  <TextError msg={errors.cancelledCheque} />
                                )}
                              </MDBox>
                            </Grid>
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  GST Registration Certificate
                                </MDTypography>
                                {previewData?.gstRegistrationCertificate && (
                                  <Typography
                                    color="primary"
                                    variant="subtitle2"
                                  >
                                    Selected file:{" "}
                                    {
                                      previewData?.gstRegistrationCertificate
                                        .name
                                    }
                                  </Typography>
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="gstRegistrationCertificate"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "gstRegistrationCertificate",
                                      e.target.files[0]
                                    )
                                  }
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                />
                                {errors.gstRegistrationCertificate && (
                                  <TextError
                                    msg={errors.gstRegistrationCertificate}
                                  />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  PAN Card
                                </MDTypography>
                                {previewData?.panCard && (
                                  <Typography
                                    color="primary"
                                    variant="subtitle2"
                                  >
                                    Selected file: {previewData?.panCard.name}
                                  </Typography>
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  type="file"
                                  accept=".pdf"
                                  name="panCard"
                                  onChange={(e) =>
                                    setFieldValue("panCard", e.target.files[0])
                                  }
                                />
                                {errors.panCard && (
                                  <TextError msg={errors.panCard} />
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Valid Lut
                                </MDTypography>
                                {previewData?.validLut && (
                                  <Typography
                                    color="primary"
                                    variant="subtitle2"
                                  >
                                    Selected file: {previewData?.validLut.name}
                                  </Typography>
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="validLut"
                                  accept=".pdf"
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setFieldValue("validLut", e.target.files[0])
                                  }
                                />
                                {errors.validLut && (
                                  <TextError msg={errors.validLut} />
                                )}
                              </MDBox>
                            </Grid>

                            {values.sezNonsez === "SEZ" && (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    Sez Approval
                                  </MDTypography>
                                  {previewData?.sezApproval && (
                                    <Typography
                                      color="primary"
                                      variant="subtitle2"
                                    >
                                      Selected file:{" "}
                                      {previewData?.sezApproval.name}
                                    </Typography>
                                  )}
                                  <input
                                    style={{
                                      borders: "none",
                                      marginTop: "10px",
                                      width: "565px",
                                    }}
                                    className="choose_file"
                                    type="file"
                                    name="sezApproval"
                                    accept=".pdf"
                                    // fullWidth
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "sezApproval",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  {errors.sezApproval && (
                                    <TextError msg={errors.sezApproval} />
                                  )}
                                </MDBox>
                              </Grid>
                            )}

                            {values.pfAccountNumber && (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    PF Certificate
                                  </MDTypography>
                                  {previewData?.pfCertificate && (
                                    <Typography
                                      color="primary"
                                      variant="subtitle2"
                                    >
                                      Selected file:{" "}
                                      {previewData?.pfCertificate.name}
                                    </Typography>
                                  )}
                                  <input
                                    style={{
                                      borders: "none",
                                      marginTop: "10px",
                                      width: "565px",
                                    }}
                                    className="choose_file"
                                    type="file"
                                    name="pfCertificate"
                                    // fullWidth
                                    accept=".pdf"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "pfCertificate",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  {errors.pfCertificate && (
                                    <TextError msg={errors.pfCertificate} />
                                  )}
                                </MDBox>
                              </Grid>
                            )}

                            {values.esiAccountNumber && (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    ESI Certificate
                                  </MDTypography>
                                  {previewData?.esiCertificate && (
                                    <Typography
                                      color="primary"
                                      variant="subtitle2"
                                    >
                                      Selected file:{" "}
                                      {previewData?.esiCertificate.name}
                                    </Typography>
                                  )}
                                  <input
                                    style={{
                                      borders: "none",
                                      marginTop: "10px",
                                      width: "565px",
                                    }}
                                    className="choose_file"
                                    type="file"
                                    name="esiCertificate"
                                    accept=".pdf"
                                    // fullWidth
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "esiCertificate",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  {errors.esiCertificate && (
                                    <TextError msg={errors.esiCertificate} />
                                  )}
                                </MDBox>
                              </Grid>
                            )}

                            {values.tdsLowerDeduction == "Yes" ? (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    TdsLower Deduction Certificate
                                  </MDTypography>
                                  {previewData?.tdsLowerDeduction && (
                                    <Typography
                                      color="primary"
                                      variant="subtitle2"
                                    >
                                      Selected file:{" "}
                                      {
                                        previewData
                                          ?.tdsLowerDeductionCertificate.name
                                      }
                                    </Typography>
                                  )}
                                  <input
                                    style={{
                                      borders: "none",
                                      marginTop: "10px",
                                      width: "565px",
                                    }}
                                    className="choose_file"
                                    type="file"
                                    name="tdsLowerDeductionCertificate"
                                    accept=".pdf"
                                    // fullWidth
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "tdsLowerDeductionCertificate",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  {errors.tdsLowerDeductionCertificate && (
                                    <TextError
                                      msg={errors.tdsLowerDeductionCertificate}
                                    />
                                  )}
                                </MDBox>
                              </Grid>
                            ) : (
                              ""
                            )}

                            {values.msmedAct === "Yes" ? (
                              <Grid item xl={6} lg={4} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography variant="h6" color="inherit">
                                    Msme Certificate
                                  </MDTypography>
                                  {previewData?.msmeCertificate && (
                                    <Typography
                                      color="primary"
                                      variant="subtitle2"
                                    >
                                      Selected file:{" "}
                                      {previewData?.msmeCertificate.name}
                                    </Typography>
                                  )}
                                  <input
                                    style={{
                                      borders: "none",
                                      marginTop: "10px",
                                      width: "565px",
                                    }}
                                    className="choose_file"
                                    type="file"
                                    name="msmeCertificate"
                                    accept=".pdf"
                                    // fullWidth
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "msmeCertificate",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  {errors.msmeCertificate && (
                                    <TextError msg={errors.msmeCertificate} />
                                  )}
                                </MDBox>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </TabPanel>
                        {
                          !isValid ? (
                            <MDButton
                              color="error"
                              style={{ backgroundColor: "red", marginTop: "20px" }}
                              type="submit"
                              disabled={!isValid}
                            >
                              Preview
                            </MDButton>
                          ) : (
                            <MDButton
                              color="error"
                              style={{ backgroundColor: previewButtonColor, cursor: cursorCss, marginTop: "20px" }}
                              type="submit"

                            >
                              Preview
                            </MDButton>
                          )
                        }

                      </Form>
                    )}
                  </Formik>
                </TabsUnstyled>
              </MDBox>
            </CardContent>
          </Card>
        </div>
      </BasicLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getPreviewReducer: state.getPreviewReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPreview: (payload) => dispatch(getPreview(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorForm);
