/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useState, useEffect } from "react";

// react-router components
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@mui/material/Tooltip";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { connect } from "react-redux";
import { getAsnViewDetails } from "store";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Dashboard context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function DashboardNavbar({
  absolute,
  light,
  isMini,
  getAsnViewDetails,
  asnViewDetailsReducer,
}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  
  let user = cookies.get("vendor-portal-user-data");
  let vendorId = user.data.data.vendorId;
  let role = user.data.data.role;

  useEffect(() => {
    getAsnViewDetails(vendorId);
  }, [getAsnViewDetails]);

  let asnDataLength = asnViewDetailsReducer.asnViewDetails.data
    ? asnViewDetailsReducer.asnViewDetails.data.length
    : "";

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const navigate = useNavigate();

  const viewAsn = () => {
    navigate("/asn");
  };

  const viewHome = () => {
    navigate("/");
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>person</Icon>}
        title="Profile"
        route="/profile"
      />
      <NotificationItem
        icon={<Icon>key</Icon>}
        title="Reset Password"
        route="/authentication/reset-password"
      />
      <NotificationItem
        icon={<Icon>lock</Icon>}
        title="Logout"
        route="/authentication/logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </MDBox>
        {/* {isMini ? null : ( */}
        <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox color={light ? "white" : "inherit"}>
            {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              {role === "user" ? (
              <Tooltip title="ASN View">
                <IconButton
                  size="small"
                  disableRipple
                  
                  sx={navbarIconButton}
                  onClick={viewAsn}
                >
                  <Badge
                    badgeContent={asnDataLength ? asnDataLength : 0}
                    color="error"
                  >
                   <MDButton 

                   style={{border:"1px solid #575b72",color:"#575b72"}}
                   
                   >ASN View
                   </MDButton> 
                  </Badge>
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Toggle Sidebar">
              <IconButton
                size="small"
                // disableRipple
                color="inherit"
                // sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Home">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={viewHome}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  home
                </Icon>
              </IconButton>
            </Tooltip>

            

            <Tooltip title="Profile">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Tooltip>

            <Tooltip title="Settings">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
            </Tooltip>
            {renderMenu()}
          </MDBox>
        </MDBox>
        {/* )} */}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    asnViewDetailsReducer: state.asnViewDetailsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getAsnViewDetails: (payload) => dispatch(getAsnViewDetails(payload)),
    // getAsnData: () => dispatch(getAsnData()),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DashboardNavbar);

// export default DashboardNavbar;
