/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    VALIDATE_OTP_FOR_FORGOT_PASSWORD_REQUEST,
    VALIDATE_OTP_FOR_FORGOT_PASSWORD_SUCCESS,
    VALIDATE_OTP_FOR_FORGOT_PASSWORD_FAILURE
} from './forgotPasswordTypes';
import axios from 'axios';
import Config from '../../../config/index'
let auth3Url = Config.auth3Url
let dashboardUrl = Config.dashboardUrl

const forgotPasswordRequest = () => {
    return {
        type: FORGOT_PASSWORD_REQUEST
    }
}

const forgotPasswordSuccess = (payload) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: payload
    }
}

const forgotPasswordFaliure = (error) => {
    return {
        type: FORGOT_PASSWORD_FAILURE,
        payload: error
    }
}

const forgotPassword = (forgotPasswordPayload) => {

    let payload = {
        email: forgotPasswordPayload.email,
        
      };
   
    return (dispatch) => {
        dispatch(forgotPasswordRequest())
        let url = `${auth3Url}/vendor/forgot_password`
        // forgotPasswordPayload["url"] = `${dashboardUrl}/set_password`
       return axios.post(url,payload).then(response => {
            dispatch(forgotPasswordSuccess(response.data))
            return { status: true, data: response.data };
        }).catch(err => {
            dispatch(forgotPasswordFaliure(err.message))
            return { status: false };
        })
    }
}

const validateOtpForForgotPasswordRequest = () => {
    return {
        type: VALIDATE_OTP_FOR_FORGOT_PASSWORD_REQUEST
    }
}

const validateOtpForForgotPasswordSuccess = (payload) => {
    return {
        type: VALIDATE_OTP_FOR_FORGOT_PASSWORD_SUCCESS,
        payload: payload
    }
}

const validateOtpForForgotPasswordFaliure = (error) => {
    return {
        type: VALIDATE_OTP_FOR_FORGOT_PASSWORD_FAILURE,
        payload: error
    }
}

const validateOtpForForgotPassword = (validateOtpForForgotPasswordPayload) => {

    let payload = {
        email: validateOtpForForgotPasswordPayload.email,
        otp: validateOtpForForgotPasswordPayload.otp,
        hash: validateOtpForForgotPasswordPayload.hash,
      };
   
    return (dispatch) => {
        dispatch(validateOtpForForgotPasswordRequest())
        let url = `${auth3Url}/vendor/validate_otp_for_reset_password`
       
       return axios.post(url,payload).then(response => {
            dispatch(validateOtpForForgotPasswordSuccess(response.data))
            return { status: true, data: response.data };
        }).catch(err => {
            dispatch(validateOtpForForgotPasswordFaliure(err.message))
            return { status: false };
        })
    }
}

export { forgotPassword, validateOtpForForgotPassword } 
