import { GET_STATUS_REQUEST ,GET_STATUS_SUCCESS , GET_STATUS_FAILURE } from './StatusTypes';


const initialState = {
    loading: false,
    statusData : {},
    error: "",
  };

  const statusReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_STATUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_STATUS_SUCCESS:
        return {
          loading: false,
         statusData : action.payload,
          error: "",
        };
      case GET_STATUS_FAILURE:
        return {
          loading: false,
          statusData : {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export { statusReducer };  

  

