import {
  GET_REGISTEREDVENDORS_REQUEST,
  GET_REGISTEREDVENDORS_SUCCESS,
  GET_REGISTEREDVENDORS_FAILURE,
} from "./RegisteredVendorsTypes";
// import Config from "../../config/index";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
// const URL = Config.auth3Url;

const getRegisteredVendorsRequest = () => {
  return {
    type: GET_REGISTEREDVENDORS_REQUEST,
  };
};

const getRegisteredVendorsSuccess = (payload) => {
  return {
    type: GET_REGISTEREDVENDORS_SUCCESS,
    payload: payload,
  };
};

const getRegisteredVendorsFailure = (error) => {
  return {
    type: GET_REGISTEREDVENDORS_FAILURE,
    payload: error,
  };
};

const getRegisteredVendors = () => {
  return (dispatch) => {
    dispatch(getRegisteredVendorsRequest());
    let url = `${auth3Url}/vendor/vendors_approved`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRegisteredVendorsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRegisteredVendorsFailure(err.message));
      });
  };
};

export { getRegisteredVendors };
