export const GET_PURCHASEDORDERS_REQUEST = "GET_PURCHASEDORDERS_REQUEST";
export const GET_PURCHASEDORDERS_SUCCESS = "GET_PURCHASEDORDERS_SUCCESS";
export const GET_PURCHASEDORDERS_FAILURE = "GET_PURCHASEDORDERS_FAILURE";

export const GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST = "GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST";
export const GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS = "GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS";
export const GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE = "GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE";

export const PUT_ACCEPTPO_REQUEST = "PUT_ACCEPTPO_REQUEST";
export const PUT_ACCEPTPO_SUCCESS = "PUT_ACCEPTPO_SUCCESS";
export const PUT_ACCEPTPO_FAILURE = "PUT_ACCEPTPO_FAILURE"; 

export const GET_CARTDATA_SUCCESS = "GET_CARTDATA_SUCCESS";

export const GET_PURCHASEDORDERSTATUS_REQUEST = "GET_PURCHASEDORDERSTATUS_REQUEST";
export const GET_PURCHASEDORDERSTATUS_SUCCESS = "GET_PURCHASEDORDERSTATUS_SUCCESS";
export const GET_PURCHASEDORDERSTATUS_FAILURE = "GET_PURCHASEDORDERSTATUS_FAILURE";

export const GET_LISTOFITEMS_REQUEST = "GET_LISTOFITEMS_REQUEST";
export const GET_LISTOFITEMS_SUCCESS = "GET_LISTOFITEMS_SUCCESS";
export const GET_LISTOFITEMS_FAILURE = "GET_LISTOFITEMS_FAILURE";

export const UPDATE_ASNQTY_REQUEST = "UPDATE_ASNQTY_REQUEST";
export const UPDATE_ASNQTY_SUCCESS = "UPDATE_ASNQTY_SUCCESS";
export const UPDATE_ASNQTY_FAILURE = "UPDATE_ASNQTY_FAILURE";

export const DELETE_UPDATEDASNDATA_REQUEST = "DELETE_UPDATEDASNDATA_REQUEST";
export const DELETE_UPDATEDASNDATA_SUCCESS = "DELETE_UPDATEDASNDATA_SUCCESS";
export const DELETE_UPDATEDASNDATA_FAILURE = "DELETE_UPDATEDASNDATA_FAILURE";