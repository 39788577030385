import { GET_OTP_FAILURE, GET_OTP_SUCCESS, GET_OTP_REQUEST , PUT_OTP_VALIDATE_REQUEST , PUT_OTP_VALIDATE_SUCCESS , PUT_OTP_VALIDATE_FAILURE } from "./OtpTypes";


const initialState = {
    loading: false,
    otpData : {},
    error: "", 
  };
 
  const otpReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_OTP_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_OTP_SUCCESS:
        return {
          loading: false,
         otpData : action.payload,
          error: "",
        };
      case GET_OTP_FAILURE:
        return {
          loading: false,
          otpData : {},
          error: action.payload,
        };
      default:
        return state;
    }
  }; 


  const initialStates = {
    loading: false,
    validateOtp : {},
    error: ""
}


const otpValidateReducer = (state = initialStates, action) => {
    switch (action.type) {
        case PUT_OTP_VALIDATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PUT_OTP_VALIDATE_SUCCESS :
            return {
                loading: false,
                validateOtp: action.payload,
                error: ''
            }
        case PUT_OTP_VALIDATE_FAILURE :
            return {
                loading: false,
                validateOtp: {},
                error: action.payload
            }
        default:
            return state
    } 
}
 

  export { otpReducer, otpValidateReducer };  

  