
 import {
    GET_REJECTEDVENDORS_REQUEST,
    GET_REJECTEDVENDORS_SUCCESS,
    GET_REJECTEDVENDORS_FAILURE,
    
  } from "./RejectedVendorsTypes";

 const initialState = {
     loading: false,
     rejectedVendors: {},
     error: "",
 };
 
 const rejectedVendorsReducer = (state = initialState, action) => {
     switch (action.type) {
         case GET_REJECTEDVENDORS_REQUEST:
             return {
                 ...state,
                 loading: true,
             };
         case GET_REJECTEDVENDORS_SUCCESS:
             return {
                 loading: false,
                 rejectedVendors: action.payload,
                 error: "",
             };
         case GET_REJECTEDVENDORS_FAILURE:
             return {
                 loading: false,
                 rejectedVendors: {},
                 error: action.payload,
             };
         default:
             return state;
     }
 };
 
 export {rejectedVendorsReducer};
 