import {
  CREATE_PRINTQR_REQUEST,
  CREATE_PRINTQR_SUCCESS,
  CREATE_PRINTQR_FAILURE,
} from "./PrintQRTypes";
import Config from "../../config/index";

import axios from "axios";

const URL = Config.baseUrl;

let auth3Url = Config.auth3Url;

const createPrintQRRequest = () => {
  return {
    type: CREATE_PRINTQR_REQUEST,
  };
};

const createPrintQRSuccess = (payload) => {
  return {
    type: CREATE_PRINTQR_SUCCESS,
    payload: payload,
  };
};

const createPrintQRFailure = (error) => {
  return {
    type: CREATE_PRINTQR_FAILURE,
    payload: error,
  };
};

const createPrintQR = (payload) => {
  return (dispatch) => {
    dispatch(createPrintQRRequest());
    let url = `${auth3Url}/api/print/generate_asn_qr_code`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(createPrintQRSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPrintQRFailure(err.message));
        return { status: false };
      });
  };
};

export { createPrintQR };
