import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { Formik, Form, Field } from "formik";
import { useFormik, Formik, Form, Field } from "formik";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  getVendorByVendorId,
  createVendorDetails,
  getVendorsNotApproved,
  acceptVendorHandler,
  rejectVendorHandler,
} from "../../store/VendorDetails/VendorDetailsActions";
import { Button } from "@mui/material";
import contained from "./../../assets/theme-dark/components/button/contained";
import { vendorDetailsReducer } from "store/VendorDetails/VendorDetailsReducers";

const DashboardForm = ({
  getVendorByVendorId,
  getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer,
  getVendorsNotApproved,
  acceptVendorHandlerReducer,
  acceptVendorHandler,
  rejectVendorHandler,
  getRejectHandler,
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { vendorId } = params;

  const [values, setValues] = useState({});
  useEffect(() => {
    getVendorByVendorId(vendorId);
  }, [vendorId]);

  let loading = getVendorByVendorIdReducer.loading;

  const documentEmail = values.email;
  const getDocument = (filename) => {
    return (
      <a
        rel="noopener noreferrer"
        href={`http://3.110.254.227:8000/vendor/document?email=${documentEmail}&file_name=${filename}`}
        target="_blank"
        className="btn"
        style={{ color: "white" }}
      >
        View
      </a>
    );
  };

  const [open, setOpen] = useState(false);
  const [tickOpen, setTickOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [statusVendorName, setStatusVendorName] = useState(false);
  const [statusGST, setStatusGST] = useState(false);
  const [statusPAN, setStatusPAN] = useState(false);
  const [statusGSTIN, setStatusGSTIN] = useState(false);
  const [statusVendorNameGSTIN, setStatusVendorNameGSTIN] = useState(false);
  const [statusPincodeGSTIN, setStatusPincodeGSTIN] = useState(false);

  const clickHandler = () => {
    if (location.pathname === `/rejected-vendors/${vendorId}`) {
      navigate("/rejected-vendors");
    }
    if (location.pathname === `/registered-vendors/${vendorId}`) {
      navigate("/registered-vendors");
    }
    if (location.pathname === `/status/${vendorId}`) {
      navigate("/status");
    }
  };
  const acceptHandler = async () => {
    let payload = {
      email: values.email,
      companyCode,
      supplierGroup,
      remarks,
    };
    let response = await acceptVendorHandler(payload);

    if (response.status) {
      getVendorsNotApproved();
      setTickOpen(false);
      navigate("/status");
    }
    if (response.status == false) {
    }
  };

  const rejectHandler = async () => {
    let payload = {
      rejectReason: comment,
      email: values.email,
    };
    let response = await rejectVendorHandler(payload);

    if (response.status) {
      getVendorsNotApproved();
      setOpen(false);
      navigate("/status");
    }
  };

  const tickHandler = () => {
    setTickOpen(true);
  };

  const crossHandler = () => {
    setOpen(true);
  };
  useEffect(() => {
    getVendorsNotApproved();
  }, []);

  useEffect(() => {
    let data = getVendorByVendorIdReducer.getVendor
      ? getVendorByVendorIdReducer.getVendor
      : {};
    setValues(data);
  }, [getVendorByVendorIdReducer]);

  const formik = useFormik({
    // initialValues,
    // onSubmit,
    // validationSchema,
  });
  const valuesCompanyCode = [
    { key: "300", value: "300" },
    { key: "400", value: "400" },
    { key: "500", value: "500" },
  ];

  const valuesSupplierGroup = [
    { key: "Raw Material", value: "Raw Material" },
    { key: "Capital(Supply)", value: "Capital(Supply)" },
    { key: "Capital(Service)", value: "Capital(Service)" },
    { key: "Non-Capital(Supply)", value: "Non-Capital(Supply)" },
    { key: "Non-Capital(Service)", value: "Non-Capital(Service)" },
  ];

  const [companyCode, setCompanyCode] = useState("");
  const [supplierGroup, setSupplierGroup] = useState("");
  const [remarks, setRemarks] = useState("");
  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        {!loading ? (
          <MDCard>
            <MDBox mt={4}>
              <MDTypography
                variant="h3"
                fontWeight="medium"
                style={{ display: "grid", justifyContent: "center" }}
              >
                Vendor Form
              </MDTypography>
            </MDBox>

            <MDBox pt={1} pb={3} px={3}>
              <Formik>
                {(formik) => (
                  <Form>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        // aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Business Partner Details
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          style={{ color: "black" }}
                        >
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // variant="inherit"
                                  // variant="subtitle2"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Line of Business
                                </MDTypography>
                                <input
                                  fullWidth
                                  name="name"
                                  // color="inherit"
                                  // variant="inherit"
                                  value={values.lineOfBusiness}
                                  style={{
                                    color: "black",
                                    font: "inherit",
                                    fontSize: "14px",
                                    letterSpacing: "inherit",

                                    padding: "4px 0 5px",
                                    border: "0.2px solid #DCDCDC",
                                    borderRadius: 5,
                                    boxSizing: "content-box",
                                    background: "none",
                                    height: "1.5em",
                                    margin: 0,

                                    display: "block",
                                    minWidth: 0,
                                    width: "80%",

                                    padding: "16.5px 14px",

                                    padding: "0.75rem",
                                    backgroundColor: "transparent",
                                    // style={{ width: "420px" }}
                                  }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Industrial Type
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.industrialType}
                                  // style={{ width: "420px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Currency
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.currency}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  E-mail
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.email}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Supplier Name
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.supplierName}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Contact Person
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.contactPerson}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Contact No
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.contactDetails
                                      ? `${values.contactDetails.countryCode}-${values.contactDetails.contactNo}`
                                      : ""
                                  }
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Address
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Building No/Name
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address
                                      ? values.address.buildingNoName
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Floor
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.floor : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Street
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.street : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Country
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.country : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  State
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.state : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  City
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.city : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Pincode
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.address ? values.address.pinCode : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          mt={5}
                          color="inherit"
                        >
                          Statutory Information
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  PAN
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.pan}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  GST No
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.gstNo}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Whether SEZ/Non-SEZ
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.sezNonsez}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  RCM Applicable
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.rcmApplicable}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>

                          {values.lutNo && (
                            <>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      LUT No
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="name"
                                      value={values.lutNo}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </>
                          )}
                          {values.lutDate && (
                            <>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      LUT Date
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="name"
                                      value={values.lutDate}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </>
                          )}
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Payment Terms
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.paymentTerms}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  CIN Number
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.cinNumber}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Registered under MSMED Act?
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.msmedAct}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Have TDS lower deduction certificate?
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.tdsLowerDeduction}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Category Of Supply
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={values.categoryOfSupply}
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>

                          {values.pfAccountNumber && (
                            <>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      PF Account Number
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="name"
                                      value={values.pfAccountNumber}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              {values.esiAccountNumber && (
                                <>
                                  <Grid item xl={3} lg={4} md={6} xs={12}>
                                    <MDBox component="form" role="form">
                                      <MDBox>
                                        <MDTypography
                                          variant="h6"
                                          color="inherit"
                                          // style={{
                                          //   color: "grey",
                                          //   textAlign: "left",
                                          // }}
                                        >
                                          ESI Account Number
                                        </MDTypography>
                                        <MDInput
                                          fullWidth
                                          name="name"
                                          value={values.esiAccountNumber}
                                          // style={{ width: "310px" }}
                                        />
                                      </MDBox>
                                    </MDBox>
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          mt={5}
                          color="inherit"
                        >
                          Bank Details
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Beneficiary Bank And Address
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails
                                          .beneficiaryBankAndAddress
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Account Number
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.accountNumber
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Account Type
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.accountType
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Account Name
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.accountName
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  IFSC Code
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.ifscCode
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Swift Code/IBAN
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails.swiftCodeIban
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Intermediary/Routing Bank
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  // value={values.contactDetails.contactNo}
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails
                                          .intermediaryRoutingBank
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={4} md={6} xs={12}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  Swift Code Of Intermediary/RoutingBank
                                </MDTypography>
                                <MDInput
                                  fullWidth
                                  name="name"
                                  // value={values.contactDetails.contactNo}
                                  value={
                                    values.bankDetails
                                      ? values.bankDetails
                                          .swiftCodeOfIntermediaryRoutingBank
                                      : ""
                                  }
                                  // style={{ width: "310px" }}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          mt={5}
                          color="inherit"
                        >
                          Documents
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid
                            item
                            lg={2}
                            style={{ width: "310px", color: "white" }}
                          >
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Incorporation Certificate{" "}
                                </MDTypography>
                                <MDButton
                                  style={{ color: "white" }}
                                  color="primary"
                                  variant="contained"
                                >
                                  {" "}
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments
                                          .incorporationCertificate.name
                                      : ""
                                  )}{" "}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item lg={2} style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Pan Card
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments.panCard.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid lg={2} item style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  color="inherit"
                                  variant="h6"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Cancelled Cheque
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments.cancelledCheque.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid lg={2} item style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  Valid LUT
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments.validLut.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item lg={2} style={{ width: "310px" }}>
                            <MDBox component="form" role="form">
                              <MDBox>
                                <MDTypography
                                  variant="h6"
                                  mt={0}
                                  color="inherit"
                                  // style={{ color: "grey", textAlign: "left" }}
                                >
                                  {" "}
                                  GST Registration Certificate
                                </MDTypography>
                                <MDButton color="primary" variant="contained">
                                  {getDocument(
                                    values.attachments
                                      ? values.attachments
                                          .gstRegistrationCertificate.name
                                      : ""
                                  )}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          {values.attachments?.sezApproval && (
                            <>
                              <Grid item lg={2} style={{ width: "310px" }}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      {" "}
                                      SEZ Approval
                                    </MDTypography>
                                    <MDButton
                                      color="primary"
                                      variant="contained"
                                    >
                                      {getDocument(
                                        values.attachments
                                          ? values.attachments.sezApproval.name
                                          : ""
                                      )}
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </>
                          )}
                          {values.attachments?.pfCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    PF Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments.pfCertificate.name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                          {values.attachments?.esiCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    mt={0}
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    ESI Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments.esiCertificate.name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                          {values.attachments?.tdsLowerDeductionCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    TDS Lower Deduction Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments
                                            .tdsLowerDeductionCertificate.name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                          {values.attachments?.msmeCertificate && (
                            <Grid lg={2} item style={{ width: "310px" }}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    {" "}
                                    MSME Certificate
                                  </MDTypography>
                                  <MDButton color="primary" variant="contained">
                                    {getDocument(
                                      values.attachments
                                        ? values.attachments.msmeCertificate
                                            .name
                                        : ""
                                    )}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {location.pathname === `/status/${vendorId}` && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography variant="h5" mt={5} color="inherit">
                            Validation checks
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="gridbox">
                            <Grid container spacing={2}>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Similar Name Check in ERP
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="similarNameCheckInERP"
                                      value={`Status: ${
                                        statusVendorName ? "Match found" : "OK"
                                      }`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      GST Validation in ERP
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: ${
                                        statusGST ? "Match found" : "OK"
                                      }`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      PAN Validation in ERP
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: ${
                                        statusPAN ? "Match found" : "OK"
                                      }`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      GST Validation in GSTIN
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: OK`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Vendor Name Validation in GSTIN
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      name="gstValidationInErp"
                                      value={`Status: OK`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Pincode Validation in GSTIN
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      value={`Status: OK`}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {/* ok */}
                    {location.pathname === `/status/${vendorId}` && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography variant="h5" mt={5} color="inherit">
                            Company Additions
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            <Grid item xl={4} lg={4} md={6} xs={12}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    Applicable Company Code
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    id="companyCode"
                                    name="companyCode"
                                    as="select"
                                    value={companyCode}
                                    onChange={(e) => {
                                      setCompanyCode(e.target.value);
                                    }}
                                    style={{
                                      height: "45px",
                                      borderRadius: "5px",
                                      border:
                                        "0.2px solid rgba(0, 0, 0, 0.125)",
                                      padding: "10px",
                                      width: "300px",
                                    }}
                                  >
                                    <option value="" disabled defaultValue>
                                      Choose..
                                    </option>
                                    {valuesCompanyCode.map((option) => (
                                      <option
                                        key={option.key}
                                        value={option.value}
                                      >
                                        {option.key}
                                      </option>
                                    ))}
                                  </MDInput>
                                </MDBox>
                              </MDBox>
                            </Grid>

                            <Grid item xl={4} lg={4} md={6} xs={12}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    supplierGroup
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    id="supplierGroup"
                                    name="supplierGroup"
                                    as="select"
                                    value={supplierGroup}
                                    onChange={(e) => {
                                      setSupplierGroup(e.target.value);
                                    }}
                                    style={{
                                      width: "300px",
                                      height: "45px",
                                      border:
                                        "0.2px solid rgba(0, 0, 0, 0.125)",
                                      padding: "10px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <option value="" disabled defaultValue>
                                      Choose..
                                    </option>
                                    {valuesSupplierGroup.map((option) => (
                                      <option
                                        key={option.key}
                                        value={option.value}
                                      >
                                        {option.key}
                                      </option>
                                    ))}
                                  </MDInput>
                                </MDBox>
                              </MDBox>
                            </Grid>

                            <Grid item xl={4} lg={4} md={6} xs={12}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    Remarks
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    name="remarks"
                                    value={remarks}
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setRemarks(e.target.value);
                                    }}
                                    style={{ width: "300px" }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {location.pathname ===
                      `/registered-vendors/${vendorId}` && (
                      <>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <MDTypography variant="h5" mt={5} color="inherit">
                              Company Additions
                            </MDTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item mt={5} xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Applicable Company Code
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      // name="gstValidationInErp"
                                      value={values.companyCode}
                                      // style={{ width: "310px", padding: "0px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={4} md={6} xs={12} mt={5}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Supplier Group
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      // name="gstValidationInErp"
                                      value={values.supplierGroup}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>

                              <Grid item mt={5} xl={3} lg={4} md={6} xs={12}>
                                <MDBox component="form" role="form">
                                  <MDBox>
                                    <MDTypography
                                      variant="h6"
                                      color="inherit"
                                      // style={{ color: "grey", textAlign: "left" }}
                                    >
                                      Remarks
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      // name="gstValidationInErp"
                                      value={values.remarks}
                                      // style={{ width: "310px" }}
                                    />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                    {location.pathname === `/rejected-vendors/${vendorId}` && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography variant="h5" mt={5} color="inherit">
                            Rejection Reason
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} mt={5}>
                              <MDBox component="form" role="form">
                                <MDBox>
                                  <MDTypography
                                    variant="h6"
                                    color="inherit"
                                    // style={{ color: "grey", textAlign: "left" }}
                                  >
                                    Rejection Reason
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    value={values.rejectReason}
                                    style={{ width: "310px" }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignSelf: "center",
                        marginTop: "10px",
                      }}
                    >
                      <pre>
                        {location.pathname === `/status/${vendorId}` && (
                          <MDButton
                            type="button"
                            color="secondary"
                            variant="contained"
                            onClick={tickHandler}
                            style={{ backgroundColor: "green" }}
                          >
                            Accept
                          </MDButton>
                        )}
                        <MDButton
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={clickHandler}
                          style={{ margin: "5px" }}
                        >
                          Go Back
                        </MDButton>
                        {location.pathname === `/status/${vendorId}` && (
                          <MDButton
                            type="button"
                            color="error"
                            variant="contained"
                            onClick={crossHandler}
                            style={{ maigin: "5px" }}
                          >
                            Reject
                          </MDButton>
                        )}
                      </pre>
                    </div>
                  </Form>
                )}
              </Formik>
              <Dialog open={tickOpen} onClose={() => setTickOpen(false)}>
                <DialogContent>
                  <DialogTitle>Are you sure?</DialogTitle>
                </DialogContent>
                <DialogActions style={{ marginTop: "-20px" }}>
                  <MDButton
                    variant="contained"
                    color="success"
                    type="button"
                    onClick={acceptHandler}
                  >
                    Yes
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => setTickOpen(false)}
                  >
                    Close
                  </MDButton>
                </DialogActions>
              </Dialog>

              <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogContent>
                  <DialogTitle>Are you sure?</DialogTitle>
                  <TextField
                    fullWidth
                    label="Comment"
                    variant="outlined"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </DialogContent>
                <DialogActions style={{ marginTop: "-20px" }}>
                  <MDButton
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={rejectHandler}
                  >
                    Reject
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
          </MDCard>
        ) : (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Footer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getVendorByVendorIdReducer: state.getVendorByVendorIdReducer,
    getVendorsNotApprovedReducer: state.getVendorsNotApprovedReducer,
    acceptVendorHandlerReducer: state.acceptVendorHandlerReducer,
    rejectVendorHandlerReducer: state.rejectVendorHandlerReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getVendorByVendorId: (vendorId) => dispatch(getVendorByVendorId(vendorId)),
    getVendorsNotApproved: (payload) =>
      dispatch(getVendorsNotApproved(payload)),
    acceptVendorHandler: (payload) => dispatch(acceptVendorHandler(payload)),
    rejectVendorHandler: (payload) => dispatch(rejectVendorHandler(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DashboardForm);
