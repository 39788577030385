import {
  GET_ASNVIEWDETAILS_REQUEST,
  GET_ASNVIEWDETAILS_SUCCESS,
  GET_ASNVIEWDETAILS_FAILURE,
  PUT_ADDTOASN_REQUEST,
  PUT_ADDTOASN_SUCCESS,
  PUT_ADDTOASN_FAILURE,
  DELETE_SINGLE_ITEM_OF_ASN_FAILURE,
  DELETE_SINGLE_ITEM_OF_ASN_SUCCESS,
  DELETE_SINGLE_ITEM_OF_ASN_REQUEST,
  DELETE_ALL_ITEM_OF_ASN_REQUEST,
  DELETE_ALL_ITEM_OF_ASN_SUCCESS,
  DELETE_ALL_ITEM_OF_ASN_FAILURE,
  POST_CREATEASN_FAILURE,
  POST_CREATEASN_SUCCESS,
  POST_CREATEASN_REQUEST,
} from "./AsnTypes";
import Config from "../../config/index";

import axios from "axios";

const URL = Config.baseUrl;

let auth3Url = Config.auth3Url;

const getAsnViewDetailsRequest = () => {
  return {
    type: GET_ASNVIEWDETAILS_REQUEST,
  };
};

const getAsnViewDetailsSuccess = (payload) => {
  return {
    type: GET_ASNVIEWDETAILS_SUCCESS,
    payload: payload,
  };
};

const getAsnViewDetailsFailure = (error) => {
  return {
    type: GET_ASNVIEWDETAILS_FAILURE,
    payload: error,
  };
};

const getAsnViewDetails = (vendorId) => {
  return (dispatch) => {
    dispatch(getAsnViewDetailsRequest());
    let url = `${auth3Url}/vendor/get_items_from_cart?vendorId=${vendorId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getAsnViewDetailsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAsnViewDetailsFailure(err.message));
      });
  };
};

const addToAsnRequest = () => {
  return {
    type: PUT_ADDTOASN_REQUEST,
  };
};

const addToAsnSuccess = (payload) => {
  return {
    type: PUT_ADDTOASN_SUCCESS,
    payload: payload,
  };
};

const addToAsnFaliure = (error) => {
  return {
    type: PUT_ADDTOASN_FAILURE,
    payload: error,
  };
};
const addToAsn = (payload) => {
  return (dispatch) => {
    dispatch(addToAsnRequest());
    let url = `${auth3Url}/vendor/add_to_cart`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(addToAsnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addToAsnFaliure(err.message));
        return { status: false };
      });
  };
};

const deleteSingleItemOfAsnRequest = () => {
  return {
    type: DELETE_SINGLE_ITEM_OF_ASN_REQUEST,
  };
};

const deleteSingleItemOfAsnSuccess = (payload) => {
  return {
    type: DELETE_SINGLE_ITEM_OF_ASN_SUCCESS,
    payload: payload,
  };
};

const deleteSingleItemOfAsnFaliure = (error) => {
  return {
    type: DELETE_SINGLE_ITEM_OF_ASN_FAILURE,
    payload: error,
  };
};
const deleteSingleItemOfAsn = (vendorId, itemId) => {
  return (dispatch) => {
    dispatch(deleteSingleItemOfAsnRequest());
    let url = `${auth3Url}/vendor/remove_item_from_cart?vendorId=${vendorId}&itemId=${itemId}`;
    return axios
      .put(url)
      .then((response) => {
        dispatch(deleteSingleItemOfAsnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteSingleItemOfAsnFaliure(err.message));
        return { status: false };
      });
  };
};

const deleteAllItemOfAsnRequest = () => {
  return {
    type: DELETE_ALL_ITEM_OF_ASN_REQUEST,
  };
};

const deleteAllItemOfAsnSuccess = (payload) => {
  return {
    type: DELETE_ALL_ITEM_OF_ASN_SUCCESS,
    payload: payload,
  };
};

const deleteAllItemOfAsnFaliure = (error) => {
  return {
    type: DELETE_ALL_ITEM_OF_ASN_FAILURE,
    payload: error,
  };
};
const deleteAllItemOfAsn = (vendorId, itemId) => {
  return (dispatch) => {
    dispatch(deleteAllItemOfAsnRequest());
    let url = `${auth3Url}/vendor/remove_all_items?vendorId=${vendorId}`;
    return axios
      .delete(url)
      .then((response) => {
        dispatch(deleteAllItemOfAsnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteAllItemOfAsnFaliure(err.message));
        return { status: false };
      });
  };
};

const createAsnRequest = () => {
  return {
    type: POST_CREATEASN_REQUEST,
  };
};

const createAsnSuccess = (payload) => {
  return {
    type: POST_CREATEASN_SUCCESS,
    payload: payload,
  };
};

const createAsnFaliure = (error) => {
  return {
    type: POST_CREATEASN_FAILURE,
    payload: error,
  };
};
const createAsn = (payload) => {
  const data = new FormData();

  data.append("vendorId", payload.vendorId);
  data.append("ebn", payload.ebn);
  data.append("invoiceNumber", payload.invoiceNumber);
  data.append("file", payload.file);

  data.append("itemList", JSON.stringify(payload.itemList));

  return (dispatch) => {
    dispatch(createAsnRequest());
    let url = `${auth3Url}/vendor/create_asn`;
    return axios
      .post(url, data)
      .then((response) => {
        dispatch(createAsnSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createAsnFaliure(err.message));
        return { status: false };
      });
  };
};

export {
  getAsnViewDetails,
  addToAsn,
  deleteSingleItemOfAsn,
  deleteAllItemOfAsn,
  createAsn,
};
