import {
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAILURE,
} from "./StatusTypes";
// import Config from "../../config/index";
import axios from "axios";
// const URL = Config.auth3Url;
import Config from "../../config/index";
let auth3Url = Config.auth3Url;

const getStatusRequest = () => {
  return {
    type: GET_STATUS_REQUEST,
  };
};

const getStatusSuccess = (payload) => {
  return {
    type: GET_STATUS_SUCCESS,
    payload: payload,
  };
};

const getStatusFaliure = (error) => {
  return {
    type: GET_STATUS_FAILURE,
    payload: error,
  };
};

const getStatus = () => {
  return (dispatch) => {
    dispatch(getStatusRequest());
    let url = `${auth3Url}/vendor/vendors_not_approved`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getStatusFaliure(err.message));
      });
  };
};

export { getStatus };
