/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import loginReducer from "./authentication/login/loginReducer";
import resetPasswordReducer from "./authentication/reset-password/resetPasswordReducer";
import {
  setPasswordReducer,
  setPasswordForNewUserReducer,
  setNewPasswordReducer,
} from "./authentication/set-password/setPasswordReducer";
import {
  forgotPasswordReducer,
  validateOtpForForgotPasswordReducer,
} from "./authentication/forgot-password/forgotPasswordReducer";
import profileReducer from "./profile/profileReducer";


import {
  purchasedOrdersReducer,
  notAcceptedPurchasedOrdersReducer,
  acceptPoReducer,
  purchasedOrderStatusReducer,
  getAsnDataReducer,
  listOfItemsReducer,
  updateAsnQtyReducer,
} from "./PurchasedOrders/PurchasedOrdersReducers";

import { printQRReducer } from "./PrintQR/PrintQRReducers";
import {
  asnDetailsReducer,
  asnItemsDetailsReducer,
} from "./AsnDetails/AsnDetailsReducers";

import {
  asnViewDetailsReducer,
  addToAsnReducer,
  createAsnReducer,
} from "./Asn/AsnReducers";

import { registeredVendorsReducer } from "./RegisteredVendors/RegisteredVendorsReducers";
import { rejectedVendorsReducer } from "./RejectedVendors/RejectedVendorsReducers";
import { statusReducer } from "./Status/StatusReducers";
import { otpValidateReducer, otpReducer } from "./Otp/OtpReducers";
import {
  vendorDetailsReducer,
  vendorRegistrationLinkReducer,
  resendVendorRegistrationLinkReducer,
  getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer,
  acceptVendorHandlerReducer,
  rejectVendorHandlerReducer,
  updateEmailReducer,
  getPreviewReducer,
  getVendorIdReducer,
} from "./VendorDetails/VendorDetailsReducers";

const rootReducer = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
  setNewPasswordReducer: setNewPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  validateOtpForForgotPasswordReducer: validateOtpForForgotPasswordReducer,
  profile: profileReducer,
  purchasedOrdersReducer: purchasedOrdersReducer,
  acceptPoReducer: acceptPoReducer,
  listOfItemsReducer: listOfItemsReducer,
  asnViewDetailsReducer: asnViewDetailsReducer,
  addToAsnReducer: addToAsnReducer,
  purchasedOrderStatusReducer: purchasedOrderStatusReducer,
  getAsnDataReducer: getAsnDataReducer,
  createAsnReducer: createAsnReducer,
  updateAsnQtyReducer: updateAsnQtyReducer,
  vendorDetailsReducer: vendorDetailsReducer,
  rejectedVendorsReducer: rejectedVendorsReducer,
  registeredVendorsReducer: registeredVendorsReducer,
  statusReducer: statusReducer,
  updateEmailReducer: updateEmailReducer,
  vendorRegistrationLinkReducer: vendorRegistrationLinkReducer,
  resendVendorRegistrationLinkReducer: resendVendorRegistrationLinkReducer,
  getVendorByVendorIdReducer: getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer: getVendorsNotApprovedReducer,
  acceptVendorHandlerReducer: acceptVendorHandlerReducer,
  rejectVendorHandlerReducer: rejectVendorHandlerReducer,
  getPreviewReducer: getPreviewReducer,
  getVendorIdReducer: getVendorIdReducer,
  otpValidateReducer: otpValidateReducer,
  otpReducer: otpReducer,
  notAcceptedPurchasedOrdersReducer: notAcceptedPurchasedOrdersReducer,
  printQRReducer: printQRReducer,
  asnDetailsReducer: asnDetailsReducer,
  asnItemsDetailsReducer: asnItemsDetailsReducer,
});

export default rootReducer;
