export const GET_ASNVIEWDETAILS_REQUEST = "GET_ASNVIEWDETAILS_REQUEST";
export const GET_ASNVIEWDETAILS_SUCCESS = "GET_ASNVIEWDETAILS_SUCCESS";
export const GET_ASNVIEWDETAILS_FAILURE = "GET_ASNVIEWDETAILS_FAILURE";

export const PUT_ADDTOASN_REQUEST = "PUT_ADDTOASN_REQUEST";
export const PUT_ADDTOASN_SUCCESS = "PUT_ADDTOASN_SUCCESS";
export const PUT_ADDTOASN_FAILURE = "PUT_ADDTOASN_FAILURE";

export const ADD_TO_ASN = "ADD_TO_ASN";
export const ADJUST_ITEM_QTY = "ADJUST_ITEM_QTY";
export const REMOVE_FROM_ASN = "REMOVE_FROM_ASN";
export const LOAD_CURRENT_ITEM = "LOAD_CURRENT_ITEM";


export const  DELETE_SINGLE_ITEM_OF_ASN_REQUEST = "DELETE_SINGLE_ITEM_OF_ASN_REQUEST";
export const  DELETE_SINGLE_ITEM_OF_ASN_SUCCESS = "DELETE_SINGLE_ITEM_OF_ASN_SUCCESS";
export const  DELETE_SINGLE_ITEM_OF_ASN_FAILURE = "DELETE_SINGLE_ITEM_OF_ASN_FAILURE";

export const  DELETE_ALL_ITEM_OF_ASN_REQUEST = "DELETE_ALL_ITEM_OF_ASN_REQUEST";
export const  DELETE_ALL_ITEM_OF_ASN_SUCCESS = "DELETE_ALL_ITEM_OF_ASN_SUCCESS";
export const  DELETE_ALL_ITEM_OF_ASN_FAILURE = "DELETE_ALL_ITEM_OF_ASN_FAILURE";

export const POST_CREATEASN_REQUEST = "POST_CREATEASN_REQUEST";
export const POST_CREATEASN_SUCCESS = "POST_CREATEASN_SUCCESS";
export const POST_CREATEASN_FAILURE = "POST_CREATEASN_FAILURE";


// deleteSingleItemOfAsn