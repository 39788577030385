import {
  PUT_CREATEVENDORLINK_REQUEST,
  PUT_CREATEVENDORLINK_SUCCESS,
  PUT_CREATEVENDORLINK_FAILURE,
  PUT_RESENDVENDORLINK_REQUEST,
  PUT_RESENDVENDORLINK_SUCCESS,
  PUT_RESENDVENDORLINK_FAILURE,
  PUT_VENDORDETAILS_REQUEST,
  PUT_VENDORDETAILS_SUCCESS,
  PUT_VENDORDETAILS_FAILURE,
  GET_VENDOR_REQUEST,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAILURE,
  GET_VENDORSNOTAPPROVED_REQUEST,
  GET_VENDORSNOTAPPROVED_SUCCESS,
  GET_VENDORSNOTAPPROVED_FAILURE,
  PUT_ACCEPTHANDLER_REQUEST,
  PUT_ACCEPTHANDLER_SUCCESS,
  PUT_ACCEPTHANDLER_FAILURE,
  PUT_REJECTHANDLER_REQUEST,
  PUT_REJECTHANDLER_SUCCESS,
  PUT_REJECTHANDLER_FAILURE,
  PUT_UPDATEEMAIL_REQUEST,
  PUT_UPDATEEMAIL_SUCCESS,
  PUT_UPDATEEMAIL_FAILURE,
  GET_PREVIEW_SUCCESS,
  SET_VENDORID_REQUEST,
  SET_VENDORID_SUCCESS,
  SET_VENDORID_FAILURE,
} from "./VendorDetailsTypes";

const initialState = {
  loading: false,
  createVendorLink: {},
  error: "",
};

const vendorRegistrationLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_CREATEVENDORLINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_CREATEVENDORLINK_SUCCESS:
      return {
        loading: false,
        createVendorLink: action.payload,
        error: "",
      };
    case PUT_CREATEVENDORLINK_FAILURE:
      return {
        loading: false,
        createVendorLink: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForResendVendorRegistrationLink = {
  loading: false,
  resendVendorRegistrationLink: {},
  error: "",
};

const resendVendorRegistrationLinkReducer = (state = initialStateForResendVendorRegistrationLink, action) => {
  switch (action.type) {
    case PUT_RESENDVENDORLINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_RESENDVENDORLINK_SUCCESS:
      return {
        loading: false,
        resendVendorRegistrationLink: action.payload,
        error: "",
      };
    case PUT_RESENDVENDORLINK_FAILURE:
      return {
        loading: false,
        resendVendorRegistrationLink: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVendorDetails = {
  loading: false,
  submitVendorDetails: {},
  error: "",
};

const vendorDetailsReducer = (
  state = initialStateForVendorDetails,
  action
) => {
  switch (action.type) {
    case PUT_VENDORDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_VENDORDETAILS_SUCCESS:
      return {
        loading: false,
        submitVendorDetails: action.payload,
        error: "",
      };
    case PUT_VENDORDETAILS_FAILURE:
      return {
        loading: false,
        submitVendorDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVendor = {
  loading: false,
  getVendor: {},
  error: "",
};

const getVendorByVendorIdReducer = (state = initialStateForVendor, action) => {
  switch (action.type) {
    case GET_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VENDOR_SUCCESS:
      return {
        loading: false,
        getVendor: action.payload,
        error: "",
      };
    case GET_VENDOR_FAILURE:
      return {
        loading: false,
        getVendor: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVendorsNotApproved = {
  loading: false,
  vendorsNotApproved: {},
  error: "",
};

const getVendorsNotApprovedReducer = (
  state = initialStateForVendorsNotApproved,
  action
) => {
  switch (action.type) {
    case GET_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VENDOR_SUCCESS:
      return {
        loading: false,
        vendorsNotApproved: action.payload,
        error: "",
      };
    case GET_VENDOR_FAILURE:
      return {
        loading: false,
        vendorsNotApproved: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAcceptHandler = {
  loading: false,
  acceptHandler: {},
  error: "",
};

const acceptVendorHandlerReducer = (
  state = initialStateForAcceptHandler,
  action
) => {
  switch (action.type) {
    case PUT_ACCEPTHANDLER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_ACCEPTHANDLER_SUCCESS:
      return {
        loading: false,
        acceptHandler: action.payload,
        error: "",
      };
    case PUT_ACCEPTHANDLER_FAILURE:
      return {
        loading: false,
        acceptHandler: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRejectHandler = {
  loading: false,
  rejectHandler: {},
  error: "",
};

const rejectVendorHandlerReducer = (
  state = initialStateForRejectHandler,
  action
) => {
  switch (action.type) {
    case PUT_REJECTHANDLER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_REJECTHANDLER_SUCCESS:
      return {
        loading: false,
        rejectHandler: action.payload,
        error: "",
      };
    case PUT_REJECTHANDLER_FAILURE:
      return {
        loading: false,
        rejectHandler: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdateEmail = {
  loading: false,
  emailData: {},
  error: ""
}

const updateEmailReducer = (state = initialStateForUpdateEmail, action) => {
  switch (action.type) {
      case PUT_UPDATEEMAIL_REQUEST:
          return {
              ...state,
              loading: true
          }
      case PUT_UPDATEEMAIL_SUCCESS:
          return {
              loading: false,
              emailData: action.payload,
              error: ''
          }
      case PUT_UPDATEEMAIL_FAILURE:
          return {
              loading: false,
              emailData: {},
              error: action.payload
          }
      default:
          return state
  }
}

const initialStateForPreview = {
  loading: false,
  preview: {},
  error: "",
};

const getPreviewReducer = (state = initialStateForPreview, action) => {
  switch (action.type) {
    case GET_PREVIEW_SUCCESS:
      return {
        loading: false,
        preview: action.payload,
        error: "",
      };

    default:
      return state;
  }
};

const initialStateForVendorId = {
  loading: false,
  vendorId: "",
  error: "",
};

const getVendorIdReducer = (state = initialStateForVendorId, action) => {
  switch (action.type) {
    case SET_VENDORID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_VENDORID_SUCCESS:
      return {
        loading: false,
        vendorId: action.payload,
        error: "",
      };
    case SET_VENDORID_FAILURE:
      return {
        loading: false,
        vendorId: "",
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  vendorRegistrationLinkReducer,
  resendVendorRegistrationLinkReducer,
  vendorDetailsReducer,
  getVendorByVendorIdReducer,
  getVendorsNotApprovedReducer,
  acceptVendorHandlerReducer,
  rejectVendorHandlerReducer,
  updateEmailReducer,
  getPreviewReducer,
  getVendorIdReducer,
};
