export const PUT_CREATEVENDORLINK_REQUEST = "PUT_CREATEVENDORLINK_REQUEST";
export const PUT_CREATEVENDORLINK_SUCCESS = "PUT_CREATEVENDORLINK_SUCCESS";
export const PUT_CREATEVENDORLINK_FAILURE = "PUT_CREATEVENDORLINK_FAILURE";

export const PUT_RESENDVENDORLINK_REQUEST = "PUT_RESENDVENDORLINK_REQUEST";
export const PUT_RESENDVENDORLINK_SUCCESS = "PUT_RESENDVENDORLINK_SUCCESS";
export const PUT_RESENDVENDORLINK_FAILURE = "PUT_RESENDVENDORLINK_FAILURE";

export const PUT_VENDORDETAILS_REQUEST = "PUT_VENDORDETAILS_REQUEST";
export const PUT_VENDORDETAILS_SUCCESS = "PUT_VENDORDETAILS_SUCCESS";
export const PUT_VENDORDETAILS_FAILURE = "PUT_VENDORDETAILS_FAILURE";

export const GET_VENDORSNOTAPPROVED_REQUEST = "GET_VENDORSNOTAPPROVED_REQUEST";
export const GET_VENDORSNOTAPPROVED_SUCCESS = "GET_VENDORSNOTAPPROVED_SUCCESS";
export const GET_VENDORSNOTAPPROVED_FAILURE = "GET_VENDORSNOTAPPROVED_FAILURE";

export const GET_VENDOR_REQUEST = "GET_VENDOR_REQUEST";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAILURE = "GET_VENDOR_FAILURE";

export const PUT_ACCEPTHANDLER_REQUEST = "PUT_ACCEPTHANDLER_REQUEST";
export const PUT_ACCEPTHANDLER_SUCCESS = "PUT_ACCEPTHANDLER_SUCCESS";
export const PUT_ACCEPTHANDLER_FAILURE = "PUT_ACCEPTHANDLER_FAILURE";

export const PUT_REJECTHANDLER_REQUEST = "PUT_REJECTHANDLER_REQUEST";
export const PUT_REJECTHANDLER_SUCCESS = "PUT_REJECTHANDLER_SUCCESS";
export const PUT_REJECTHANDLER_FAILURE = "PUT_REJECTHANDLER_FAILURE";

export const PUT_UPDATEEMAIL_REQUEST = "PUT_UPDATEEMAIL_REQUEST";
export const PUT_UPDATEEMAIL_SUCCESS = "PUT_UPDATEEMAIL_SUCCESS";
export const PUT_UPDATEEMAIL_FAILURE = "PUT_UPDATEEMAIL_FAILURE";

export const GET_PREVIEW_SUCCESS = "GET_PREVIEW_SUCCESS";

export const SET_VENDORID_REQUEST = "SET_VENDORID_REQUEST";
export const SET_VENDORID_SUCCESS = "SET_VENDORID_SUCCESS";
export const SET_VENDORID_FAILURE = "SET_VENDORID_FAILURE";
