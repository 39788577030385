/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Dashboard components
import MDBox from "./components/MDBox";

// Dashboard example components
import Sidenav from "./examples/Sidenav";
import SidenavForAdmin from "./examples/Sidenav/SidenavForAdmin";
import Configurator from "./examples/Configurator";

// Dashboard themes
import theme from "./assets/theme";

// Dashboard Dark Mode themes
import themeDark from "./assets/theme-dark";

// Dashboard routes
import routes from "./routes";

// Dashboard contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

// Dashboard layouts (includes sub route components)
import Profile from "./layouts/profile";
import Login from "./layouts/authentication/login";
import Logout from "./layouts/authentication/logout";
import ResetPassword from "./layouts/authentication/reset-password";
import SetPassword from "./layouts/authentication/set-password";
import ForgotPassword from "./layouts/authentication/forgot-password";
import Asn from "./layouts/asn/index";
import DashboardForm from "layouts/DashboardForm";
import VendorForm from "layouts/vendorForm/index";
import Status from "layouts/status/index";
import Otp from "layouts/Otp/index";
import FormPreview from "layouts/vendorForm/FormPreview";
import RejectedVendorForm from "layouts/vendorForm/RejectedVendorForm";

import Tooltip from "@mui/material/Tooltip";
// JWT and Cookies
import { isExpired } from "react-jwt";
import Cookies from "universal-cookie";
import routesForAdmin from "routesForAdmin";
import VendorDetails from "layouts/vendorDetails";
import AsnDetails from "layouts/asnDetails";
const cookies = new Cookies();

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Login details from cookies

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="none"
      justifyContent="center"
      alignItems="center"
      width="1.25rem"
      height="1.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="1rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Tooltip title="Settings">
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </Tooltip>
    </MDBox>
  );

  let data = cookies.get("vendor-portal-user-data");

  let forgotPasswordData = cookies.get("forgot-password-vendor-portal");
  

  let token = cookies.get("vendor-portal-jwtToken");
  const isMyTokenExpired = isExpired(token);

  if (data && data.data.data.isLastLogin) {
    if (data.status) {
      // cookies.set("jwtForMobillorEditor", token, { path: "/" });
      if (data.data.data.role === "admin") {
        return (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand
                  routes={routesForAdmin}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routesForAdmin)}
              <Route
                path="*"
                element={<Navigate to="/new-vendor-request" replace />}
              />
              <Route
                exact
                path="/status/:vendorId"
                element={<DashboardForm />}
                key="DashboardForm"
              />
              <Route
                exact
                path="/vandor-form"
                element={<VendorForm />}
                key="VendorForm"
              />
              <Route
                exact
                path="/rejected-vendors/:vendorId"
                element={<DashboardForm />}
                key="DashboardForm"
              />
              <Route
                exact
                path="/registered-vendors/:vendorId"
                element={<DashboardForm />}
                key="DashboardForm"
              />
              <Route exact path="/status" element={<Status />} key="status" />
              <Route
                path="/registered-vendors"
                element={<Navigate to="/registered-vendors" replace />}
              />
              <Route
                path="/rejected-vendors"
                element={<Navigate to="/rejected-vendors" replace />}
              />
              <Route
                exact
                path="/profile"
                element={<Profile />}
                key="profile"
              />
              <Route
                exact
                path="/authentication/reset-password"
                element={<ResetPassword />}
                key="resetPassword"
              />
            </Routes>
          </ThemeProvider>
        );
      } else {
        return (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routes)}
              <Route
                exact
                path="/profile"
                element={<Profile />}
                key="profile"
              />
              <Route
                exact
                path="/authentication/reset-password"
                element={<ResetPassword />}
                key="resetPassword"
              />

              <Route
                path="/purchase-orders"
                element={<Navigate to="/purchase-orders" replace />}
                key="purchase-orders"
              />

              <Route
                path="*"
                element={<Navigate to="/new-purchase-orders" replace />}
              />
             
              <Route
                exact
                path="/asn-details"
                element={<AsnDetails />}
                key="asn-details"
              />

              <Route exact path="/asn" element={<Asn />} key="asn" />
            </Routes>
          </ThemeProvider>
        );
      }
    } else {
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Routes>
          <Route
            exact
            path="/authentication/logout"
            element={<Logout />}
            key="logout"
          />
          <Route
            path="*"
            element={<Navigate to="/authentication/logout" replace />}
          />
        </Routes>
      </ThemeProvider>;
    }
  } else if (isMyTokenExpired && !forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/verification"
            element={<Otp />}
            key="verification"
          />
          <Route
            exact
            path="/vendor-registration-form"
            element={<Navigate to="/" replace />}
            key="vendor-registration-form"
          />
          <Route
            exact
            path="/update-vendor-form"
            element={<Navigate to="/" replace />}
            key="update-vendor-form"
          />
          <Route
            exact
            path="/preview"
            element={<Navigate to="/" replace />}
            key="preview"
          />
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route
            exact
            path="/set-new-password"
            element={<Navigate to="/" replace />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Login />} key="login" />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }
  else if (isMyTokenExpired && forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
        <Route
            exact
            path="/verification"
            element={<Otp />}
            key="verification"
          />
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Login />} key="login" />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }
  else if (!isMyTokenExpired && forgotPasswordData){
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/verification"
            element={<Otp />}
            key="verification"
          />
          <Route
            exact
            path="/vendor-registration-form"
            element={<VendorForm />}
            key="vendor-registration-form"
          />
          <Route
            exact
            path="/update-vendor-form"
            element={<RejectedVendorForm />}
            key="update-vendor-form"
          />
          <Route
            exact
            path="/preview"
            element={<FormPreview />}
            key="preview"
          />
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
         <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/verification"
            element={<Otp />}
            key="verification"
          />
          <Route
            exact
            path="/vendor-registration-form"
            element={<VendorForm />}
            key="vendor-registration-form"
          />
          <Route
            exact
            path="/update-vendor-form"
            element={<RejectedVendorForm />}
            key="update-vendor-form"
          />
          <Route
            exact
            path="/preview"
            element={<FormPreview />}
            key="preview"
          />
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route
            exact
            path="/set-new-password"
            element={<Navigate to="/" replace />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }
}
