/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";


// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";


// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

// @mui material icons
import Icon from "@mui/material/Icon";

// Functions from store
import { getRegisteredVendors } from "../../store/RegisteredVendors/RegisteredVendorsActions";


const RegisteredVendors = ({
  getRegisteredVendors,
  registeredVendorsReducer,
}) => {
  useEffect(() => {
    getRegisteredVendors();
  }, [getRegisteredVendors]);

  let loading = registeredVendorsReducer.loading;

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "vendor Name", accessor: "vendorName" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Email", accessor: "email" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });
  const { columns, rows } = state;
  const viewFunction = (data) => {
    
    navigate(`/registered-vendors/${data.vendorId}`);
  };
  useEffect(() => {
    let tempUsers = [];
    let registeredVendor = registeredVendorsReducer.registeredVendors.data
      ? registeredVendorsReducer.registeredVendors.data
      : [];
    registeredVendor.map((user) => {
      const singleUser = {
        privateKey: user.email,
        vendorName: user.vendorName,
        contactPerson: user.contactPerson,
        email: user.email,
        action: (
          <Tooltip title="View Form">
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              type="button"
              onClick={() => viewFunction(user)}
              
            >
              <Icon>visibility_icon</Icon>
            </MDButton>
          </Tooltip>
        ),
      };
      tempUsers.push(singleUser);
    });
    setState({ ...state, rows: tempUsers });
  }, [registeredVendorsReducer]);

 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Registered Vendors
                </MDTypography>
              </MDBox>
              <MDBox pt={3}> 
                <DataTable 
                color="Dark"
                  table={{ columns, rows }} 
                  isSorted={true} 
                  canSearch={true} 
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30], 
                  }}  
                  showTotalEntries={true} 
                  noEndBorder 
                  
                />
              </MDBox>
            </Card> 
          </Grid>
        </Grid>
      </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
          
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
     {!loading ? (
      <Footer />
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    registeredVendorsReducer: state.registeredVendorsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getRegisteredVendors: (payload) => dispatch(getRegisteredVendors(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(RegisteredVendors);
