/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import MDSnackbar from "components/MDSnackbar";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable/index";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Functions from store
import {
  getListOfItems,
  addToAsn,
  getPurchasedOrderStatus,
  getPurchasedOrders,
  getAsnViewDetails,
  updateAsnQty,
  deleteUpdatedAsnData,
} from "../../store";

// Router Components
import { useParams, useLocation, useNavigate } from "react-router-dom";

// React-Select component and styling
import Select from "react-select";

// Custom css
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PurchaseOrders = ({
  addToAsn,
  getPurchasedOrders,
  purchasedOrdersReducer,
  getAsnViewDetails,
  asnViewDetailsReducer,
}) => {
  let user = cookies.get("vendor-portal-user-data");

  let role = user.data.data.role;

  let vendorId = user.data.data.vendorId;
  const emailId = user.data.data.email;

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      {
        Header: "PO Number",
        accessor: "orderNumber",
        align: "left",
      },

      {
        Header: "PO created date",
        accessor: "createdDate",

        align: "left",
      },

      {
        Header: "PO total amount",
        accessor: "totalAmount",
        align: "left",
      },

      {
        Header: "Status",
        accessor: "status",

        align: "left",
      },

      {
        Header: "Line item no",
        accessor: "lineItemNumber",
        align: "left",
      },

      {
        Header: "HSN code",
        accessor: "HSNCode",
        align: "left",
      },

      {
        Header: "Item code",
        accessor: "itemCode",
        align: "left",
      },

      {
        Header: "Item Name",
        accessor: "item",
        align: "left",
      },

      {
        Header: "UOM",
        accessor: "UOM",
        align: "left",
      },

      {
        Header: "Item Qty",
        accessor: "itemQty",
        align: "left",
      },

      {
        Header: "Remaining Qty",
        accessor: "remainingQty",
        align: "left",
      },

      {
        Header: "Amount",
        accessor: "amount",
        align: "left",
      },

      {
        Header: "Planned receiving date",
        accessor: "receivingDate",
        align: "left",
      },
    ],

    rows: [],
  });

  const { columns, rows } = state;

  const [selectedAsnData, setSelectedAsnData] = useState([]);

  const [uncheck, setUncheck] = useState(false);

  const asnData = (data) => {
    setSelectedAsnData(data);
  };

  const statusOptions = [
    { label: "Open", value: "open" },
    { label: "Closed", value: "closed" },
  ];

  const [statusSelected, setStatusSelected] = useState("");

  const handleChangeStatus = (selectedOption) => {
    setStatusSelected(selectedOption);
  };

  useEffect(() => {
    getPurchasedOrders(emailId, statusSelected ? statusSelected.value : "");
  }, [getPurchasedOrders, statusSelected]);

  let loading = purchasedOrdersReducer.loading;

  let asnDataLength = asnViewDetailsReducer.asnViewDetails.data
    ? asnViewDetailsReducer.asnViewDetails.data.length
    : 0;

  useEffect(() => {
    getAsnViewDetails(vendorId);
  }, [getAsnViewDetails]);

  let tempPurchaseOrders = [];

  useEffect(() => {
    let purchaseOrdersData = purchasedOrdersReducer.purchasedOrders.data
      ? purchasedOrdersReducer.purchasedOrders.data
      : [];

    purchaseOrdersData.map((data) => {
      const singleData = {
        privateKey: data.itemId,
        orderNumber: data.orderNumber,
        createdDate: data.createdDate,
        totalAmount: data.totalAmount,

        status: data.status,
        lineItemNumber: data.lineItemNumber,
        itemId: data.itemId,
        item: data.item,
        itemQty: data.itemQty,
        asnQty: data.asnQty,
        HSNCode: data.hsnCode,

        itemCode: data.itemCode,
        UOM: data.uom,
        remainingQty: data.remainingQty,
        amount: data.amount,
        receivingDate: data.receivingDate,
      };

      tempPurchaseOrders.push(singleData);
    });
    setState({ ...state, rows: tempPurchaseOrders });
  }, [purchasedOrdersReducer]);

  const asnCreatedNotification = () =>
    toast.error("No PO Selected", {
      position: "top-center",
      autoClose: 2000,
    });

  const addToAsnHandler = async () => {
    getPurchasedOrders(emailId, statusSelected);

    const asnObject = {
      vendorId: vendorId,
      // orderNumber: orderNumber ? orderNumber : "",
      itemList: selectedAsnData ? selectedAsnData : [],
    };

    const res = await addToAsn(asnObject);

    if (res.status) {
      setUncheck(!uncheck);
      getPurchasedOrders(emailId, statusSelected);
      getAsnViewDetails(vendorId);
    }

    if (selectedAsnData == "") {
      asnCreatedNotification();
      // toggleSnackbar();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  mb={-6}
                >
                  <MDBox>
                    <MDTypography variant="h4" gutterBottom>
                      Accepted Purchase Orders
                    </MDTypography>
                    <MDBox
                      style={{
                        width: "200px",
                      }}
                    >
                      <Select
                        isClearable
                        className="select-css"
                        placeholder="All Status"
                        name="status"
                        value={statusSelected}
                        options={statusOptions}
                        onChange={(selectedOption) => {
                          handleChangeStatus(selectedOption);
                        }}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>

                <MDBox pt={3}>
                  {role === "user" ? (
                    <>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        canSearch={true}
                        uncheckProp={uncheck}
                        selection={true}
                        selectionButtonClick={asnData}
                        selectionButtonComponent={
                          <Tooltip title="Add To ASN">
                            <MDButton
                              color="white"
                              type="button"
                              style={{
                                marginTop: "0.5rem",
                                color: "white",
                                border: "1px solid #0275d8",
                                background: "#0275d8",
                                marginLeft: 5,
                              }}
                              onClick={addToAsnHandler}
                            >
                              Add To ASN
                            </MDButton>
                          </Tooltip>
                        }
                        entriesPerPage={{
                          defaultValue: 5,
                          entries: [5, 10, 15, 20, 25, 30],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    </>
                  ) : null}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />

      <MDSnackbar
        color="error"
        icon="notifications"
        title="Error"
        content="No PO Selected"
        open={show}
        close={toggleSnackbar}
      />
      {!loading ? (
      <Footer />
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    listOfItemsReducer: state.listOfItemsReducer,
    addToAsnReducer: state.addToAsnReducer,
    purchasedOrderStatusReducer: state.purchasedOrderStatusReducer,
    purchasedOrdersReducer: state.purchasedOrdersReducer,
    updateAsnQtyReducer: state.updateAsnQtyReducer,
    asnViewDetailsReducer: state.asnViewDetailsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getListOfItems: (vendorId, orderNumber) =>
      dispatch(getListOfItems(vendorId, orderNumber)),
    addToAsn: (payload) => dispatch(addToAsn(payload)),
    getPurchasedOrderStatus: (orderNumber, vendorId) =>
      dispatch(getPurchasedOrderStatus(orderNumber, vendorId)),
    getPurchasedOrders: (emailId, statusSelected) =>
      dispatch(getPurchasedOrders(emailId, statusSelected)),
    getAsnViewDetails: (vendorId) => dispatch(getAsnViewDetails(vendorId)),
    deleteUpdatedAsnData: (vendorId) =>
      dispatch(deleteUpdatedAsnData(vendorId)),
    updateAsnQty: (payload) => dispatch(updateAsnQty(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(PurchaseOrders);
