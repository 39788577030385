import {
  CREATE_PRINTQR_REQUEST,
  CREATE_PRINTQR_SUCCESS,
  CREATE_PRINTQR_FAILURE,
} from "./PrintQRTypes";

const initialStateForPrintQR = {
  loading: false,
  printQR: {},
  error: "",
};

const printQRReducer = (state = initialStateForPrintQR, action) => {
  switch (action.type) {
    case CREATE_PRINTQR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PRINTQR_SUCCESS:
      return {
        loading: false,
        printQR: action.payload,
        error: "",
      };
    case CREATE_PRINTQR_FAILURE:
      return {
        loading: false,
        printQR: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { printQRReducer };
