/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import MDSnackbar from "components/MDSnackbar";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable/index";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Functions from store
import { getAsnDetails, getAsnItemsDetails, createPrintQR } from "../../store";

// Router Components
import { useParams, useLocation, useNavigate } from "react-router-dom";

// React-Select component and styling
import Select from "react-select";

// Custom css
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleForQR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 196,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const styleForGRN = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const AsnDetails = ({
  getAsnDetails,
  asnDetailsReducer,
  getAsnItemsDetails,
  asnItemsDetailsReducer,
  createPrintQR,
  printQRReducer,
}) => {
  let user = cookies.get("vendor-portal-user-data");

  let role = user.data.data.role;

  let vendorId = user.data.data.vendorId;
  const emailId = user.data.data.email;

  const [show, setShow] = useState(false);

  const toggleSnackbar = () => setShow(!show);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // const history = useHistory();

  // const { orderNumber } = params;

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");

  const [state, setState] = useState({
    columns: [
      { Header: "ASN Code", accessor: "asnCode", align: "left" },
      { Header: "Created Date", accessor: "createdDate", align: "left" },
      { Header: "Receiving Date", accessor: "receivingDate", align: "left" },

      { Header: "Grn Number", accessor: "grnNumber", align: "left" },

      { Header: "View Grn Details", accessor: "grnDetails", align: "left" },

      { Header: "E-WayBill No", accessor: "ebn", align: "left" },
      { Header: "Total Asn Qty ", accessor: "totalAsnQty", align: "left" },
      { Header: "Total Amount", accessor: "totalAmount", align: "left" },
      { Header: "QR Code", accessor: "action", align: "center" },
    ],

    rows: [],
  });

  const { columns, rows } = state;

  const [asnId, setAsnId] = useState("");

  const [listOfItemsState, setListOfItemsState] = useState({
    columns: [
      {
        Header: "Order Number",
        accessor: "orderNumber",
        align: "left",
        // width: 800
      },

      {
        Header: "Line item no",
        accessor: "lineItemNumber",
        align: "left",
        // width: 500
      },

      {
        Header: "Item code",
        accessor: "itemCode",
        align: "left",
        // width: 500
      },

      {
        Header: "Item Name",
        accessor: "item",
        align: "left",
        // width: 500
      },

      {
        Header: "Item Qty",
        accessor: "itemQty",
        align: "left",
        // width: 500
      },

      {
        Header: "Asn Qty",
        accessor: "asnQty",
        align: "left",
        // width: 500
      },

      {
        Header: "Planned receiving date",
        accessor: "receivingDate",
        align: "left",
        // width: 500
      },
    ],
    rows: [],
  });
  const { columns: pColumns, rows: pRows } = listOfItemsState;

  useEffect(() => {
    getAsnDetails(emailId);
  }, [getAsnDetails]);

  let loading = asnDetailsReducer.loading;

  const [openPrintQRModal, setOpenPrintQRModal] = useState(false);

  const [singleDataForPrintQRModal, setSingleDataForPrintQRModal] = useState(
    {}
  );

  const handleOpenPrintQRModal = (qrData = {}) => {
    setSingleDataForPrintQRModal(qrData);
    setOpenPrintQRModal(true);
  };

  const handleClosePrintQRModal = () => {
    setOpenPrintQRModal(false);
  };

  const [openGrnDetailsModal, setOpenGrnDetailsModal] = useState(false);

  const [singleDataForGrnDetails, setSingleDataForGrnDetails] = useState({});

  const handleOpenGrnDetailsModal = (grnDetails = {}) => {
    setSingleDataForGrnDetails(grnDetails);
    setOpenGrnDetailsModal(true);
  };

  const handleCloseGrnDetailsModal = () => {
    setOpenGrnDetailsModal(false);
  };

  let tempAsnDetailsData = [];

  useEffect(() => {
    let asnDetailsData = asnDetailsReducer.asnDetails.data
      ? asnDetailsReducer.asnDetails.data
      : [];

    asnDetailsData.map((data) => {
      const singleData = {
        privateKey: data.asnCode,
        asnCode: data.asnCode,
        createdDate: data.createdDate,
        receivingDate: data.receivingDate,

        grnDetails: (
          <div>
            {data.grnNumber ? (
              <Tooltip title="View GRN Details">
                <MDButton
                  
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={() => handleOpenGrnDetailsModal(data)}
                  iconOnly
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
            ) : null}
          </div>
        ),
        grnNumber: data.grnNumber,
        // grnNumber: `${data.grnNumber}/${grnNumber2}`,

        ebn: data.ebn,
        totalAsnQty: data.totalAsnQty,
        totalAmount: data.totalAmount,
        isPrinted: data.isPrinted,

        action: (
          <div>
            <Tooltip title="Print QR Code">
              <MDButton
                variant="gradient"
                color="success"
                type="button"
                onClick={() => handleOpenPrintQRModal(data)}
                iconOnly
              >
                <Icon> print </Icon>
              </MDButton>
            </Tooltip>
          </div>
        ),
        expand: (
          <IconButton aria-label="expand row" size="small">
            {asnId === data.asnCode && open === true ? (
              <MDTypography>
                <KeyboardArrowUpIcon
                  onClick={() => {
                    setTitle(data.asnCode);
                    setAsnId(data.asnCode);
                    setOpen(false);
                  }}
                />
              </MDTypography>
            ) : (
              <MDTypography>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setTitle(data.asnCode);
                    setAsnId(data.asnCode);
                    setOpen(true);
                  }}
                />
              </MDTypography>
            )}
          </IconButton>
        ),
        items: (
          <Collapse
            in={asnId === data.asnCode && open}
            timeout="auto"
            unmountOnExit
          >
            <MDBox sx={{ margin: 1, width: "150%", marginLeft: 10 }}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                mb={-5}
              >
                {/* <MDBox>
                  <MDTypography variant="h5" gutterBottom>
                    {title}
                  </MDTypography>
                </MDBox> */}
              </MDBox>
              <DataTable
                table={{ columns: pColumns, rows: pRows }}
                totalRecords={rows.length}
                isSorted={true}
                canSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
              />
            </MDBox>
          </Collapse>
        ),
      };

      tempAsnDetailsData.push(singleData);
    });
    setState({ ...state, rows: tempAsnDetailsData });
  }, [asnDetailsReducer, open, listOfItemsState]);

  useEffect(() => {
    getAsnItemsDetails(asnId);
  }, [asnId]);

  useEffect(() => {
    let tempAsnItemsDetailsData = [];
    let data = asnItemsDetailsReducer.asnItemsDetails.data
      ? asnItemsDetailsReducer.asnItemsDetails.data
      : [];

    data.map((data) => {
      const asnItemlistObject = {
        privateKey: data.itemId,
        orderNumber: data.orderNumber,

        totalAmount: data.totalAmount,

        lineItemNumber: data.lineItemNumber,
        itemCode: data.itemCode,
        item: data.item,
        itemQty: data.itemQty,
        asnQty: data.asnQty,

        // remainingQty: data.remainingQty,
        amount: data.amount,
        receivingDate: data.receivingDate,
      };
      tempAsnItemsDetailsData.push(asnItemlistObject);
    });
    setListOfItemsState({ ...listOfItemsState, rows: tempAsnItemsDetailsData });
  }, [asnItemsDetailsReducer]);

  const clickHandler = () => {
    navigate("/purchase-orders");
  };

  const asnCreatedNotification = () =>
    toast.error("No PO Selected", {
      position: "top-center",
      autoClose: 2000,
    });

  const [isErrorAcceptPo, setIsErrorAcceptPo] = useState(false);

  useEffect(() => {
    if (isErrorAcceptPo) {
      setTimeout(() => {
        setIsErrorAcceptPo(false);
      }, 3000);
    }
  }, [isErrorAcceptPo]);

  const acceptedPoNotification = () =>
    toast.success("QRCode Printed Successfully", {
      position: "top-center",
    });

  const printQRHandler = async () => {
    let payload = {
      asnCode: singleDataForPrintQRModal.asnCode,
      totalAmount: singleDataForPrintQRModal.totalAmount,
      receivingDate: singleDataForPrintQRModal.receivingDate,
      ebn: singleDataForPrintQRModal.ebn,
      // isPrinted: singleDataForPrintQRModal.isPrinted
    };

    let response = await createPrintQR(payload);

    if (response.status) {
      handleClosePrintQRModal();

      setTimeout(() => {
        getAsnDetails(emailId);
      }, 0);

      acceptedPoNotification();
    }
    if (response.status == false) {
      setIsErrorAcceptPo(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  mb={-6}
                >
                  <MDBox>
                    <MDTypography variant="h4" gutterBottom>
                      ASN Details
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox pt={3}>
                  {role === "user" ? (
                    <>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        canSearch={true}
                        entriesPerPage={false}
                        totalRecords={rows.length}
                        showTotalEntries={true}
                        nestedTable={true}
                        nestedTableData={pRows}
                        noEndBorder
                      />
                    </>
                  ) : null}
                </MDBox>

                <Modal
                  open={openPrintQRModal}
                  onClose={handleClosePrintQRModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={styleForQR}>
                    <div
                      onClick={handleClosePrintQRModal}
                      style={{
                        float: "right",
                        textAlign: "right",
                        marginTop: "-15px",
                        marginRight: "-13px",
                        cursor: "pointer",
                      }}
                    >
                      <MDTypography>
                        <Tooltip title="Close">
                          <Icon>cancel</Icon>
                        </Tooltip>
                      </MDTypography>
                    </div>
                    <MDTypography variant="h5" mb={2} ml={2.5}>
                      Print QR Code
                    </MDTypography>
                    <MDCard
                      mt={2}
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        border: "1px solid #4caf50",
                        paddingTop: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{ textAlign: "center" }}
                        onClick={printQRHandler}
                      >
                        <Tooltip title="Print">
                          <Icon fontSize="large" color="success">
                            print
                          </Icon>
                        </Tooltip>
                      </div>
                    </MDCard>
                    <MDCard mt={2}>
                      <Collapse in={isErrorAcceptPo}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAcceptPo(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not remove item from asn !
                        </Alert>
                      </Collapse>
                    </MDCard>
                  </Card>
                </Modal>

                <Modal
                  open={openGrnDetailsModal}
                  onClose={handleCloseGrnDetailsModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={styleForGRN}>
                    <MDTypography
                      variant="h5"
                      mb={2}
                      style={{ textAlign: "center", fontSize: "24px" }}
                    >
                      GRN Details
                    </MDTypography>

                    <hr></hr>
                    <MDCard
                      mt={2}
                      style={{
                        marginLeft: "20px",
                        marginRight: "35px",

                        paddingTop: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <MDTypography>
                        <strong>GRN Number:-</strong>{" "}
                        {singleDataForGrnDetails.grnNumber}
                      </MDTypography>
                      <MDTypography>
                        <strong>GRN DateTime:-</strong>{" "}
                        {singleDataForGrnDetails.grnDateTime}
                      </MDTypography>
                      <MDTypography>
                        <strong>Vendor Name:-</strong>{" "}
                        {singleDataForGrnDetails.vendorName}
                      </MDTypography>
                      <MDTypography>
                        <strong>GRN LineNumber:-</strong>{" "}
                        {singleDataForGrnDetails.grnLineNumber}
                      </MDTypography>
                      <MDTypography>
                        <strong>Item Code:-</strong>{" "}
                        {singleDataForGrnDetails.itemCode}
                      </MDTypography>
                      <MDTypography>
                        <strong>Item Qty:-</strong>{" "}
                        {singleDataForGrnDetails.itemQty}
                      </MDTypography>
                      <MDTypography>
                        <strong>UOM:-</strong> {singleDataForGrnDetails.uom}
                      </MDTypography>
                      <MDTypography>
                        <strong>Mfg DateTime:-</strong>{" "}
                        {singleDataForGrnDetails.mfgDateTime}
                      </MDTypography>
                    </MDCard>
                    <MDCard mt={2}>
                      <Collapse in={isErrorAcceptPo}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorAcceptPo(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not remove item from asn !
                        </Alert>
                      </Collapse>
                    </MDCard>
                  </Card>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />

      <MDSnackbar
        color="error"
        icon="notifications"
        title="Error"
        content="No PO Selected"
        open={show}
        close={toggleSnackbar}
      />
      {!loading ? (
      <Footer />
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    asnDetailsReducer: state.asnDetailsReducer,
    asnItemsDetailsReducer: state.asnItemsDetailsReducer,
    printQRReducer: state.printQRReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getAsnDetails: (emailId) => dispatch(getAsnDetails(emailId)),
    getAsnItemsDetails: (asnCode) => dispatch(getAsnItemsDetails(asnCode)),
    createPrintQR: (payload) => dispatch(createPrintQR(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(AsnDetails);
