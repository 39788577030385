
import {
    GET_PURCHASEDORDERS_REQUEST,
    GET_PURCHASEDORDERS_SUCCESS ,
    GET_PURCHASEDORDERS_FAILURE ,
    GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST,
    GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS,
    GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE,
    PUT_ACCEPTPO_REQUEST,
    PUT_ACCEPTPO_SUCCESS,
    PUT_ACCEPTPO_FAILURE , 
    GET_CARTDATA_SUCCESS,
    GET_PURCHASEDORDERSTATUS_REQUEST,
    GET_PURCHASEDORDERSTATUS_SUCCESS,
    GET_PURCHASEDORDERSTATUS_FAILURE,
    GET_LISTOFITEMS_REQUEST,
    GET_LISTOFITEMS_SUCCESS,
    GET_LISTOFITEMS_FAILURE,
    UPDATE_ASNQTY_REQUEST,
    UPDATE_ASNQTY_SUCCESS,
    UPDATE_ASNQTY_FAILURE
    } from "./PurchasedOrdersTypes"; 



  const initialStateForAsnData = {
    loading: false,
    asnData : {},
    error: "",
  };
  
  const getAsnDataReducer = (state = initialStateForAsnData , action) => {
    switch (action.type) {
      case GET_CARTDATA_SUCCESS:
        return { 
          loading: false,
          asnData  : action.payload,
          error: "",
        };
  
      default:
        return state;
    }
  }; 

 const initialState = {
     loading: false,
     purchasedOrders : {},
     error: "",
 };
 

 const purchasedOrdersReducer = (state = initialState, action) => {
     switch (action.type) {
         case GET_PURCHASEDORDERS_REQUEST:
             return {
                 ...state,
                 loading: true, 
             }; 
         case  GET_PURCHASEDORDERS_SUCCESS:
             return {
                 loading: false,
                 purchasedOrders : action.payload,
                 error: "",
             };
         case GET_PURCHASEDORDERS_FAILURE:
             return {
                 loading: false,
                 purchasedOrders : {},
                 error: action.payload,
             };
         default:
             return state;
     }
 };

 const initialStateForNotAccepted = {
  loading: false,
  notAcceptedpurchasedOrders : {},
  error: "",
};


const notAcceptedPurchasedOrdersReducer = (state = initialStateForNotAccepted, action) => {
  switch (action.type) {
      case GET_NOTACCEPTEDPURCHASEDORDERS_REQUEST:
          return {
              ...state,
              loading: true, 
          }; 
      case  GET_NOTACCEPTEDPURCHASEDORDERS_SUCCESS:
          return {
              loading: false,
              notAcceptedpurchasedOrders : action.payload,
              error: "",
          };
      case GET_NOTACCEPTEDPURCHASEDORDERS_FAILURE:
          return {
              loading: false,
              notAcceptedpurchasedOrders : {},
              error: action.payload,
          };
      default:
          return state;
  }
};

 const initialStateForPoStatus = {
  loading: false,
  purchasedOrderStatus : {},
  error: "",
};

const purchasedOrderStatusReducer = (state = initialStateForPoStatus, action) => {
  switch (action.type) {
      case GET_PURCHASEDORDERSTATUS_REQUEST:
          return {
              ...state,
              loading: true, 
          };
      case  GET_PURCHASEDORDERSTATUS_SUCCESS:
          return {
              loading: false,
              purchasedOrderStatus : action.payload,
              error: "",
          };
      case GET_PURCHASEDORDERSTATUS_FAILURE:
          return {
              loading: false,
              purchasedOrderStatus : {},
              error: action.payload,
          };
      default:
          return state;
  }
};

 const initialStateForAcceptPo = {
    loading: false,
    acceptPo: {},
    error: "",
  };
  
  const acceptPoReducer = (state = initialStateForAcceptPo, action) => {
    switch (action.type) {
      case PUT_ACCEPTPO_REQUEST:
        return {
          ...state,
          loading: true,
          
        };
      case PUT_ACCEPTPO_SUCCESS:
        return {
          loading: true,
          
          acceptPo: action.payload,
          error: "",
        };
      case PUT_ACCEPTPO_FAILURE:
        return {
          loading: false,
          
          acceptPo: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };

  


const initialStateForListOfItems = {
   loading: false,
   listOfItems : {},
   error: "",
};

const listOfItemsReducer = (state = initialStateForListOfItems, action) => {
   switch (action.type) {
       case GET_LISTOFITEMS_REQUEST:
           return {
               ...state,
               loading: true, 
           };
       case  GET_LISTOFITEMS_SUCCESS:
           return {
               loading: false,
               listOfItems : action.payload,
               error: "",
           };
       case GET_LISTOFITEMS_FAILURE:
           return {
               loading: false,
               listOfItems : {},
               error: action.payload,
           };
       default:
           return state;
   }
};

const initialStateForUpdateAsnQty = {
  loading: false,
  asnQty: {},
  error: ""
}

const updateAsnQtyReducer = (state = initialStateForUpdateAsnQty, action) => {
  switch (action.type) {
      case UPDATE_ASNQTY_REQUEST:
          return {
              ...state,
              loading: true
          }
      case UPDATE_ASNQTY_SUCCESS:
          return {
              loading: true,
              asnQty: action.payload,
              error: ''
          }
      case UPDATE_ASNQTY_FAILURE:
          return {
              loading: false,
              asnQty: {},
              error: action.payload
          }
      default:
          return state
  }
}


 
 export {purchasedOrdersReducer, acceptPoReducer , getAsnDataReducer, purchasedOrderStatusReducer,notAcceptedPurchasedOrdersReducer,listOfItemsReducer, updateAsnQtyReducer};
 