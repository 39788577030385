
 import {
    GET_REGISTEREDVENDORS_REQUEST,
    GET_REGISTEREDVENDORS_SUCCESS,
    GET_REGISTEREDVENDORS_FAILURE,
    
  } from "./RegisteredVendorsTypes";

 const initialState = {
     loading: false,
     registeredVendors: {},
     error: "",
 };
 
 const registeredVendorsReducer = (state = initialState, action) => {
     switch (action.type) {
         case GET_REGISTEREDVENDORS_REQUEST:
             return {
                 ...state,
                 loading: true,
             };
         case GET_REGISTEREDVENDORS_SUCCESS:
             return {
                 loading: false,
                 registeredVendors: action.payload,
                 error: "",
             }; 
         case GET_REGISTEREDVENDORS_FAILURE:
             return {
                 loading: false,
                 registeredVendors: {},
                 error: action.payload,
             };
         default:
             return state;
     }
 };
 
 export {registeredVendorsReducer};
 