import {
  GET_ASNVIEWDETAILS_REQUEST,
  GET_ASNVIEWDETAILS_SUCCESS,
  GET_ASNVIEWDETAILS_FAILURE,
  PUT_ADDTOASN_REQUEST,
  PUT_ADDTOASN_SUCCESS,
  PUT_ADDTOASN_FAILURE,
  POST_CREATEASN_REQUEST,
  POST_CREATEASN_FAILURE,
  POST_CREATEASN_SUCCESS,
} from "./AsnTypes";

const initialState = {
  loading: false,
  asnViewDetails: {},
  error: "",
};

const asnViewDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASNVIEWDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ASNVIEWDETAILS_SUCCESS:
      return {
        loading: false,
        asnViewDetails: action.payload,
        error: "",
      };
    case GET_ASNVIEWDETAILS_FAILURE:
      return {
        loading: false,
        asnViewDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForaddToAsn = {
  loading: false,
  addToAsn: {},
  error: "",
};

const addToAsnReducer = (state = initialStateForaddToAsn, action) => {
  switch (action.type) {
    case PUT_ADDTOASN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_ADDTOASN_SUCCESS:
      return {
        loading: true,

        addToAsn: action.payload,
        error: "",
      };
    case PUT_ADDTOASN_FAILURE:
      return {
        loading: false,

        addToAsn: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreateAsn = {
  loading: false,
  createAsn: {},
  error: "",
};

const createAsnReducer = (state = initialStateForCreateAsn, action) => {
  switch (action.type) {
    case POST_CREATEASN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CREATEASN_SUCCESS:
      return {
        loading: true,

        addToAsn: action.payload,
        error: "",
      };
    case POST_CREATEASN_FAILURE:
      return {
        loading: false,

        addToAsn: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { asnViewDetailsReducer, addToAsnReducer, createAsnReducer };
