
import {
  GET_ASNDETAILS_REQUEST,
  GET_ASNDETAILS_SUCCESS ,
  GET_ASNDETAILS_FAILURE,
  GET_ASNITEMSDETAILS_REQUEST,
  GET_ASNITEMSDETAILS_SUCCESS,
  GET_ASNITEMSDETAILS_FAILURE
  } from "./AsnDetailsTypes"; 





 const initialState = {
     loading: false,
     asnDetails : {},
     error: "",
 };
 

 const asnDetailsReducer = (state = initialState, action) => {
     switch (action.type) {
         case GET_ASNDETAILS_REQUEST:
             return {
                 ...state,
                 loading: true, 
             }; 
         case  GET_ASNDETAILS_SUCCESS:
             return {
                 loading: false,
                 asnDetails : action.payload,
                 error: "",
             };
         case GET_ASNDETAILS_FAILURE:
             return {
                 loading: false,
                 asnDetails : {},
                 error: action.payload,
             };
         default:
             return state;
     }
 };

 const initialStateForAsnItems = {
  loading: false,
  asnItemsDetails : {},
  error: "",
};


const asnItemsDetailsReducer = (state = initialStateForAsnItems, action) => {
  switch (action.type) {
      case GET_ASNITEMSDETAILS_REQUEST:
          return {
              ...state,
              loading: true, 
          }; 
      case  GET_ASNITEMSDETAILS_SUCCESS:
          return {
              loading: false,
              asnItemsDetails : action.payload,
              error: "",
          };
      case GET_ASNITEMSDETAILS_FAILURE:
          return {
              loading: false,
              asnItemsDetails : {},
              error: action.payload,
          };
      default:
          return state;
  }
};

 
 export {asnDetailsReducer, asnItemsDetailsReducer};
 