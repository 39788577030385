import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik & Yup
import { Formik, Form } from "formik";

// @mui material components
import { Card, CardContent, Grid } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "./../../components/MDInput/index";

// Dashboard layout components
import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";

// Functions from store
import { createVendorDetails } from "../../store";

// Router Components
import { useNavigate } from "react-router-dom";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const FormPreview = ({
  createVendorDetails,
  vendorDetailsReducer,
  getPreviewReducer,
}) => {
  const cookies = new Cookies();

  const jwt = cookies.get("vendor-portal-jwtToken");

  const [open, setOpen] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [msg, setMsg] = useState("");

  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState("");

  const [venderData, setVenderData] = useState({});

  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(-1);
  };

  const handleChange = (id) => () => {
    setExpanded((pre) => {
      return {
        ...pre,
        [id]: !expanded[id],
      };
    });
  };

  const [expanded, setExpanded] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
  });
  useEffect(() => {
    let formdata = getPreviewReducer.preview ? getPreviewReducer.preview : {};
    setVenderData(formdata);
  }, [getPreviewReducer]);

  let token = cookies.get("vendor-portal-jwtToken");

  const submitHandler = async (values) => {
    let data = new FormData();
    for (let value in values) {
      data.append(value, values[value]);
    }

    let response = await createVendorDetails(data, token);

    if (response.status) {
      setFormSubmitted(true);
      setOpen(true);
      setMsg("Form is submitting... Please wait");
      setTimeout(() => {
        setMsg("Form is submitted.");
      }, 5000);

      cookies.remove("vendor-portal-jwtToken");
    }
  };

  const modalCloseHandler = () => {
    setOpen(false);
    setFormSubmittedSuccessfully("Form has been submitted.");
  };

  return (
    <BasicLayout>
      <Card>
        {!formSubmitted && (
          <CardContent align="center">
            <Formik
              initialValues={getPreviewReducer?.preview}
              onSubmit={submitHandler}
            >
              {(formik) => (
                <Form>
                  <div>
                    <MDBox mt={20}>
                      <Container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlign: "center",
                        }}
                      >
                        <MDTypography
                          variant="h3"
                          gutterBottom
                          color="black"
                          textAlign="center"
                          width="100%"
                          marginBottom="20px"
                        >
                          Review the details before submitting the form
                        </MDTypography>
                      </Container>
                    </MDBox>
                    <Accordion
                      style={{ boxShadow: "none", width: "100%" }}
                      expanded={expanded.panel1 === true}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        style={{
                          backgroundColor: "#d2042d",
                          borderBottom: "2px solid white",

                          width: "100%",

                          maxHeight: "50px",
                          minHeight: "50px",
                        }}
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                        // aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          style={{ color: "white", margin: "0px" }}
                          mt={5}
                          variant="h5"
                          fontWeight="bold"
                          color="inherit"
                        >
                          Business Partner Details
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          backgroundColor: "white",
                          padding: "15px",
                        }}
                      >
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Line of Business
                              </MDTypography>
                              <MDInput
                                disabled
                                fullWidth
                                name="lineOfBusiness"
                                value={venderData.lineOfBusiness}
                                style={{ backgroundColor: "white" }}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Currency
                              </MDTypography>
                              <MDInput
                                fullWidth
                                disabled
                                name="currency"
                                value={venderData.currency}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Supplier Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="supplierName"
                                fullWidth
                                autoComplete="off"
                                value={venderData.supplierName}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Building No/Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="buildingNoName"
                                fullWidth
                                autoComplete="off"
                                value={venderData.buildingNoName}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Floor
                              </MDTypography>
                              <MDInput
                                name="floor"
                                fullWidth
                                disabled
                                value={venderData.floor}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Street
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="street"
                                fullWidth
                                autoComplete="off"
                                value={venderData.street}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6">Country</MDTypography>
                              <MDInput
                                fullWidth
                                disabled
                                name="country"
                                value={venderData.country}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6">State</MDTypography>
                              <MDInput
                                fullWidth
                                disabled
                                name="state"
                                value={venderData.state}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                City
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="city"
                                disabled
                                fullWidth
                                autoComplete="off"
                                value={venderData.city}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Pincode
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="pinCode"
                                fullWidth
                                value={venderData.pinCode}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Contact Person
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="contactPerson"
                                fullWidth
                                value={venderData.contactPerson}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Contact No
                              </MDTypography>{" "}
                              <MDInput
                                type="text"
                                disabled
                                name="contactNo"
                                fullWidth
                                autoComplete="off"
                                value={
                                  venderData.countryCode +
                                  "-" +
                                  venderData.contactNo
                                }
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Email
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="email"
                                fullWidth
                                value={venderData.email}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Industrial Type
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="industrialType"
                                fullWidth
                                value={venderData.industrialType}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      style={{ boxShadow: "none", width: "100%" }}
                      expanded={expanded.panel2 === true}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        style={{
                          backgroundColor: "#d2042d",
                          borderBottom: "2px solid white",
                          width: "100%",

                          maxHeight: "50px",
                          minHeight: "50px",
                        }}
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                        // aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          style={{ color: "white", margin: "0px" }}
                          // mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Statutory Information
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          backgroundColor: "white",
                          padding: "15px",
                        }}
                      >
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Pan
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="pan"
                                disabled
                                fullWidth
                                value={venderData.pan}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Gst No
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="gstNo"
                                fullWidth
                                autoComplete="off"
                                value={venderData.gstNo}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Whether SEZ/Non-SEZ
                              </MDTypography>
                              <MDInput
                                fullWidth
                                disabled
                                name="valuesSezNonsez"
                                value={venderData.sezNonsez}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                RCM Applicable
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="rcmApplicable"
                                fullWidth
                                autoComplete="off"
                                value={venderData.rcmApplicable}
                              />
                            </MDBox>
                          </Grid>
                          {venderData.lutNo && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Lut No
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  disabled
                                  name="lutNo"
                                  fullWidth
                                  autoComplete="off"
                                  value={venderData.lutNo}
                                />
                              </MDBox>
                            </Grid>
                          )}
                          {venderData.lutDate && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  LUT Date
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  disabled
                                  name="lutDate"
                                  fullWidth
                                  autoComplete="off"
                                  value={venderData.lutDate}
                                />
                              </MDBox>
                            </Grid>
                          )}

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Payment Terms
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="paymentTerms"
                                fullWidth
                                disabled
                                autoComplete="off"
                                value={venderData.paymentTerms}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                CIN Number
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="cinNumber"
                                fullWidth
                                disabled
                                autoComplete="off"
                                value={venderData.cinNumber}
                              />
                            </MDBox>
                          </Grid>

                          {venderData.pfAccountNumber && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  PF Account Number
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  disabled
                                  name="pfAccountNumber"
                                  fullWidth
                                  autoComplete="off"
                                  value={venderData.pfAccountNumber}
                                />
                              </MDBox>
                            </Grid>
                          )}
                          {venderData.esiAccountNumber && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  ESI AccountNumber
                                </MDTypography>
                                <MDInput
                                  type="text"
                                  disabled
                                  name="esiAccountNumber"
                                  fullWidth
                                  autoComplete="off"
                                  value={venderData.esiAccountNumber}
                                />
                              </MDBox>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      style={{ boxShadow: "none", width: "100%" }}
                      expanded={expanded.panel3 === true}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        style={{
                          backgroundColor: "#d2042d",
                          borderBottom: "2px solid white",
                          width: "100%",

                          maxHeight: "50px",
                          minHeight: "50px",
                        }}
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                        // aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          style={{ color: "white", marginTop: "0px" }}
                          // mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Bank Details
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          backgroundColor: "white",
                          padding: "15px",
                        }}
                      >
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Beneficiary Bank And Address
                              </MDTypography>
                              <MDInput
                                name="beneficiaryBankAndAddress"
                                fullWidth
                                disabled
                                autoComplete="off"
                                value={venderData.beneficiaryBankAndAddress}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Account Number
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="accountNumber"
                                disabled
                                fullWidth
                                value={venderData.accountNumber}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Account Type
                              </MDTypography>
                              <MDInput
                                fullWidth
                                // name="lineOfBusiness"
                                value={venderData.lineOfBusiness}
                                disabled
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Account Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="accountName"
                                fullWidth
                                autoComplete="off"
                                value={venderData.accountName}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                IFSC Code
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="ifscCode"
                                fullWidth
                                autoComplete="off"
                                value={venderData.ifscCode}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Swift Code/IBAN
                              </MDTypography>
                              <MDInput
                                name="swiftCodeIban"
                                disabled
                                fullWidth
                                autoComplete="off"
                                value={venderData.swiftCodeIban}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Swift Code Of Intermediary/RoutingBank
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="swiftCodeOfIntermediaryRoutingBank"
                                fullWidth
                                autoComplete="off"
                                value={
                                  venderData.swiftCodeOfIntermediaryRoutingBank
                                }
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Intermediary/Routing Bank
                              </MDTypography>
                              <MDInput
                                type="text"
                                disabled
                                name="intermediaryRoutingBank"
                                fullWidth
                                value={venderData.intermediaryRoutingBank}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      style={{ boxShadow: "none", width: "100%" }}
                      expanded={expanded.panel4 === true}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        style={{
                          backgroundColor: "#d2042d",
                          borderBottom: "2px solid white",
                          width: "100%",
                          marginRight: "35px",
                          maxHeight: "50px",
                          minHeight: "50px",
                        }}
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                        // aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography
                          style={{ color: "white", margin: "0px" }}
                          mt={5}
                          variant="h5"
                          fontWeight="medium"
                          color="inherit"
                        >
                          Attached Documents
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          backgroundColor: "white",
                          padding: "15px",
                        }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Incorporation Certificate
                              </MDTypography>
                              <MDInput
                                style={{ borders: "none" }}
                                className="choose_file"
                                disabled
                                type="text"
                                name="msmeCertificate"
                                // type="file"
                                fullWidth
                                accept=".pdf"
                                autoComplete="off"
                                value={
                                  venderData.incorporationCertificate
                                    ? venderData.incorporationCertificate.name
                                    : " "
                                }
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Pan Card
                              </MDTypography>
                              <MDInput
                                style={{ borders: "none" }}
                                className="choose_file"
                                type="text"
                                name="panCard"
                                accept=".pdf"
                                fullWidth
                                disabled
                                autoComplete="off"
                                value={
                                  venderData.panCard
                                    ? venderData.panCard.name
                                    : ""
                                }
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Cancelled Cheque
                              </MDTypography>
                              <MDInput
                                style={{ borders: "none" }}
                                className="choose_file"
                                type="text"
                                // type="file"
                                accept=".pdf"
                                fullWidth
                                autoComplete="off"
                                disabled
                                value={
                                  venderData.cancelledCheque
                                    ? venderData.cancelledCheque.name
                                    : " "
                                }
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Valid Lut
                              </MDTypography>
                              <MDInput
                                style={{ borders: "none" }}
                                className="choose_file"
                                type="text"
                                disabled
                                name="validLut"
                                accept=".pdf"
                                fullWidth
                                value={
                                  venderData.validLut
                                    ? venderData.validLut.name
                                    : ""
                                }
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xl={3} lg={3} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                GST Registration Certificate
                              </MDTypography>
                              <MDInput
                                style={{ borders: "none" }}
                                className="choose_file"
                                type="text"
                                disabled
                                name="validLut"
                                accept=".pdf"
                                fullWidth
                                value={
                                  venderData.gstRegistrationCertificate
                                    ? venderData.gstRegistrationCertificate.name
                                    : ""
                                }
                              />
                            </MDBox>
                          </Grid>

                          {venderData.sezApproval && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  SEZ Approval
                                </MDTypography>
                                <MDInput
                                  style={{ borders: "none" }}
                                  className="choose_file"
                                  disabled
                                  type="text"
                                  name="msmeCertificate"
                                  accept=".pdf"
                                  fullWidth
                                  autoComplete="off"
                                  value={
                                    venderData.sezApproval
                                      ? venderData.sezApproval.name
                                      : venderData.sezApproval
                                  }
                                />
                              </MDBox>
                            </Grid>
                          )}

                          {venderData.pfCertificate && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  PF Certificate
                                </MDTypography>
                                <MDInput
                                  style={{ borders: "none" }}
                                  className="choose_file"
                                  type="text"
                                  disabled
                                  name="msmeCertificate"
                                  accept=".pdf"
                                  fullWidth
                                  autoComplete="off"
                                  value={
                                    venderData.pfCertificate
                                      ? venderData.pfCertificate.name
                                      : venderData.pfCertificate
                                  }
                                />
                              </MDBox>
                            </Grid>
                          )}

                          {venderData.esiCertificate && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  ESI Certificate
                                </MDTypography>
                                <MDInput
                                  style={{ borders: "none" }}
                                  className="choose_file"
                                  disabled
                                  type="text"
                                  name="msmeCertificate"
                                  accept=".pdf"
                                  fullWidth
                                  autoComplete="off"
                                  value={
                                    venderData.esiCertificate
                                      ? venderData.esiCertificate.name
                                      : venderData.esiCertificate
                                  }
                                />
                              </MDBox>
                            </Grid>
                          )}

                          {venderData.tdsLowerDeductionCertificate && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  TDS Lower Deduction Certificate
                                </MDTypography>
                                <MDInput
                                  style={{ borders: "none" }}
                                  className="choose_file"
                                  type="text"
                                  disabled
                                  name="msmeCertificate"
                                  accept=".pdf"
                                  fullWidth
                                  autoComplete="off"
                                  value={
                                    venderData.tdsLowerDeductionCertificate
                                      ? venderData.tdsLowerDeductionCertificate
                                          .name
                                      : venderData.tdsLowerDeductionCertificate
                                  }
                                />
                              </MDBox>
                            </Grid>
                          )}

                          {venderData.msmeCertificate && (
                            <Grid item xl={3} lg={3} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Msme Certificate
                                </MDTypography>
                                <MDInput
                                  style={{ borders: "none" }}
                                  className="choose_file"
                                  disabled
                                  type="text"
                                  name="msmeCertificate"
                                  accept=".pdf"
                                  fullWidth
                                  autoComplete="off"
                                  value={
                                    venderData.msmeCertificate
                                      ? venderData.msmeCertificate.name
                                      : venderData.msmeCertificate
                                  }
                                />
                              </MDBox>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <MDButton
                      type="button"
                      color="info"
                      variant="contained"
                      onClick={clickHandler}
                      style={{ marginTop: "50px" }}
                    >
                      Edit
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: "15px", marginTop: "50px" }}
                      type="submit"
                      color="success"
                      variant="contained"
                    >
                      Submit
                    </MDButton>
                  </div>
                </Form>
              )}
            </Formik>
          </CardContent>
        )}

        {formSubmitted && (
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogContent>
              <DialogTitle
                style={{
                  fontSize: 30,
                  textAlign: "center",
                  marginTop: "35px",
                }}
              >
                {msg}
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <MDButton
                variant="contained"
                color="secondary"
                onClick={modalCloseHandler}
              >
                Close
              </MDButton>
            </DialogActions>
          </Dialog>
        )}
      </Card>
      {formSubmitted && (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            background: "transparent",
            width: "700px",
            height: "200px",
            marginTop: "200px",
            borderRadius: "10px",
          }}
        >
          <MDTypography
            variant="h1"
            gutterBottom
            color="black"
            textAlign="center"
            alignItems="center"
            width="100%"
          >
            {formSubmittedSuccessfully}
          </MDTypography>
        </Container>
      )}
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    vendorDetailsReducer: state.vendorDetailsReducer,
    getPreviewReducer: state.getPreviewReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createVendorDetails: (payload, token) =>
      dispatch(createVendorDetails(payload, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormPreview);
