import {
    GET_ASNDETAILS_REQUEST,
    GET_ASNDETAILS_SUCCESS ,
    GET_ASNDETAILS_FAILURE ,
    GET_ASNITEMSDETAILS_REQUEST,
    GET_ASNITEMSDETAILS_SUCCESS,
    GET_ASNITEMSDETAILS_FAILURE
      } from "./AsnDetailsTypes";
  import Config from "../../config/index";
  
import axios from 'axios';

const URL = Config.baseUrl;

let auth3Url = Config.auth3Url;
  

  
  const getAsnDetailsRequest = () => {
    return {
      type: GET_ASNDETAILS_REQUEST,
    };
  }; 
  
  
  const getAsnDetailsSuccess = (payload) => {
    return {
      type: GET_ASNDETAILS_SUCCESS,
      payload: payload,
    };
  };
  
  const getAsnDetailsFailure = (error) => {
    return {
      type: GET_ASNDETAILS_FAILURE,
      payload: error,
    };
  };
  
  const getAsnDetails = (emailId) => {
    return (dispatch) => {
      dispatch(getAsnDetailsRequest());
      let url = `${auth3Url}/vendor/get_asn?email=${emailId}`;
      axios
        .get(url)
        .then((response) => {
          dispatch( getAsnDetailsSuccess(response.data));
        })
        .catch((err) => {
          dispatch(getAsnDetailsFailure(err.message));
        });
    };
  };

  const getAsnItemsDetailsRequest = () => {
    return {
      type: GET_ASNITEMSDETAILS_REQUEST,
    };
  }; 
  
  
  const getAsnItemsDetailsSuccess = (payload) => {
    return {
      type: GET_ASNITEMSDETAILS_SUCCESS,
      payload: payload,
    };
  };
  
  const getAsnItemsDetailsFailure = (error) => {
    return {
      type: GET_ASNITEMSDETAILS_FAILURE,
      payload: error,
    };
  };
  
  const getAsnItemsDetails = (asnCode) => {
    return (dispatch) => {
      dispatch(getAsnItemsDetailsRequest());
      let url = `${auth3Url}/vendor/get_items_of_asn?asnCode=${asnCode}`;
      axios
        .get(url)
        .then((response) => {
          dispatch( getAsnItemsDetailsSuccess(response.data));
        })
        .catch((err) => {
          dispatch(getAsnItemsDetailsFailure(err.message));
        });
    };
  };



  
 
  export { getAsnDetails, getAsnItemsDetails};
  