/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// @mui material components;
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard base styles
import typography from "assets/theme/base/typography";

// Functions from store
import { createVendorRegistrationLink } from "../../store/VendorDetails/VendorDetailsActions";

const style = {
  position: "absolute",
  // top: "35%",
  left: "20%",
  // transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  marginTop: "110px",
  boxShadow: 24,
  p: 4,
};
const VendorDetails = ({
  createVendorRegistrationLink,
  vendorRegistrationLinkReducer,
}) => {

  const { size } = typography;

  const initialValues = {
    vendorName: "",
    contactPerson: "",
    email: "",
  };

  const validationSchema = Yup.object({
    vendorName: Yup.string().required("Enter the name!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-Mail!"),
    contactPerson: Yup.string().required("Enter the contact person !"),
  });

  const [isErrorCreateUser, setIsErrorCreateUser] = useState(false);
  const [isSuccessCreateUser, setIsSuccessCreateUser] = useState(false);
  const [isErrorForEmail, setIsErrorForEmail] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    const res = await createVendorRegistrationLink(values);

    if (res.status) {
      setIsSuccessCreateUser(true);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreateUser(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 500);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card style={{ width: 500, marginTop: "50px" }}>
            <MDBox mt={2}>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                style={{ textAlign: "center" }}
              >
                New Vendor Request
              </MDTypography>
            </MDBox>

            <MDBox pt={1} pb={3} px={3}>
              <MDBox
                component="form"
                role="form"
                onSubmit={formik.handleSubmit}
              >
                <MDBox mb={2}>
                  {/* <MDTypography variant="h6" color="inherit">
                        vendorName 
                        </MDTypography> */}
                  <MDInput
                    label="Vendor Name"
                    type="text"
                    name="vendorName"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.vendorName}
                    error={
                      formik.touched.vendorName &&
                      formik.errors.vendorName &&
                      true
                    }
                  />
                  {formik.touched.vendorName && formik.errors.vendorName && (
                    <TextError msg={formik.errors.vendorName} />
                  )}
                </MDBox>

                <MDBox mb={2}>
                  {/* <MDTypography variant="h6" color="inherit">
                          ContactPerson 
                        </MDTypography> */}
                  <MDInput
                    label="Contact Person "
                    type="text"
                    name="contactPerson"
                    fullWidth
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactPerson}
                    error={
                      formik.touched.contactPerson &&
                      formik.errors.contactPerson &&
                      true
                    }
                  />
                  {formik.touched.contactPerson &&
                    formik.errors.contactPerson && (
                      <TextError msg={formik.errors.contactPerson} />
                    )}
                </MDBox>

                <MDBox mb={2}>
                  {/* <MDTypography variant="h6" color="inherit">
                          E-Mail
                        </MDTypography> */}
                  <MDInput
                    label="E-Mail"
                    type="email"
                    name="email"
                    fullWidth
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email && true}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <TextError msg={formik.errors.email} />
                  )}
                </MDBox>

                <MDBox>
                  <Collapse in={isErrorCreateUser}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorCreateUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Vendor already exist!!
                    </Alert>
                  </Collapse>
                  <Collapse in={isSuccessCreateUser}>
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsSuccessCreateUser(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      New Vendor Request Submitted Successfully
                    </Alert>
                  </Collapse>
                  <Collapse in={isErrorForEmail}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setIsErrorForEmail(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Please check your email domain!
                    </Alert>
                  </Collapse>
                </MDBox>
                <MDBox mt={2}>
                  <MDButton
                    // fullWidth
                    style={{ backgroundColor: "red", float: "left" }}
                    color="info"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </div>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!loading ? (
      <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      position="fixed"
      bottom={1}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        <p className=" text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p>
      </MDBox>
      {/* <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox> */}
    </MDBox>
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    vendorRegistrationLinkReducer: state.vendorRegistrationLinkReducer,
    drawerState: state.drawerState,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    createVendorRegistrationLink: (payload) =>
      dispatch(createVendorRegistrationLink(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(VendorDetails);
