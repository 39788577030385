import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Country, State } from "country-state-city";

// React-Select component and styling
import Select from "react-select";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Utils component
import TextError from "utils/TextError";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Selects from "@mui/material/Select";
import "../../utils/input.css"

import { useNavigate } from "react-router-dom";

// React-DatePicker component and styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


// Store
import { getVendorByVendorId, getPreview } from "../../store";

const red = {
  // 50: "#F0F7FF",
  // 100: "#FFCCCB",
  100: "#ffcdd2",
  200: "#ff0000",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${red[100]};
    color: red;
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${red[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${red[200]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 400px;
  background-color: ${red[200]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  // box-shadow: 0px 4px 8px ${theme.palette.mode === "dark" ? red[100] : red[100]
    };
  `
);

const RejectedVendorForm = ({
  getVendorByVendorId,
  getVendorByVendorIdReducer,
  getVendorIdReducer,
  getPreview,
  getPreviewReducer,
}) => {
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  //  const authCtx = useContext(AuthContext);

  const [vendorData, setVendorData] = useState({});
  const [vendorId, setVendorId] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [dataChange, setDataChange] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setVendorId(getVendorIdReducer.vendorId);
  }, [getVendorIdReducer]);

  useEffect(() => {
    getVendorByVendorId(vendorId);
  }, [vendorId]);

  useEffect(() => {
    // if(!dataChange){
    setVendorData(getVendorByVendorIdReducer.getVendor);
    // }
  }, [getVendorByVendorIdReducer]);

  useEffect(() => {
    setPreviewData(getPreviewReducer?.preview);
  }, [getPreviewReducer]);

  const [industryType, setIndustryType] = React.useState([]);

  const handleChangeForIndustrial = (event) => {
    const {
      target: { value },
    } = event;
    setIndustryType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const industryTypeNames = [
    // "Both",
    "Dealer",
    "Manufacturer",
    "Service",
    "Sub-Contractor",
    "Trader",
  ];

  const valuesAccountType = [
    { label: "Current Account", value: "Current Account" },
    { label: "Savings Account", value: "Savings Account" },
  ];

  const [selectedaccountType, setSelectedaccountType] = useState("");

  const handleaccountType = (selectedaccountType) => {
    setSelectedaccountType(selectedaccountType);
  };

  const valuesIndustrialType = [
    // { key: "All", value: "Both" },
    { key: "Dealer", value: "Dealer" },
    { key: "Manufacturer", value: "Manufacturer" },
    { key: "Service", value: "Service" },
    { key: "Sub-Contractor", value: "Sub-Contractor" },
    { key: "Trader", value: "Trader" },
  ];
  // industry type ok

  const [selectedindustrialType, setSelectedindustrialType] = useState("");

  const handleindustrialType = (selectedindustrialType) => {
    setSelectedindustrialType(selectedindustrialType);
  };

  const valuesLineOfBusiness = [
    { label: "Direct Material", value: "Direct Material" },
    { label: "Indirect Material", value: "Indirect Material" },
  ];

  const [selectedlineOfBusiness, setSelectedlineOfBusiness] = useState({});

  const handlelineOfBusiness = (selectedlineOfBusiness) => {
    setSelectedlineOfBusiness(selectedlineOfBusiness);
  };

  //   Whether SEZ/Non-SEZ
  const valuesSezNonsez = [
    { label: "SEZ", value: "SEZ" },
    { label: "Non-SEZ", value: "Non-SEZ" },
  ];
  const [selectedvaluesSezNonsez, setSelectedvaluesSezNonsez] = useState("");

  const handlevaluesSezNonsez = (selectedvaluesSezNonsez) => {
    setSelectedvaluesSezNonsez(selectedvaluesSezNonsez);
  };

  // const currencyTypes = [
  //   { label: "2 Days", value: "2_days" },
  //   { label: "1 Week", value: "1_week" },
  //   { label: "1 Month", value: "1_month" },
  //   { label: "Never expire", value: "never_expire" },
  // ];
  const listOfCountries = Country.getAllCountries();
  const countryDetails = [];

  // const countryinfo =
  listOfCountries.map((country) =>
    countryDetails.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.name,
      value: country.name,
    })
  );

  const countryDetails2 = [];

  listOfCountries.map((country) =>
    countryDetails2.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.currency,
      value: country.currency,
    })
  );

  //  for country
  const listOfCountriesName = Country.getAllCountries();
  const countryDetailsName = [];

  // const countryinfo =
  listOfCountriesName.map((country) =>
    countryDetailsName.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.name,
      value: country.name,
    })
  );

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const [selectedValidity, setSelectedValidity] = useState("");

  const handleValidityChange = (selectedValidity) => {
    setSelectedValidity(selectedValidity);
  };

  //  FOR STATE
  const [selectedState, setSelectedState] = useState("");

  const handleStateChange = (selectedState) => {
    setSelectedState(selectedState);
  };

  const [selectedCountryName, setSelectedCountryName] = useState("");

  const handleCountryName = (selectedCountryName) => {
    setSelectedCountryName(selectedCountryName);
  };

  // const listOfStateName = State.getStatesOfCountry();
  const countryStateName = [];

  // csc.getStatesOfCountry(countryId);

  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));

  const [startDate, setStartDate] = useState("");

  // let isoStartDate

  // useEffect(() => {
  //  startDate.toString();

  // }, [vendorData]);

  // const countryinfo =
  // State.getStatesOfCountry(
  //   countryDetails.filter(
  //     (country) => country.name === selectedCountryName.value
  //   )[0]?.countryCode
  // ).map((country) =>
  //   countryStateName.push({
  //     name: country.name,
  //     currency: country.currency,
  //     phoneCode: country.phonecode,
  //     countryCode: country.isoCode,
  //     label: country.name,
  //     value: country.name,
  //   })
  // );

  const valuesCurrency = countryDetails2.filter(
    (country) => country.currency !== ""
  );

  const [selectedCurrency, setSelectedCurrency] = useState("");

  const handleCurrency = (selectedlineOfBusiness) => {
    setSelectedCurrency(selectedlineOfBusiness);
  };

  useEffect(() => {
    if (previewData.lineOfBusiness) {
      setSelectedlineOfBusiness({
        label: previewData.lineOfBusiness,
        value: previewData.lineOfBusiness,
      });
    } else {
      setSelectedlineOfBusiness({
        label: vendorData.lineOfBusiness,
        value: vendorData.lineOfBusiness,
      });
    }

    if (previewData.currency) {
      setSelectedCurrency({
        label: previewData.currency,
        value: previewData.currency,
      });
    } else {
      setSelectedCurrency({
        label: vendorData.currency,
        value: vendorData.currency,
      });
    }

    if (previewData.country) {
      setSelectedCountry({
        label: previewData.country,
        value: previewData.country,
      });
    } else {
      setSelectedCountry({
        label: vendorData?.address?.country,
        value: vendorData?.address?.country,
      });
    }

    if (previewData.state) {
      setSelectedState({
        label: previewData.state,
        value: previewData.state,
      });
    } else {
      setSelectedState({
        label: vendorData?.address?.state,
        value: vendorData?.address?.state,
      });
    }

    if (previewData.sezNonsez) {
      setSelectedvaluesSezNonsez({
        label: previewData.sezNonsez,
        value: previewData.sezNonsez,
      });
    } else {
      setSelectedvaluesSezNonsez({
        label: vendorData.sezNonsez,
        value: vendorData.sezNonsez,
      });
    }

    if (previewData.accountType) {
      setSelectedaccountType({
        label: previewData.accountType,
        value: previewData.accountType,
      });
    } else {
      setSelectedaccountType({
        label: vendorData?.bankDetails?.accountType,
        value: vendorData?.bankDetails?.accountType,
      });
    }


    if (previewData) {
      setStartDate(previewData.lutDate);
    }
    else {
      setStartDate(vendorData.lutDate);
    }

    if (previewData.industrialType) {
      setIndustryType(previewData.industrialType);
    }
    if (!previewData.industrialType && vendorData.industrialType) {
      setIndustryType(vendorData.industrialType);
    }
  }, [vendorData]);


  const initialValues = {
    lineOfBusiness: previewData.lineOfBusiness
      ? previewData.lineOfBusiness
      : vendorData?.lineOfBusiness,
    currency: previewData.currency
      ? previewData.currency
      : vendorData?.currency,
    supplierName: previewData.supplierName
      ? previewData.supplierName
      : vendorData?.supplierName,
    buildingNoName: previewData.buildingNoName
      ? previewData.buildingNoName
      : vendorData?.address?.buildingNoName,
    floor: previewData.floor ? previewData.floor : vendorData?.address?.floor,
    street: previewData.street
      ? previewData.street
      : vendorData?.address?.street,
    country: previewData.country
      ? previewData.country
      : vendorData?.address?.country,
    state: previewData.state ? previewData.state : vendorData?.address?.state,
    city: previewData.city ? previewData.city : vendorData?.address?.city,
    pinCode: previewData.pinCode
      ? previewData.pinCode
      : vendorData?.address?.pinCode,
    contactPerson: previewData.contactPerson
      ? previewData.contactPerson
      : vendorData?.contactPerson,
    countryCode: previewData.countryCode
      ? previewData.countryCode
      : vendorData?.contactDetails?.countryCode,
    contactNo: previewData.contactNo
      ? previewData.contactNo
      : vendorData?.contactDetails?.contactNo,
    industrialType: previewData.industrialType
      ? previewData?.industrialType
      : vendorData?.industrialType || [""],
    // industrialType: vendorData.industrialType
    //   ? vendorData.industrialType
    //   : [""],
    email: previewData.email ? previewData.email : vendorData?.email,
    pan: previewData.pan ? previewData.pan : vendorData?.pan,
    gstNo: previewData.gstNo ? previewData.gstNo : vendorData?.gstNo,
    sezNonsez: previewData.sezNonsez
      ? previewData.sezNonsez
      : vendorData?.sezNonsez,
    rcmApplicable: previewData.rcmApplicable
      ? previewData.rcmApplicable
      : vendorData?.rcmApplicable,
    lutNo: previewData.lutNo ? previewData.lutNo : vendorData?.lutNo,
    lutDate: previewData.lutDate ? previewData.lutDate : vendorData?.lutDate,
    paymentTerms: previewData.paymentTerms
      ? previewData.paymentTerms
      : vendorData?.paymentTerms,
    cinNumber: previewData.cinNumber
      ? previewData.cinNumber
      : vendorData?.cinNumber,
    msmedAct: previewData.msmedAct
      ? previewData.msmedAct
      : vendorData?.msmedAct,
    tdsLowerDeduction: previewData.tdsLowerDeduction
      ? previewData.tdsLowerDeduction
      : vendorData?.tdsLowerDeduction,
    categoryOfSupply: previewData.categoryOfSupply
      ? previewData.categoryOfSupply
      : vendorData?.categoryOfSupply || [],
    pfAccountNumber: previewData.pfAccountNumber
      ? previewData.pfAccountNumber
      : vendorData?.pfAccountNumber,
    esiAccountNumber: previewData.esiAccountNumber
      ? previewData.esiAccountNumber
      : vendorData?.esiAccountNumber,
    beneficiaryBankAndAddress: previewData.beneficiaryBankAndAddress
      ? previewData.beneficiaryBankAndAddress
      : vendorData?.bankDetails?.beneficiaryBankAndAddress,
    accountNumber: previewData.accountNumber
      ? previewData.accountNumber
      : vendorData?.bankDetails?.accountNumber,
    accountType: previewData.accountType
      ? previewData.accountType
      : vendorData?.bankDetails?.accountType,
    accountName: previewData.accountName
      ? previewData.accountName
      : vendorData?.bankDetails?.accountName,
    ifscCode: previewData.ifscCode
      ? previewData.ifscCode
      : vendorData?.bankDetails?.ifscCode,
    swiftCodeIban: previewData.swiftCodeIban
      ? previewData.swiftCodeIban
      : vendorData?.bankDetails?.swiftCodeIban,
    intermediaryRoutingBank: previewData.intermediaryRoutingBank
      ? previewData.intermediaryRoutingBank
      : vendorData?.bankDetails?.intermediaryRoutingBank,
    swiftCodeOfIntermediaryRoutingBank:
      previewData.swiftCodeOfIntermediaryRoutingBank
        ? previewData.swiftCodeOfIntermediaryRoutingBank
        : vendorData?.bankDetails?.swiftCodeOfIntermediaryRoutingBank,
    incorporationCertificate: previewData.incorporationCertificate
      ? previewData.incorporationCertificate
      : vendorData.attachments?.incorporationCertificate,
    panCard: previewData.panCard
      ? previewData.panCard
      : vendorData.attachments?.panCard,
    cancelledCheque: previewData.cancelledCheque
      ? previewData.cancelledCheque
      : vendorData.attachments?.cancelledCheque,
    validLut: previewData.validLut
      ? previewData.validLut
      : vendorData.attachments?.validLut,
    gstRegistrationCertificate: previewData.gstRegistrationCertificate
      ? previewData.gstRegistrationCertificate
      : vendorData.attachments?.gstRegistrationCertificate,
    sezApproval: previewData.sezApproval
      ? previewData.sezApproval
      : vendorData.attachments?.sezApproval,
    pfCertificate: previewData.pfCertificate
      ? previewData.pfCertificate
      : vendorData.attachments?.pfCertificate,
    esiCertificate: previewData.esiCertificate
      ? previewData.esiCertificate
      : vendorData.attachments?.esiCertificate,
    tdsLowerDeductionCertificate: previewData.tdsLowerDeductionCertificate
      ? previewData.tdsLowerDeductionCertificate
      : vendorData.attachments?.tdsLowerDeductionCertificate,
    msmeCertificate: previewData.msmeCertificate
      ? previewData.msmeCertificate
      : vendorData.attachments?.msmeCertificate,
  };

  //   Whether SEZ/Non-SEZ
  // const valuesSezNonsez = [
  //   { label: "SEZ", value: "SEZ" },
  //   { label: "Non-SEZ", value: "Non-SEZ" },
  // ];
  // const [selectedvaluesSezNonsez, setSelectedvaluesSezNonsez] = useState("");

  // const handlevaluesSezNonsez = (selectedvaluesSezNonsez) => {
  //   setSelectedvaluesSezNonsez(selectedvaluesSezNonsez);
  // };

  // const listOfCountries = Country.getAllCountries();
  // const countryDetails = [];

  // // const countryinfo =
  // listOfCountries.map((country) =>
  //   countryDetails.push({
  //     name: country.name,
  //     currency: country.currency,
  //     phoneCode: country.phonecode,
  //     countryCode: country.isoCode,
  //   })
  // );

  // const valuesCurrency = countryDetails.filter(
  //   (country) => country.currency !== ""
  // );

  const pancardRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  const gstnoRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const cinnumberRegex =
    /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  const pfaccountnumberRegex =
    /[A-Z]{2}\/[A-Z]{3}\/[0-9]{7}\/[0-9A-Z]{2}[0-9]{1}\/[0-9]{7}/;
  const ifsccodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    lineOfBusiness: Yup.string().required("Choose a Line Of Business"),
    currency: Yup.string().required("Choose a Currency"),
    supplierName: Yup.string().required("Enter a Supplier Name"),
    buildingNoName: Yup.string().required("Enter a Building No/Name"),
    floor: Yup.string().required("Enter a Floor"),
    street: Yup.string().required("Enter a Street"),
    country: Yup.string().required("Choose a Country"),
    state: Yup.string().required("Choose a State"),
    city: Yup.string().required("Enter a City"),
    pinCode: Yup.string()
      .required("Enter a PinCode")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),

    contactPerson: Yup.string().required("Enter a Contact Person"),
    // contactNo: Yup.string().required("Required"),
    contactNo: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Enter a Contact No"),
    // industrialType: Yup.array(
    //   Yup.string()
    //     .oneOf([
    //       "Both",
    //       "Dealer",
    //       "Manufacturer",
    //       "Service",
    //       "Sub-Contractor",
    //       "Trader",
    //     ])
    //     .min(1)
    //     .required("Choose a Industrial Type")
    // ),
    email: Yup.string().email("E-mail is Invalid").required("Enter a E-mail"),
    pan: Yup.string()
      .required("Enter Pan No")
      .matches(pancardRegex, "Invalid Format"),
    gstNo: Yup.string()
      .matches(gstnoRegex, "Invalid Format")
      .required("Enter GST No"),
    // sezNonsez: Yup.string().required("Choose Whether SEZ/Non-SEZ"),
    // rcmApplicable: Yup.string().required("Enter RCM Applicable"),
    // lutNo: Yup.number().required("Enter a LutNo"),
    // lutDate: Yup.date().required("Enter a Lut Date"),
    // paymentTerms: Yup.string().required("Enter Payment Terms"),
    cinNumber: Yup.string()
      .matches(cinnumberRegex, "Invalid Format"),
    msmedAct: Yup.string().oneOf(["Yes", "No"]).required("Required"),
    tdsLowerDeduction: Yup.string().oneOf(["Yes", "No"]).required("Required"),
    categoryOfSupply: Yup.array(
      Yup.string().oneOf(["Material", "Services", "Man Power"])
    )
      .min(1, "Choose atleast one")
      .required("Choose Category Of Supply"),
    pfAccountNumber: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: Yup.string()
        .matches(pfaccountnumberRegex, "Invalid Format")
        .required("Enter a valid pf Account Number"),
      otherwise: Yup.string()
        .matches(pfaccountnumberRegex, "Invalid Format")
        .optional(),
    }),
    esiAccountNumber: Yup.mixed().when("categoryOfSupply", {
      is: (categoryOfSupply) =>
        categoryOfSupply.find((value) => value === "Man Power"),
      then: Yup.string().required(),
      otherwise: Yup.string().optional(),
    }),
    beneficiaryBankAndAddress: Yup.string().required(
      "Enter Beneficiary Bank And Address"
    ),
    accountNumber: Yup.number().required("Enter Account Number"),
    accountType: Yup.string().required("Choose Account Type"),
    accountName: Yup.string().required("Enter Account Name"),
    ifscCode: Yup.string()
      .matches(ifsccodeRegex, "Invalid Format")
      .required("Enter IFSC Code"),
    swiftCodeIban: Yup.string().optional(),
    intermediaryRoutingBank: Yup.string().optional(),
    swiftCodeOfIntermediaryRoutingBank: Yup.string().optional(),
  });

  const [countryIsoCode, setCountryIsoCode] = useState("");

  const countryCodeHandler = (code) => {
    setCountryIsoCode(code);
  };

  const onSubmit = (values) => {
    values.countryCode = countryIsoCode;
    values.lineOfBusiness = selectedlineOfBusiness.value;
    values.industrialType = industryType;
    values.lutDate = startDate;

    getPreview(values);
    navigate("/preview");
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };



  const documentEmail = vendorData.email;
  const getDocument = (filename) => {
    return (
      <a
        rel="noopener noreferrer"
        href={`http://3.110.254.227:8000/vendor/document?email=${documentEmail}&file_name=${filename}`}
        target="_blank"
        className="btn"
        style={{ display: "inline-block", marginBottom: "0.5rem" }}
      >
        View Previous File
      </a>
    );
  };

  return (
    <BasicLayout>
      <div style={{ position: "absolute" }}>
        <Card
          style={{
            marginTop: "150px",

            marginLeft: "5px",
            marginRight: "-15px",
          }}
        >
          <CardContent align="center">
            <MDTypography
              mt={5}
              mb={5}
              variant="h2"
              fontWeight="medium"
              style={{ display: "grid", justifyContent: "center" }}
            >
              Vendor Form
            </MDTypography>
            <MDBox
            // style={{ marginLeft: "150px", marginRight: "150px" }}
            >
              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <Tab style={{ padding: "15px" }}>
                    {" "}
                    Business Partner Details{" "}
                  </Tab>
                  <Tab style={{ padding: "15px" }}> Statutory Information </Tab>
                  <Tab style={{ padding: "15px" }}> Bank Details </Tab>
                  <Tab style={{ padding: "15px" }}>
                    {" "}
                    Documents To Be Attached{" "}
                  </Tab>
                </TabsList>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    isValid
                  }) => (
                    <Form>
                      <TabPanel value={0}>
                        <Grid container mt={3} spacing={3}>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Line of Business
                              </MDTypography>

                              <Select
                                className="select-css-for-form"
                                // placeholder={p}
                                name="lineOfBusiness"
                                value={selectedlineOfBusiness}
                                options={valuesLineOfBusiness}
                                onChange={(selectedOption) => {
                                  handlelineOfBusiness(selectedOption);
                                  handleChange("lineOfBusiness")(
                                    selectedOption.value
                                  );
                                }}
                              />

                              {errors.lineOfBusiness && (
                                <TextError msg={errors.lineOfBusiness} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Currency
                              </MDTypography>

                              <Select
                                className="select-css-for-form"
                                // placeholder="Select..."
                                name="currency"
                                value={selectedCurrency}
                                options={valuesCurrency}
                                onChange={(selectedOption) => {
                                  handleCurrency(selectedOption);
                                  handleChange("currency")(
                                    selectedOption.value
                                  );
                                }}
                              />

                              {errors.currency && (
                                <TextError msg={errors.currency} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Supplier Name
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="supplierName"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.supplierName}
                                style={{ width: "565px" }}
                              />

                              {errors.supplierName && (
                                <TextError msg={errors.supplierName} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Building No/Name
                              </MDTypography>
                              <MDInput
                                // label="Building No/Name"
                                type="text"
                                name="buildingNoName"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.buildingNoName}
                                style={{ width: "565px" }}
                              />

                              {errors.buildingNoName && (
                                <TextError msg={errors.buildingNoName} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Floor
                              </MDTypography>
                              <MDInput
                                // label="Floor"
                                type="text"
                                name="floor"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.floor}
                                style={{ width: "565px" }}
                              />

                              {errors.floor && <TextError msg={errors.floor} />}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Street
                              </MDTypography>
                              <MDInput
                                // label="Street"
                                type="text"
                                name="street"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.street}
                                style={{ width: "565px" }}
                              />

                              {errors.street && (
                                <TextError msg={errors.street} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6">Country</MDTypography>

                              <Select
                                className="select-css-for-form"
                                // placeholder="Select..."
                                name="country"
                                value={selectedCountry}
                                options={countryDetails}
                                onChange={(selectedOption) => {
                                  handleCountry(selectedOption);
                                  handleChange("country")(selectedOption.name);
                                }}
                              />

                              {errors.country && (
                                <TextError msg={errors.country} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6">State</MDTypography>

                              <Select
                                className="select-css-for-form"
                                // placeholder="Select..."
                                name="state"
                                value={selectedState}
                                options={updatedStates(
                                  selectedCountry
                                    ? selectedCountry.countryCode
                                    : null
                                )}
                                onChange={(selectedOption) => {
                                  handleStateChange(selectedOption);
                                  handleChange("state")(selectedOption.value);
                                }}
                              />
                              {errors.state && <TextError msg={errors.state} />}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                City
                              </MDTypography>
                              <MDInput
                                // label="City"
                                type="text"
                                name="city"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                style={{ width: "565px" }}
                              />

                              {errors.city && <TextError msg={errors.city} />}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                PinCode
                              </MDTypography>
                              <MDInput
                                // label="PinCode"
                                type="text"
                                name="pinCode"
                                inputProps={{ maxLength: 6 }}
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.pinCode}
                                style={{ width: "565px" }}
                              />

                              {errors.pinCode && (
                                <TextError msg={errors.pinCode} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Contact Person
                              </MDTypography>
                              <MDInput
                                // label="Contact Person"
                                type="text"
                                name="contactPerson"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contactPerson}
                                style={{ width: "565px" }}
                              />
                              {errors.contactPerson && (
                                <TextError msg={errors.contactPerson} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Contact No
                              </MDTypography>{" "}
                              <Field
                                readOnly
                                style={{
                                  width: "50px",
                                  height: "40px",
                                  borderRadius: "5px",
                                  border: "0.2px solid rgba(0, 0, 0, 0.125)",
                                }}
                                className="phonecode"
                                onChange={countryCodeHandler(
                                  values.country
                                    ? "+" +
                                    countryDetails.filter(
                                      (country) =>
                                        country.name === values.country
                                    )[0].phoneCode
                                    : ""
                                )}
                                value={
                                  values.country
                                    ? "+" +
                                    countryDetails.filter(
                                      (country) =>
                                        country.name === values.country
                                    )[0].phoneCode
                                    : ""
                                }
                              />
                              <span style={{ paddingLeft: "1rem" }} />
                              <MDInput

                                type="number"
                                name="contactNo"
                                disabled={values.country ? false : true}
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contactNo}
                                style={{ width: "565px" }}
                                inputProps={{ maxLength: 14 }}
                              />
                              {errors.contactNo && (
                                <TextError msg={errors.contactNo} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Email
                              </MDTypography>
                              <MDInput
                                // label="Email"
                                type="text"
                                name="email"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                style={{ width: "565px" }}
                              />
                              {errors.email && <TextError msg={errors.email} />}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <div>
                              <MDTypography variant="h6" color="inherit">
                                Enter Industrial Type
                              </MDTypography>
                              <FormControl
                                sx={{ m: 0, width: 570, height: 45 }}
                              // style={{ width:"500px" }}
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  Select Industrial Type
                                </InputLabel>
                                <Selects
                                  //  sx={{ m: 1, width: 600 , height:40 }}
                                  style={{ height: "100px" }}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={industryType}
                                  onChange={handleChangeForIndustrial}
                                  input={
                                    <OutlinedInput label="Select Industrial Type" />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {industryTypeNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          industryType.indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Selects>
                              </FormControl>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={1}>
                        <Grid container spacing={3} mt={3} alignItems="center">
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Pan
                              </MDTypography>
                              <MDInput
                                // label="Pan"
                                type="text"
                                name="pan"
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.pan}
                                style={{ width: "565px" }}
                              />
                              {errors.pan && <TextError msg={errors.pan} />}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Gst No
                              </MDTypography>
                              <MDInput
                                // label="Gst No"
                                type="text"
                                name="gstNo"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.gstNo}
                                style={{ width: "565px" }}
                              />
                              {errors.gstNo && <TextError msg={errors.gstNo} />}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Whether SEZ/Non-SEZ
                              </MDTypography>

                              <Select
                                className="select-css-for-form"
                                placeholder="Choose..."
                                name="sezNonsez"
                                value={selectedvaluesSezNonsez}
                                options={valuesSezNonsez}
                                onChange={(selectedOption) => {
                                  handlevaluesSezNonsez(selectedOption);
                                  handleChange("sezNonsez")(
                                    selectedOption.value
                                  );
                                }}
                              />

                              {errors.sezNonsez && (
                                <TextError msg={errors.sezNonsez} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                RCM Applicable
                              </MDTypography>
                              <MDInput
                                // label="RCM Applicable"
                                type="text"
                                name="rcmApplicable"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.rcmApplicable}
                                style={{ width: "565px" }}
                              />
                              {errors.rcmApplicable && (
                                <TextError msg={errors.rcmApplicable} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Lut No
                              </MDTypography>
                              <MDInput
                                // label="Lut No"
                                type="text"
                                name="lutNo"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lutNo}
                                style={{ width: "565px" }}
                              />
                              {errors.lutNo && <TextError msg={errors.lutNo} />}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                LUT Date
                              </MDTypography>
                              <MDBox width="35rem">
                                <DatePicker
                                  className="form-control"
                                  dateFormat="yyyy/MM/dd"

                                  placeholderText="Start Date"

                                  onChange={(date) => setStartDate(date)}
                                  isClearable={true}
                                  withPortal
                                  selectsStart
                                  selected={startDate}
                                // minDate={startDate}
                                />
                              </MDBox>
                              {/* <Field
                                        type="date"
                                        format="YY-MM-DD"
                                        autocomplete="off"
                                        name="lutDate"
                                        className="form-control"
                                        value={startDate}
                                        onChange={(e) =>
                                          setStartDate(
                                            e.target.value
                                          )
                                        }
                                      /> */}
                              {errors.lutDate && (
                                <TextError msg={errors.lutDate} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Payment Terms
                              </MDTypography>
                              <MDInput
                                // label="Payment Terms"
                                type="text"
                                name="paymentTerms"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.paymentTerms}
                                style={{ width: "565px" }}
                              />
                              {errors.paymentTerms && (
                                <TextError msg={errors.paymentTerms} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                CIN Number
                              </MDTypography>
                              <MDInput
                                // label="CIN Number"
                                type="text"
                                name="cinNumber"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cinNumber}
                                style={{ width: "565px" }}
                              />
                              {errors.cinNumber && (
                                <TextError msg={errors.cinNumber} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={6}
                            xs={12}
                            style={{
                              display: "grid",
                              justifyContent: "center",
                              alignSelf: "center",
                              // marginTop: "10px",
                            }}
                          >
                            <FormControl>
                              <MDTypography variant="h6" color="inherit">
                                Are you registered under MSMED Act, if yes
                                please share valid self attested certificate
                              </MDTypography>
                              <div>
                                <label>
                                  <Field
                                    style={{
                                      fontWeight: "bold",
                                      margin: "10px",
                                      marginRight: "10px",
                                    }}
                                    type="radio"
                                    value="Yes"
                                    name="msmedAct"
                                  />
                                  Yes
                                </label>
                                <span />
                                <label>
                                  <Field
                                    style={{
                                      margin: "10px",
                                      marginRight: "10px",
                                    }}
                                    type="radio"
                                    value="No"
                                    name="msmedAct"
                                  />
                                  No
                                </label>
                              </div>
                            </FormControl>
                            <ErrorMessage
                              component="div"
                              name="msmedAct"
                              render={(msg) => (
                                <div className="error" style={{ color: "red" }}>
                                  {msg}
                                </div>
                              )}
                              className="error"
                            />
                          </Grid>

                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={6}
                            xs={12}
                            style={{
                              display: "grid",
                              justifyContent: "center",
                              alignSelf: "center",
                              // marginTop: "10px",
                            }}
                          >
                            <FormControl>
                              <MDTypography variant="h6" color="inherit">
                                Do you have any TDS lower deduction certificate,
                                if yes provide self attested copy
                              </MDTypography>
                              <div>
                                <label>
                                  <Field
                                    type="radio"
                                    value="Yes"
                                    name="tdsLowerDeduction"
                                    style={{
                                      margin: "10px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  Yes
                                </label>
                                <span />
                                <label>
                                  <Field
                                    type="radio"
                                    value="No"
                                    name="tdsLowerDeduction"
                                    style={{
                                      margin: "10px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  No
                                </label>
                                <ErrorMessage
                                  component="div"
                                  name="tdsLowerDeduction"
                                  render={(msg) => (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {msg}
                                    </div>
                                  )}
                                  className="error"
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={6}
                            xs={12}
                            style={{
                              display: "grid",
                              justifyContent: "center",
                              alignSelf: "center",
                              // marginTop: "10px",
                            }}
                          >
                            <FormControl>
                              <MDTypography variant="h6" color="inherit">
                                Category of Supply
                              </MDTypography>
                              <div>
                                <label>
                                  <Field
                                    style={{
                                      margin: "10px",
                                      marginRight: "10px",
                                      fontWeight: "bold",
                                    }}
                                    type="checkbox"
                                    value="Material"
                                    name="categoryOfSupply"
                                  />
                                  Material
                                </label>
                                <span />
                                <label>
                                  <Field
                                    style={{
                                      margin: "10px",
                                      marginRight: "10px",
                                    }}
                                    type="checkbox"
                                    value="Services"
                                    name="categoryOfSupply"
                                  />
                                  Services
                                </label>
                                <span />
                                <label>
                                  <Field
                                    style={{
                                      margin: "10px",
                                      marginRight: "10px",
                                    }}
                                    type="checkbox"
                                    value="Man Power"
                                    name="categoryOfSupply"
                                  />
                                  Man Power
                                </label>
                              </div>
                            </FormControl>
                            <ErrorMessage
                              component="div"
                              name="categoryOfSupply"
                              render={(msg) => (
                                <div className="error" style={{ color: "red" }}>
                                  {msg}
                                </div>
                              )}
                              className="error"
                            />
                          </Grid>

                          {values.categoryOfSupply.includes("Man Power") ? (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  PF AccountNumber
                                </MDTypography>
                                <MDInput
                                  // label="PF AccountNumber"
                                  type="text"
                                  name="pfAccountNumber"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.pfAccountNumber}
                                  style={{ width: "565px" }}
                                />
                                {errors.pfAccountNumber && (
                                  <TextError msg={errors.pfAccountNumber} />
                                )}
                              </MDBox>
                            </Grid>
                          ) : (
                            ""
                          )}
                          {values.categoryOfSupply.includes("Man Power") ? (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  ESI AccountNumber
                                </MDTypography>
                                <MDInput
                                  // label="ESI AccountNumber"
                                  type="text"
                                  name="esiAccountNumber"
                                  fullWidth
                                  autoComplete="off"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.esiAccountNumber}
                                  style={{ width: "565px" }}
                                />
                                {errors.esiAccountNumber && (
                                  <TextError msg={errors.esiAccountNumber} />
                                )}
                              </MDBox>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={2}>
                        <Grid container mt={3} spacing={3} alignItems="center">
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Beneficiary Bank And Address
                              </MDTypography>
                              <MDInput
                                // label="Beneficiary Bank And Address"
                                type="text"
                                name="beneficiaryBankAndAddress"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.beneficiaryBankAndAddress}
                                style={{ width: "565px" }}
                              />
                              {errors.beneficiaryBankAndAddress && (
                                <TextError
                                  msg={errors.beneficiaryBankAndAddress}
                                />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Account Number
                              </MDTypography>
                              <MDInput
                                // label="Account Number"
                                type="text"
                                name="accountNumber"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.accountNumber}
                                style={{ width: "565px" }}
                              />
                              {errors.accountNumber && (
                                <TextError msg={errors.accountNumber} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Account Type
                              </MDTypography>

                              <Select
                                className="select-css-for-form"
                                // placeholder="Choose..."
                                name="accountType"
                                value={selectedaccountType}
                                options={valuesAccountType}
                                onChange={(selectedOption) => {
                                  handleaccountType(selectedOption);
                                  handleChange("accountType")(
                                    selectedOption.value
                                  );
                                }}
                              />

                              {errors.accountType && (
                                <TextError msg={errors.accountType} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Account Name
                              </MDTypography>
                              <MDInput
                                // label="accountName"
                                type="text"
                                name="accountName"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.accountName}
                                style={{ width: "565px" }}
                              />
                              {errors.accountName && (
                                <TextError msg={errors.accountName} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                IFSC Code
                              </MDTypography>
                              <MDInput
                                // label=" IFSC Code"
                                type="text"
                                name="ifscCode"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.ifscCode}
                                style={{ width: "565px" }}
                              />
                              {errors.ifscCode && (
                                <TextError msg={errors.ifscCode} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Swift Code/IBAN
                              </MDTypography>
                              <MDInput
                                label=" Swift Code/IBAN"
                                type="text"
                                name="swiftCodeIban"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.swiftCodeIban}
                                style={{ width: "565px" }}
                              />
                              {errors.swiftCodeIban && (
                                <TextError msg={errors.swiftCodeIban} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Swift Code Of Intermediary/RoutingBank
                              </MDTypography>
                              <MDInput
                                label="Swift Code Of Intermediary/RoutingBank"
                                type="text"
                                name="swiftCodeOfIntermediaryRoutingBank"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.swiftCodeOfIntermediaryRoutingBank
                                }
                                style={{ width: "565px" }}
                              />
                              {errors.swiftCodeOfIntermediaryRoutingBank && (
                                <TextError
                                  msg={
                                    errors.swiftCodeOfIntermediaryRoutingBank
                                  }
                                />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Intermediary/Routing Bank
                              </MDTypography>
                              <MDInput
                                label="Intermediary/Routing Bank"
                                type="text"
                                name="intermediaryRoutingBank"
                                fullWidth
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.intermediaryRoutingBank}
                                style={{ width: "565px" }}
                              />
                              {errors.intermediaryRoutingBank && (
                                <TextError
                                  msg={errors.intermediaryRoutingBank}
                                />
                              )}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={3}>
                        <Grid container mt={3} spacing={3} alignItems="center">
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                IncorporationCertificate
                              </MDTypography>

                              <Typography color="primary" variant="subtitle2">
                                Selected file:{" "}
                                {initialValues.incorporationCertificate
                                  ? initialValues.incorporationCertificate.name
                                  : vendorData.attachments
                                    ?.incorporationCertificate?.name}
                              </Typography>

                              {getDocument(
                                vendorData.attachments
                                  ? vendorData.attachments
                                    ?.incorporationCertificate?.name
                                  : ""
                              )}

                              <input
                                style={{
                                  borders: "none",
                                  marginTop: "10px",
                                  width: "565px",
                                }}
                                className="choose_file"
                                type="file"
                                name="incorporationCertificate"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  setFieldValue(
                                    "incorporationCertificate",
                                    event.target.files[0]
                                  );
                                }}
                                accept=".pdf"
                              />
                              {errors.incorporationCertificate && (
                                <TextError
                                  msg={errors.incorporationCertificate}
                                />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                Cancelled Cheque
                              </MDTypography>

                              <Typography color="primary" variant="subtitle2">
                                Selected file:{" "}
                                {initialValues.cancelledCheque
                                  ? initialValues.cancelledCheque.name
                                  : vendorData.attachments?.cancelledCheque
                                    ?.name}
                              </Typography>
                              {getDocument(
                                vendorData.attachments
                                  ? vendorData.attachments?.cancelledCheque
                                    ?.name
                                  : ""
                              )}
                              <input
                                style={{
                                  borders: "none",
                                  marginTop: "10px",
                                  width: "565px",
                                }}
                                className="choose_file"
                                type="file"
                                name="cancelledCheque"
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setFieldValue(
                                    "cancelledCheque",
                                    e.target.files[0]
                                  )
                                }
                                accept=".pdf"
                                autoComplete="off"
                              />

                              {errors.cancelledCheque && (
                                <TextError msg={errors.cancelledCheque} />
                              )}
                            </MDBox>
                          </Grid>
                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                GST RegistrationCertificate
                              </MDTypography>

                              <Typography color="primary" variant="subtitle2">
                                Selected file:{" "}
                                {initialValues.gstRegistrationCertificate
                                  ? initialValues.gstRegistrationCertificate
                                    .name
                                  : vendorData.attachments
                                    ?.gstRegistrationCertificate?.name}
                              </Typography>
                              {getDocument(
                                vendorData.attachments
                                  ? vendorData.attachments
                                    ?.gstRegistrationCertificate?.name
                                  : ""
                              )}
                              <input
                                style={{
                                  borders: "none",
                                  marginTop: "10px",
                                  width: "565px",
                                }}
                                className="choose_file"
                                type="file"
                                name="gstRegistrationCertificate"
                                onChange={(e) =>
                                  setFieldValue(
                                    "gstRegistrationCertificate",
                                    e.target.files[0]
                                  )
                                }
                                autoComplete="off"
                                onBlur={handleBlur}
                              />
                              {errors.gstRegistrationCertificate && (
                                <TextError
                                  msg={errors.gstRegistrationCertificate}
                                />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                PAN Card
                              </MDTypography>

                              <Typography color="primary" variant="subtitle2">
                                Selected file:{" "}
                                {initialValues.panCard
                                  ? initialValues.panCard.name
                                  : vendorData.attachments?.panCard?.name}
                              </Typography>
                              {getDocument(
                                vendorData.attachments
                                  ? vendorData.attachments?.panCard?.name
                                  : ""
                              )}
                              <input
                                style={{
                                  borders: "none",
                                  marginTop: "10px",
                                  width: "565px",
                                }}
                                type="file"
                                accept=".pdf"
                                name="panCard"
                                onChange={(e) =>
                                  setFieldValue("panCard", e.target.files[0])
                                }
                              />
                              {errors.panCard && (
                                <TextError msg={errors.panCard} />
                              )}
                            </MDBox>
                          </Grid>

                          <Grid item xl={6} lg={4} md={6} xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h6" color="inherit">
                                ValidLut
                              </MDTypography>

                              <Typography color="primary" variant="subtitle2">
                                Selected file:{" "}
                                {initialValues.validLut
                                  ? initialValues.validLut.name
                                  : vendorData.attachments?.validLut?.name}
                              </Typography>
                              {getDocument(
                                vendorData.attachments
                                  ? vendorData.attachments?.validLut?.name
                                  : ""
                              )}
                              <input
                                style={{
                                  borders: "none",
                                  marginTop: "10px",
                                  width: "565px",
                                }}
                                className="choose_file"
                                type="file"
                                name="validLut"
                                accept=".pdf"
                                autoComplete="off"
                                onChange={(e) =>
                                  setFieldValue("validLut", e.target.files[0])
                                }
                              />
                              {errors.validLut && (
                                <TextError msg={errors.validLut} />
                              )}
                            </MDBox>
                          </Grid>

                          {selectedvaluesSezNonsez.value === "SEZ" && (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Sez Approval
                                </MDTypography>

                                <Typography color="primary" variant="subtitle2">
                                  Selected file:{" "}
                                  {initialValues.sezApproval
                                    ? initialValues.sezApproval.name
                                    : vendorData.attachments?.sezApproval?.name}
                                </Typography>
                                {getDocument(
                                  vendorData.attachments
                                    ? vendorData.attachments?.sezApproval?.name
                                    : ""
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="sezApproval"
                                  accept=".pdf"
                                  // fullWidth
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setFieldValue("panCard", e.target.files[0])
                                  }
                                />
                                {errors.sezApproval && (
                                  <TextError msg={errors.sezApproval} />
                                )}
                              </MDBox>
                            </Grid>
                          )}

                          {values.pfAccountNumber && (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  PF Certificate
                                </MDTypography>

                                <Typography color="primary" variant="subtitle2">
                                  Selected file:{" "}
                                  {initialValues.pfCertificate
                                    ? initialValues.pfCertificate.name
                                    : vendorData.attachments?.pfCertificate
                                      ?.name}
                                </Typography>
                                {getDocument(
                                  vendorData.attachments
                                    ? vendorData.attachments?.pfCertificate
                                      ?.name
                                    : ""
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="pfCertificate"
                                  // fullWidth
                                  accept=".pdf"
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "pfCertificate",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                {errors.pfCertificate && (
                                  <TextError msg={errors.pfCertificate} />
                                )}
                              </MDBox>
                            </Grid>
                          )}

                          {values.esiAccountNumber && (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  ESI Certificate
                                </MDTypography>

                                <Typography color="primary" variant="subtitle2">
                                  Selected file:{" "}
                                  {initialValues.esiCertificate
                                    ? initialValues.esiCertificate.name
                                    : vendorData.attachments?.esiCertificate
                                      ?.name}
                                </Typography>
                                {getDocument(
                                  vendorData.attachments
                                    ? vendorData.attachments?.esiCertificate
                                      ?.name
                                    : ""
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="esiCertificate"
                                  accept=".pdf"
                                  // fullWidth
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "esiCertificate",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                {errors.esiCertificate && (
                                  <TextError msg={errors.esiCertificate} />
                                )}
                              </MDBox>
                            </Grid>
                          )}

                          {values.tdsLowerDeduction === "Yes" ? (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  TdsLower DeductionCertificate
                                </MDTypography>

                                <Typography color="primary" variant="subtitle2">
                                  Selected file:{" "}
                                  {initialValues.tdsLowerDeductionCertificate
                                    ? initialValues.tdsLowerDeductionCertificate
                                      .name
                                    : vendorData.attachments
                                      ?.tdsLowerDeductionCertificate?.name}
                                </Typography>
                                {getDocument(
                                  vendorData.attachments
                                    ? vendorData.attachments
                                      ?.tdsLowerDeductionCertificate?.name
                                    : ""
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="tdsLowerDeductionCertificate"
                                  accept=".pdf"
                                  // fullWidth
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "tdsLowerDeductionCertificate",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                {errors.tdsLowerDeductionCertificate && (
                                  <TextError
                                    msg={errors.tdsLowerDeductionCertificate}
                                  />
                                )}
                              </MDBox>
                            </Grid>
                          ) : (
                            ""
                          )}

                          {values.msmedAct === "Yes" ? (
                            <Grid item xl={6} lg={4} md={6} xs={12}>
                              <MDBox mb={2}>
                                <MDTypography variant="h6" color="inherit">
                                  Msme Certificate
                                </MDTypography>

                                <Typography color="primary" variant="subtitle2">
                                  Selected file:{" "}
                                  {initialValues.msmeCertificate
                                    ? initialValues.msmeCertificate.name
                                    : vendorData.attachments?.msmeCertificate
                                      ?.name}
                                </Typography>
                                {getDocument(
                                  vendorData.attachments
                                    ? vendorData.attachments?.msmeCertificate
                                      ?.name
                                    : ""
                                )}
                                <input
                                  style={{
                                    borders: "none",
                                    marginTop: "10px",
                                    width: "565px",
                                  }}
                                  className="choose_file"
                                  type="file"
                                  name="msmeCertificate"
                                  accept=".pdf"
                                  // fullWidth
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "msmeCertificate",
                                      e.target.files[0]
                                    )
                                  }
                                />
                                {errors.msmeCertificate && (
                                  <TextError msg={errors.msmeCertificate} />
                                )}
                              </MDBox>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </TabPanel>

                      <MDButton
                        color="error"
                        style={{ backgroundColor: "red", marginTop: "10px" }}
                        type="submit"
                        disabled={!isValid}
                      >
                        Preview
                      </MDButton>

                    </Form>
                  )}
                </Formik>
              </TabsUnstyled>
            </MDBox>
          </CardContent>
        </Card>
      </div>
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getVendorByVendorIdReducer: state.getVendorByVendorIdReducer,
    getVendorIdReducer: state.getVendorIdReducer,
    getPreviewReducer: state.getPreviewReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorByVendorId: (vendorId) => dispatch(getVendorByVendorId(vendorId)),
    getPreview: (payload) => dispatch(getPreview(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RejectedVendorForm);
