/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getStatus,
  resendVendorRegistrationLink,
  updateEmail,
} from "../../store";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const styleForEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 275,
  height: 210,
  // border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  pt: 3,
};

const Status = ({
  getStatus,
  statusReducer,
  resendVendorRegistrationLink,
  resendVendorRegistrationLinkReducer,
  updateEmail,
  updateEmailReducer,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    getStatus();
  }, [getStatus]);

  let loading = statusReducer.loading;

  const viewFunction = (data) => {
    navigate(`/status/${data.vendorId}`);
  };

  const [state, setState] = useState({
    columns: [
      { Header: "vendor Name", accessor: "vendorName" },
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Email", accessor: "email" },
      { Header: "Form Filled", accessor: "isFilled" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [openResendLinkModal, setOpenResendLinkModal] = useState(false);
  const [singleDataForResendLinkModal, setSingleDataForResendLinkModal] =
    useState({});
  const handleOpenResendLinkModal = (Data = {}) => {
    setSingleDataForResendLinkModal(Data);
    setOpenResendLinkModal(true);
  };
  const handleCloseResendLinkModal = () => {
    setOpenResendLinkModal(false);
  };

  const [openEditEmailModal, setOpenEditEmailModal] = useState(false);

  const [singleEmailForUpdate, setSingleEmailForUpdate] = useState({});

  const handleOpenEditEmailModal = (Data = {}) => {
    setSingleEmailForUpdate(Data);
    setOpenEditEmailModal(true);
  };

  const handleCloseEditEmailModal = () => {
    setOpenEditEmailModal(false);
  };

  const initialValues = {
    vendorId: singleEmailForUpdate.vendorId
      ? singleEmailForUpdate.vendorId
      : null,
    email: singleEmailForUpdate.email ? singleEmailForUpdate.email : null,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-mail!"),
  });

  const onSubmitForUpdateEmail = async (values) => {
    let response = await updateEmail(values);

    if (response.status) {
      handleCloseEditEmailModal();
      getStatus();

      setSingleEmailForUpdate({});
    }
    if (!response.status) {
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitForUpdateEmail,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const resendLinkNotification = () =>
    toast.success("Vendor Registration Link Send Successfully", {
      position: "top-center",
      autoClose: 2000,
    });

  const [isErrorResendLink, setIsErrorResendLink] = useState(false);

  useEffect(() => {
    if (isErrorResendLink) {
      setTimeout(() => {
        setIsErrorResendLink(false);
      }, 3000);
    }
  }, [isErrorResendLink]);

  const handleResendLink = async () => {
    let payload = {
      email: singleDataForResendLinkModal.email,
    };

    const response = await resendVendorRegistrationLink(payload);

    if (response.status) {
      handleCloseResendLinkModal();
      setTimeout(() => {
        getStatus();
        resendLinkNotification();
      }, 0);
    }
  };

  useEffect(() => {
    let tempStatus = [];
    let usersData = statusReducer.statusData.data
      ? statusReducer.statusData.data
      : [];

    usersData.map((user) => {
      const singleUser = {
        privateKey: user.email,
        vendorName: user.vendorName,
        contactPerson: user.contactPerson,
        email: user.email,
        isFilled: (
          <div>{usersData && user.isFilled == true ? "Yes" : "No"}</div>
        ),
        action: (
          <div style={{ display: "flex" }}>
            {usersData && user.isFilled == true ? (
              <>
                <Tooltip title="View">
                  <MDButton
                    variant="gradient"
                    color="info"
                    iconOnly
                    type="button"
                    onClick={() => viewFunction(user)}
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    <Icon>visibility_icon</Icon>
                  </MDButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Resend Link">
                  <MDButton
                    variant="gradient"
                    color="warning"
                    iconOnly
                    type="button"
                    onClick={() => handleOpenResendLinkModal(user)}
                    style={{ marginLeft: "0px", marginRight: "5px" }}
                  >
                    <Icon>send</Icon>
                  </MDButton>
                </Tooltip>
              </>
            )}

            <>
              <Tooltip title="Edit Email">
                <MDButton
                  variant="gradient"
                  color="success"
                  iconOnly
                  type="button"
                  onClick={() => handleOpenEditEmailModal(user)}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </Tooltip>
            </>
          </div>
        ),
      };
      tempStatus.push(singleUser);
    });
    setState({ ...state, rows: tempStatus });
  }, [statusReducer]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Status
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    canSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
                <Modal
                  open={openResendLinkModal}
                  onClose={handleCloseResendLinkModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={style}>
                    <MDTypography mb={2}>
                      Are you sure you want to Resend Vendor Registration Link ?
                    </MDTypography>
                    <MDCard mt={2}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <MDButton
                            style={{ width: "100%", marginRight: "0.5rem" }}
                            color="secondary"
                            variant="contained"
                            onClick={handleCloseResendLinkModal}
                          >
                            Cancel
                          </MDButton>
                        </div>
                        <div style={{ float: "right" }}>
                          <MDButton
                            style={{ width: "100%", marginLeft: "0.5rem" }}
                            color="warning"
                            onClick={handleResendLink}
                          >
                            Resend
                          </MDButton>
                        </div>
                      </div>
                    </MDCard>
                    <MDCard mt={2}>
                      <Collapse in={isErrorResendLink}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorResendLink(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not resend link !
                        </Alert>
                      </Collapse>
                    </MDCard>
                  </Card>
                </Modal>
                <Modal
                  open={openEditEmailModal}
                  onClose={handleCloseEditEmailModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForEdit}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Enter Email
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseEditEmailModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                    <MDBox pt={1} pb={3} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            name="email"
                            fullWidth
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={
                              formik.touched.email &&
                              formik.errors.email &&
                              true
                            }
                          />
                          {formik.touched.email && formik.errors.email && (
                            <TextError msg={formik.errors.email} />
                          )}
                        </MDBox>

                        <MDBox mt={2}>
                          <MDButton
                            variant="contained"
                            color="success"
                            type="submit"
                            fullWidth
                            disabled={!formik.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
      {!loading ? (
      <Footer />
      ):(
        null
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    statusReducer: state.statusReducer,
    updateEmailReducer: state.updateEmailReducer,
    resendVendorRegistrationLinkReducer:
      state.resendVendorRegistrationLinkReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getStatus: () => dispatch(getStatus()),
    updateEmail: (payload) => dispatch(updateEmail(payload)),
    resendVendorRegistrationLink: (payload) =>
      dispatch(resendVendorRegistrationLink(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Status);
